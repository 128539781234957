import { ACTION_TYPES } from './useNewSummaryData'
import { SUMMARY_TABS } from '../../Constant'

export const COLUMN_PREFERENCES = [
    { id: 'C&T', label: 'C&T' },
    { id: 'FF', label: 'FF' },
    { id: 'Total', label: 'Org Totals' },
]

export const ROW_PREFERENCES = [
    { id: 'total', label: 'Total' },
    { id: 'totalOh', label: 'Total with OH' },
    { id: 'bis', label: 'BIS' },
    { id: 'gap', label: 'Gap' },
]

export const PROGRAM_ROW_PREFERENCES = [{ id: 'total', label: 'Total' }]

export const COL_LIST = ['Total', 'C&T', 'FF']
export const ROW_LIST = ['default', 'totalOh', 'bis', 'gap', 'total']

/* Enforcing limit for max orgs or groups that can be selected
as preferences to stay under url size limit of 2048 characters */
export const PREFERENCE_LIMIT = 14
export const TAB = 'Tab'
export const ROWS = 'Rows'
export const COLUMNS = 'Columns'
export const PROGRAM = 'Program'
export const REVISION = 'Revision'
export const PLAN = 'Plan'
export const GRAND_TOTAL_ROW = 'grandTotalRow'
export const MIN_GROUP_ALERT = 'You must have at least 1 group selected'
export const MIN_ORG_ALERT = 'You must have at least 1 org selected'
export const MAX_GROUP_ALERT = 'You can select a maximum of 15 groups, remove some to add more'
export const MAX_ORG_ALERT = 'You can select a maximum of 15 orgs, remove some to add more'

const setOrgList = (data) => {
    const orgNames: { id: string; label: string }[] = []
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            orgNames.push({
                id: org.orgName,
                label: org.orgName,
            })
        })
    }
    return orgNames
}

export const setGroupList = (data) => {
    const groupNames: { id: string; label: string }[] = []
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            org.groups.forEach((group) => {
                groupNames.push({
                    id: group.group_name,
                    label: group.group_name,
                })
            })
        })
    }
    return groupNames
}

const setProgramOrgList = (data) => {
    const orgNames: { id: string; label: string }[] = []
    const orgData = data[ACTION_TYPES.HC].data.filter(
        (hcItem: any) => hcItem.program_id === data[ACTION_TYPES.PROGRAM]?.selected?.value,
    )
    const orgsToInclude = orgData.map((deliverable) => deliverable.org_id)
    const orgs = data[ACTION_TYPES.GROUPS_BY_ORG].data.filter((org) =>
        orgsToInclude.includes(org.orgId),
    )
    orgs.forEach((org) => {
        orgNames.push({
            id: org.orgName,
            label: org.orgName,
        })
    })
    return orgNames
}

export const ORG_PREFERENCES_HANDLERS: Map<SUMMARY_TABS, (data: any) => any[]> = new Map([
    [SUMMARY_TABS.ORG, (data) => setOrgList(data)],
    [SUMMARY_TABS.PROGRAM, (data) => setProgramOrgList(data)],
    // [SUMMARY_TABS.SPEND, (data) => setOrgList(data)],
])

export const parseArrayFromString = (tokens, defaultTokens): string[] => {
    if (!tokens) {
        return defaultTokens
    }
    if (typeof tokens === 'string') {
        return tokens.split(',')
    }
    return defaultTokens
}

const recursiveFilterColumns = (colDefs: any[], cols: string[]): any[] =>
    colDefs.filter((colDef) => {
        if (colDef.headerName && cols.includes(colDef.headerName)) {
            return false
        }

        if (colDef.children && Array.isArray(colDef.children)) {
            colDef.children = recursiveFilterColumns(colDef.children, cols)
        }
        return true
    })

export const filterColumns = (colDefs: any[], initialCols: string[]): any[] => {
    const colsToFilter = COL_LIST.filter((col) => !initialCols.includes(col))
    return recursiveFilterColumns(colDefs, colsToFilter)
}

export const getPinnedRows = (bisData, groupOHData, gapData, initialRows): any[] => {
    const pinnedRows: any[] = []
    if (initialRows.includes('bis')) {
        pinnedRows.push(bisData)
    }
    if (initialRows.includes('totalOh')) {
        pinnedRows.push(groupOHData)
    }
    if (initialRows.includes('gap')) {
        pinnedRows.push(gapData)
    }
    return pinnedRows
}
