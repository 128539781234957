import { useAppContext } from '../../../../context'
import { ALERT_TYPES, COMPONENT_TYPES, MODAL_MODES } from '../../../Constant'
import {
    deliverableAttributes,
    getDeliverableAttributes,
    getNonProgramDeliverableAttributes,
    NONPROGRAM_DELIVERABLE_ATTRIBUTES,
} from './DeliverableAttributes'
import { generateUuid } from '../../../common/Util'
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import GroupFormFieldGenerator from '../../reusable/GroupFormFieldGenerator'
import { useState } from 'react'
import useStore from '../../../Store'

interface CreateDeliverableProps {
    isStlNotAdmin: boolean
    state: any
    planID: string
    programID: string
    isProgram: boolean
    revisionID: string
    inputChange: (field: string, value: any) => void
    onAlertItemAdd: (content: any, type: ALERT_TYPES) => void
    onDismiss: () => void
    clearAllInputs: () => void
    onIsInputInvalidChanged: (value: boolean) => void
    onIsDataUnmodifiedChanged: (value: boolean) => void
    onIsLoadingChange: (value: boolean) => void
    modalMode: string
    selectedDeliverables: any[]
    onSelectedDeliverablesChange: (value: any[]) => void
    revisionLocked: boolean
    visible: boolean
    isDataUnmodified: boolean
    isInputInvalid: boolean
    reloadDeliverables: () => void
    deliverables: any[]
    isAddingDeliverable?: boolean
    programName?: string
}

const CreateDeliverable = (props: CreateDeliverableProps) => {
    const {
        isStlNotAdmin,
        state,
        planID,
        programID,
        isProgram,
        revisionID,
        inputChange,
        onAlertItemAdd,
        onDismiss,
        clearAllInputs,
        onIsInputInvalidChanged,
        onIsDataUnmodifiedChanged,
        onIsLoadingChange,
        modalMode,
        selectedDeliverables,
        onSelectedDeliverablesChange,
        revisionLocked,
        visible,
        isDataUnmodified,
        isInputInvalid,
        reloadDeliverables,
        deliverables,
    } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const [formErrors, setFormErrors] = useState<string[]>([])
    const attributes = isProgram ? getDeliverableAttributes() : getNonProgramDeliverableAttributes()

    const isInputsValid = () => {
        let isValid = true
        for (const stateId in state) {
            if (stateId.endsWith('_error')) {
                continue
            }

            let errorMessage = ''
            const value = state[stateId]
            const attrDef: any = deliverableAttributes.find((attr) => attr.id === stateId)

            if (!attrDef) {
                continue
            }

            const required = isStlNotAdmin ? attrDef.required : attrDef.adminRequired
            const requiredCondition = isProgram ? required : 'nonProgram' in attrDef
            if (requiredCondition) {
                if (attrDef.componentType === COMPONENT_TYPES.SELECT) {
                    errorMessage = !value.value ? `${attrDef.headerName} must be provided.` : ''
                } else if (
                    attrDef.componentType === COMPONENT_TYPES.INPUT_STRING ||
                    attrDef.componentType === COMPONENT_TYPES.TEXTAREA
                ) {
                    errorMessage = !value ? `${attrDef.headerName} must be provided.` : ''
                }
            }

            if (errorMessage) {
                inputChange(`${stateId}_error`, errorMessage)
                isValid = false
            }
        }
        return isValid
    }

    const handleCloseModal = (reason) => {
        if (reason === 'overlay') {
            // prevent close modal from clicking outside of modal
            return
        }
        onDismiss()
        onIsDataUnmodifiedChanged(true)
        onIsInputInvalidChanged(false)
        clearAllInputs()
    }

    const handleSubmit = async (isSaveAndCreateNew) => {
        if (!isInputsValid()) {
            return
        }

        const payload = {
            plan_id: planID,
            revision_id: revisionID,
            program_id: programID,
            deliverable_id:
                modalMode === MODAL_MODES.EDIT
                    ? selectedDeliverables[0].deliverable_id
                    : generateUuid(),
            mode: modalMode,
            business_entity_id: selectedBusinessEntity.business_entity_id,
        }

        for (const stateId in state) {
            if (!isProgram && !NONPROGRAM_DELIVERABLE_ATTRIBUTES.includes(stateId)) {
                continue
            }
            if (!stateId.endsWith('_error') && state[stateId] != undefined) {
                if (state[stateId].label) {
                    payload[stateId] = state[stateId].value
                } else {
                    payload[stateId] = state[stateId]
                }
            }
        }

        onIsLoadingChange(true)
        if (!revisionLocked) {
            createUpdateDeliverable(payload)
                .then(() => {
                    if (modalMode === MODAL_MODES.EDIT) {
                        onAlertItemAdd(
                            `Successfully updated deliverable ${selectedDeliverables[0].deliverable_name}.`,
                            ALERT_TYPES.SUCCESS,
                        )
                    } else {
                        onAlertItemAdd(
                            `Successfully created deliverable ${state.deliverable_name}`,
                            ALERT_TYPES.SUCCESS,
                        )
                    }
                    clearAllInputs()
                    onSelectedDeliverablesChange([])
                })
                .catch((error) => {
                    if (modalMode === MODAL_MODES.EDIT) {
                        onAlertItemAdd(
                            `Failed to update deliverable ${selectedDeliverables[0].deliverable_name}: ${error.response.data}.`,
                            ALERT_TYPES.ERROR,
                        )
                    } else {
                        onAlertItemAdd(
                            `Failed to create deliverable ${state.deliverable_name}: ${error.response.data}.`,
                            ALERT_TYPES.ERROR,
                        )
                    }
                    console.error(error)
                })
                .finally(() => {
                    reloadDeliverables()
                    onIsLoadingChange(false)
                    if (!isSaveAndCreateNew) {
                        onDismiss()
                        clearAllInputs()
                    }
                })
        } else {
            onAlertItemAdd(
                `Failed to add/update deliverable: please add deliverables to the latest revision.`,
                ALERT_TYPES.ERROR,
            )
            reloadDeliverables()
            handleCloseModal('Adding to locked revision')
        }
    }

    const createUpdateDeliverable = (payload) => {
        const currentIsProgram = isStlNotAdmin ? isProgram : false
        return apiClient.post(
            `/plan/${planID}/revision/${revisionID}/program/${programID}/deliverables?is_true_program=${currentIsProgram}`,
            JSON.stringify(payload),
        )
    }

    return (
        <Modal
            onDismiss={({ detail }) => handleCloseModal(detail.reason)}
            visible={visible}
            size='large'
            closeAriaLabel='Close modal'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={() => handleSubmit(false)}
                            disabled={isDataUnmodified || isInputInvalid}
                        >
                            {modalMode === MODAL_MODES.CREATE ? 'Create' : 'Update'}
                        </Button>
                        {modalMode === MODAL_MODES.CREATE ? (
                            <Button
                                variant='primary'
                                onClick={() => handleSubmit(true)}
                                disabled={isDataUnmodified || isInputInvalid}
                            >
                                Save and Create New
                            </Button>
                        ) : (
                            <></>
                        )}
                    </SpaceBetween>
                </Box>
            }
            header={
                modalMode === MODAL_MODES.CREATE ? 'Create New Deliverable' : 'Edit Deliverable'
            }
        >
            <SpaceBetween direction='vertical' size='m'>
                <Container header={<Box variant='h4'>Common Attributes</Box>}>
                    <ColumnLayout borders='horizontal' columns={1}>
                        <GroupFormFieldGenerator
                            isStlNotAdmin={isStlNotAdmin}
                            attributes={attributes}
                            state={state}
                            inputChange={inputChange}
                            items={deliverables}
                            selectedItems={selectedDeliverables}
                            isInputInvalid={isInputInvalid}
                            onIsInputInvalidChanged={onIsInputInvalidChanged}
                            onIsDataUnmodifiedChanged={onIsDataUnmodifiedChanged}
                            isAddingProgram={false}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                        />
                    </ColumnLayout>
                </Container>
            </SpaceBetween>
        </Modal>
    )
}

export default CreateDeliverable
