import { Box, FormField, DateInput, Calendar } from '@amzn/awsui-components-react'

const generateFilteringProperty = (name: string, key: string, type: string) => {
    return {
        propertyLabel: `${name}`,
        key: key,
        groupValuesLabel: `${name} values`,
        operators: type === 'string' ? [':', '!:', '=', '!='] : ['=', '!=', '>', '<', '<=', '>='],
    }
}

export const dateFilterProperty = (filterProps) => {
    return {
        ...filterProps,
        ['operators']: filterProps.operators.map((operator: string) => ({
            operator,
            form: ({ value, onChange }) => (
                <Box>
                    <FormField>
                        <DateInput
                            value={value || ''}
                            onChange={(event) => {
                                onChange(event.detail.value)
                            }}
                            placeholder='YYYY/YY/DD'
                        />
                    </FormField>
                    <Calendar
                        value={value || ''}
                        onChange={(event) => {
                            onChange(event.detail.value)
                        }}
                        locale='en-EN'
                        todayAriaLabel='Today'
                        nextMonthAriaLabel='Next month'
                        previousMonthAriaLabel='Previous month'
                    />
                </Box>
            ),
            format: (tokenValue) => tokenValue,
        })),
    }
}

export const getMatchesCountText = (count: number | undefined) => {
    return count === 1 ? `1 match` : `${count} matches`
}

export const LOCAL_PROGRAM_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Program', 'program_name', 'string'),
    generateFilteringProperty('STL Alias', 'stl_alias', 'string'),
    generateFilteringProperty('STL Name', 'stl_name', 'string'),
    generateFilteringProperty('Is Program', 'is_true_program', 'boolean'),
    generateFilteringProperty('Description', 'description', 'string'),
    generateFilteringProperty('Owned Org', 'owned_org_id', 'string'),
    generateFilteringProperty('Program Theme', 'program_theme', 'string'),
    generateFilteringProperty('Updated By', 'update_by', 'string'),
    generateFilteringProperty('Updated At', 'update_at', 'string'),
]

export const DISCRETIONARY_SPEND_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Expense ID', 'ds_item_id_display', 'string'),
    generateFilteringProperty('Expense Title', 'expense_title', 'string'),
    generateFilteringProperty('Expense Category', 'expense_category', 'string'),
    generateFilteringProperty('Expense Type', 'expense_type', 'string'),
    generateFilteringProperty('Cost Center', 'cost_center', 'string'),
    generateFilteringProperty('Total Expenditures', 'total_expenditure', 'number'),
]

export const PROPERTY_FILTER_I18NSTRING = {
    filteringAriaLabel: 'your choice',
    dismissAriaLabel: 'Dismiss',
    filteringPlaceholder: 'Filter by text, property or value',
    groupValuesText: 'Values',
    groupPropertiesText: 'Properties',
    operatorsText: 'Operators',
    operationAndText: 'and',
    operationOrText: 'or',
    operatorLessText: 'Less than',
    operatorLessOrEqualText: 'Less than or equal',
    operatorGreaterText: 'Greater than',
    operatorGreaterOrEqualText: 'Greater than or equal',
    operatorContainsText: 'Contains',
    operatorDoesNotContainText: 'Does not contain',
    operatorEqualsText: 'Equals',
    operatorDoesNotEqualText: 'Does not equal',
    editTokenHeader: 'Edit filter',
    propertyText: 'Property',
    operatorText: 'Operator',
    valueText: 'Value',
    cancelActionText: 'Cancel',
    applyActionText: 'Apply',
    allPropertiesLabel: 'All fields',
    tokenLimitShowMore: 'Show more',
    tokenLimitShowFewer: 'Show fewer',
    clearFiltersText: 'Clear filters',
    removeTokenButtonAriaLabel: () => 'Remove token',
    enteredTextLabel: (text: any) => `Use: "${text}"`,
}
export const CREATE_UPDATE_AT_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Created by', 'created_by', 'string'),
    generateFilteringProperty('Updated by', 'updated_by', 'string'),
    generateFilteringProperty('Created at', 'created_at', 'string'),
    generateFilteringProperty('Updated at', 'updated_at', 'string'),
]
export const PLAN_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Name', 'plan_name', 'string'),
    generateFilteringProperty('Year', 'year', 'string'),
    generateFilteringProperty('Type', 'plan_type', 'string'),
    generateFilteringProperty('STL Leader', 'stl_leader', 'string'),
    generateFilteringProperty('Status', 'plan_status', 'string'),
    ...CREATE_UPDATE_AT_FILTERING_PROPERTIES,
]

export const PLAN_REVISION_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Name', 'revision_name', 'string'),
    generateFilteringProperty('Title', 'revision_title', 'string'),
    generateFilteringProperty('Status', 'revision_status', 'string'),
    ...CREATE_UPDATE_AT_FILTERING_PROPERTIES,
]
export const ORG_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Organization', 'org_name', 'string'),
    generateFilteringProperty('Groups', 'number_of_groups', 'number'),
    generateFilteringProperty('Teams', 'number_of_teams', 'number'),
    generateFilteringProperty('Created by', 'created_by', 'string'),
    generateFilteringProperty('Updated by', 'updated_by', 'string'),
    generateFilteringProperty('Created at', 'created_at', 'string'),
    generateFilteringProperty('Updated at', 'updated_at', 'string'),
    generateFilteringProperty('Active', 'is_active', 'boolean'),
    generateFilteringProperty('Primary Contact', 'primary_alias', 'string'),
    generateFilteringProperty('Registered Users', 'registered_users', 'string'),
]
export const COMMON_GROUP_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Group', 'group_name', 'string'),
    generateFilteringProperty('Teams', 'number_of_teams', 'number'),
    generateFilteringProperty('Created by', 'created_by', 'string'),
    generateFilteringProperty('Updated by', 'updated_by', 'string'),
    generateFilteringProperty('Created at', 'created_at', 'string'),
    generateFilteringProperty('Updated at', 'updated_at', 'string'),
    generateFilteringProperty('Active', 'is_active', 'boolean'),
    generateFilteringProperty('Primary Contact', 'primary_alias', 'string'),
    generateFilteringProperty('Registered Users', 'registered_users', 'string'),
]

export const ADMIN_GROUP_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Falcon Usage', 'is_falcon', 'boolean'),
    generateFilteringProperty('Egret Usage', 'is_egret', 'boolean'),
]

export const DELIVERABLE_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Deliverable Name', 'deliverable_name', 'string'),
    generateFilteringProperty('Description', 'description', 'string'),
    generateFilteringProperty('Delivery Year', 'delivery_year', 'string'),
    generateFilteringProperty('Delivery Quarter', 'delivery_quarter', 'string'),
    generateFilteringProperty('Priority', 'priority', 'string'),
    generateFilteringProperty('Category', 'category', 'string'),
    generateFilteringProperty('Pillars', 'pillars', 'string'),
    generateFilteringProperty('AR Dependencies', 'ar_dependencies', 'string'),
    generateFilteringProperty('Updated by', 'updated_by', 'string'),
    generateFilteringProperty('Updated at', 'updated_at', 'string'),
]

export const HR_MEMBER_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Resource Name', 'resource_name', 'string'),
    generateFilteringProperty('Alias', 'alias', 'string'),
    generateFilteringProperty('Manager Alias', 'manager_alias', 'string'),
    generateFilteringProperty('Business Title', 'business_title', 'string'),
]

export const AUDIT_FILTERING_PROPERTIES: any[] = [
    dateFilterProperty(generateFilteringProperty('Date Time', 'date', 'date')),
    generateFilteringProperty('Type', 'object_type', 'string'),
    generateFilteringProperty('Action', 'action', 'string'),
    generateFilteringProperty('User', 'user_alias', 'string'),
]

export const AUDIT_FILTERING_PROPERTIES_PROGRAM: any[] = AUDIT_FILTERING_PROPERTIES.concat([
    generateFilteringProperty('STL Alias', 'stl_alias', 'string'),
    generateFilteringProperty('Program Name', 'program_name', 'string'),
])

export const AUDIT_FILTERING_PROPERTIES_DISCRETIONARY_SPEND: any[] =
    AUDIT_FILTERING_PROPERTIES.concat([
        generateFilteringProperty('Program Name', 'spend_program_name', 'string'),
        generateFilteringProperty('Group Name', 'spend_group_name', 'string'),
        generateFilteringProperty('Expense ID', 'ds_item_id_display', 'string'),
        generateFilteringProperty('Total Amount', 'total_expenditure', 'number'),
    ])

export const AUDIT_FILTERING_PROPERTIES_HC_ESTIMATE: any[] = AUDIT_FILTERING_PROPERTIES.concat([
    generateFilteringProperty('Group Name', 'hc_estimate_group_name', 'string'),
    generateFilteringProperty('Program Name', 'hc_estimate_program_name', 'string'),
    generateFilteringProperty('Deliverable Name', 'hc_estimate_deliverable_name', 'string'),
])

export const AUDIT_FILTERING_PROPERTIES_DELIVERABLE: any[] = AUDIT_FILTERING_PROPERTIES.concat([
    generateFilteringProperty('Program Name', 'deliverable_program_name', 'string'),
    generateFilteringProperty('Deliverable Name', 'deliverable_name', 'string'),
])
