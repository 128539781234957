import { SUMMARY_TABS } from '../../Constant'
import { hideZerosAndTruncateNumbers } from './SummaryUtil'
import { ACTION_TYPES } from './useNewSummaryData'
import styled from 'styled-components'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import {filterColumns, getPinnedRows, GRAND_TOTAL_ROW} from './PreferencesUtil'

const HC_TYPE = 'headcount_type'

const ATL_COLOR = 'rgba(70, 227, 114, 0.1)'
const DELIVERABLE_BTL_COLOR = 'rgba(255, 0, 92, 0.1)'
const PROGRAM_BTL_COLOR = 'rgba(255, 0, 92, 1)'

const CustomDeliverableBadge = styled.span<{ $atl: boolean }>`
    margin: 2rem;
    background: ${(props) => (props.$atl ? ATL_COLOR : DELIVERABLE_BTL_COLOR)};
    border-radius: 70%;
    padding: 0.5rem;
`

const CustomProgramBadge = styled.span<{ $atl: boolean }>`
    margin: 2rem;
    background: ${(props) => (props.$atl ? ATL_COLOR : PROGRAM_BTL_COLOR)};
    border-radius: 70%;
    padding: 0.3rem;
`

const createRowMapFromDeliverables = (deliverables, programData = {}) => {
    const rowMap: any = {}

    Object.entries(deliverables).forEach((entry: any) => {
        const program_name = entry[0]
        const deliverableList = entry[1]

        if (!program_name || !deliverableList) {
            return
        }

        Array.from(deliverableList).forEach((deliverable: any) => {
            const programId = deliverableList[0]?.program_id
            const isOpGoal = programData[deliverable.program_id]?.is_op_goal === 'True'
            const rowId = `${deliverable.deliverable_id}#${programId}`
            const priority = programData[programId]?.priority
            const ohApplied = programData[programId]?.is_overhead_applied

            if (deliverable?.is_active) {
                rowMap[rowId] = {
                    id: rowId,
                    program_name: program_name,
                    deliverable_name: {
                        name: deliverable?.deliverable_name,
                        priority: deliverable?.priority,
                    },
                    deliverable_id: deliverable?.deliverable_id,
                    program_id: programId,
                    hc_ct: 0,
                    hc_ff: 0,
                    row_total: 0,
                    total_ct: 0,
                    total_ff: 0,
                    scoped: false,
                    is_oh_applied: ohApplied,
                }
            }
        })
    })

    return rowMap
}

const leftPinnedColumns = [
    {
        headerName: 'C&T',
        field: 'hc_ct',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    },
    {
        headerName: 'FF',
        field: 'hc_ff',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    },
    {
        headerName: `Total`,
        field: 'row_total',
        width: 100,
        aggFunc: 'sum',
        colId: 'TotalAggRow',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
        headerTooltip: 'Total (C&T + FF)',
    },
]

const setOrgColumns = (groupOHData, data, initialOrgs) => {
    const colDef = [...orgSummaryInitialColDef, ...leftPinnedColumns]


    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            if (initialOrgs.includes(org.orgName)) {
                const children: any = []
                const orgId = org.orgId
                const orgName = org.orgName

                org.groups.forEach((group) => {
                    const groupColDef = {
                        columnGroupShow: 'open',
                        headerName: group.group_name,
                        field: `${group.group_id}`,
                        colId: `${group.group_id}_id`,
                        groupId: `${group.group_id}`,
                        valueFormatter: (params) => {
                            return params.value ? params.value : 0
                        },
                        width: 100,
                        headerTooltip: `${group.group_name}`,
                        children: [
                            {
                                columnGroupShow: 'open',
                                colId: `${group.group_id}_ct_id`,
                                headerName: 'C&T',
                                field: `${group.group_id}_ct`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                            {
                                columnGroupShow: 'open',
                                colId: `${group.group_id}_ff_id`,
                                headerName: 'FF',
                                field: `${group.group_id}_ff`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                        ],
                    }

                    children.push(groupColDef)
                })

                children.push({
                    headerName: 'C&T',
                    field: `${orgId}_ct`,
                    aggFunc: 'sum',
                    colId: `${orgId}_ct_id`,
                    columnGroupShow: 'closed',
                    cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                })
                children.push({
                    headerName: 'FF',
                    field: `${orgId}_ff`,
                    colId: `${orgId}_ff_id`,
                    aggFunc: 'sum',
                    columnGroupShow: 'closed',
                    cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                })
                children.push({
                    headerName: 'Total',
                    field: `${orgId}_total`,
                    colId: `${orgId}_total_id`,
                    aggFunc: 'sum',
                    columnGroupShow: 'closed',
                    cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                })

                const col = {
                    headerName: orgName,
                    field: orgName,
                    colId: `${orgId}_id`,
                    groupId: `${orgId}_group_id`,
                    width: 100,
                    aggFunc: 'sum',
                    headerTooltip: orgName,
                    children: children,
                }

                colDef.push(col)
            }
        })
    }

    return { colDef }
}

const setOrgRows = (data, orgsToInclude: string[]) => {
    const orgData = data[ACTION_TYPES.HC].data
    const deliverables = data[ACTION_TYPES.DELIVERABLES].data
    const programData = data[ACTION_TYPES.PROGRAM].data
    const ohFactor = Number(data[ACTION_TYPES.PLAN].selected?.data.overhead_factor)
    const rowMap = createRowMapFromDeliverables(deliverables, programData)
    const groupOHData = { program_name: 'Total OH' }
    const bisData = { program_name: 'BIS Total' }

    for (const hcEstimate of orgData) {
        if (!orgsToInclude.includes(hcEstimate.org_id)) continue

        const rowId = `${hcEstimate.deliverable_id}#${hcEstimate.program_id}`
        const ohApplied = programData[hcEstimate.program_id]?.is_overhead_applied

        if (rowId in rowMap) {
            const row = rowMap[rowId]

            if (HC_TYPE in hcEstimate) {
                const hcType = hcEstimate.headcount_type

                // set the left pinned data
                row[`hc_${hcType}`] += hcEstimate.headcount_value
                row[`total_${hcType}`] += hcEstimate.headcount_value
                row[`row_total`] += hcEstimate.headcount_value

                // set the group data
                row[`${hcEstimate.group_id}_${hcType}`] = hcEstimate.headcount_value

                if (!row[`${hcEstimate.org_id}_${hcType}`]) {
                    row[`${hcEstimate.org_id}_${hcType}`] = 0
                }

                row[`${hcEstimate.org_id}_${hcType}`] += hcEstimate.headcount_value

                if (!row[`${hcEstimate.org_id}_total`]) {
                    row[`${hcEstimate.org_id}_total`] = 0
                }
                row[`${hcEstimate.org_id}_total`] += hcEstimate.headcount_value

                if (ohApplied && ohFactor) {
                    const ohValue = (1 + ohFactor / 100) * hcEstimate.headcount_value

                    if (!groupOHData[`${hcEstimate.group_id}_${hcType}`]) {
                        groupOHData[`${hcEstimate.group_id}_${hcType}`] = 0
                    }

                    if (!groupOHData[`${hcEstimate.group_id}_total`]) {
                        groupOHData[`${hcEstimate.group_id}_total`] = 0
                    }

                    groupOHData[`${hcEstimate.group_id}_${hcType}`] += ohValue
                    groupOHData[`${hcEstimate.group_id}_total`] += ohValue
                }
            }
        }
    }

    // BIS data
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            const orgId = org.orgId
            let orgFF = 0
            let orgCT = 0

            org.groups.forEach((group) => {
                bisData[`${group.group_id}_ct`] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                bisData[`${group.group_id}_ff`] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0

                orgFF += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                orgCT += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
            })

            bisData[`${orgId}_ct`] = orgCT
            bisData[`${orgId}_ff`] = orgFF
            bisData[`${orgId}_total`] = orgCT + orgFF
        })
    }

    const gapData = {}
    Object.entries(bisData).forEach((value: any) => {
        const totalOHHC = groupOHData[value[0]] ?? 0
        const bisHC = value[1]
        gapData[value[0]] = totalOHHC - bisHC
    })
    gapData['program_name'] = 'Gap (Total w/ OH - BIS)'

    const flatData = Object.values(rowMap)

    return { flatData, groupOHData, bisData, gapData }
}

const setGroupColumns = (data, initialGroups) => {
    const ctColDef = {
        headerName: 'C&T',
        field: 'hc_ct',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    }

    const ffColDef = {
        headerName: 'FF',
        field: 'hc_ff',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    }

    const rowHCAggColDef = {
        headerName: `Total`,
        field: 'row_total',
        width: 100,
        aggFunc: 'sum',
        colId: 'TotalAggRow',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
        headerTooltip: 'Total (C&T + FF)',
    }

    const colDef = [...orgSummaryInitialColDef, ctColDef, ffColDef, rowHCAggColDef]

    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            const orgId = org.orgId
            let orgFF = 0
            let orgCT = 0

            org.groups.forEach((group) => {
                if (initialGroups.includes(group.group_name)) {
                    const groupColDef = {
                        headerName: group.group_name,
                        field: `${group.group_id}`,
                        colId: `${group.group_id}_id`,
                        groupId: `${group.group_id}`,
                        valueFormatter: (params) => {
                            return params.value ? params.value : 0
                        },
                        width: 100,
                        headerTooltip: `${group.group_name}`,
                        children: [
                            {
                                colId: `${group.group_id}_ct_id`,
                                headerName: 'C&T',
                                field: `${group.group_id}_ct`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                            {
                                colId: `${group.group_id}_ff_id`,
                                headerName: 'FF',
                                field: `${group.group_id}_ff`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                            {
                                colId: `${group.group_id}_total_id`,
                                headerName: 'Total',
                                field: `${group.group_id}_total`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                        ],
                    }

                    colDef.push(groupColDef)

                    orgFF += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                    orgCT += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                }
            })
        })
    }
    return { colDef }
}

// TODO refactor - same as org
const setGroupRows = (data, groupsToInclude: string[]) => {
    const orgData = data[ACTION_TYPES.HC].data
    const deliverables = data[ACTION_TYPES.DELIVERABLES].data
    const programData = data[ACTION_TYPES.PROGRAM].data
    const ohFactor = Number(data[ACTION_TYPES.PLAN].selected?.data.overhead_factor)

    const rowMap = createRowMapFromDeliverables(deliverables)
    const groupOHData = { program_name: 'Total OH' }
    const bisData = { program_name: 'BIS Total' }

    for (const hcEstimate of orgData) {
        if (!groupsToInclude.includes(hcEstimate.group_id)) continue

        const rowId = `${hcEstimate.deliverable_id}#${hcEstimate.program_id}`
        const ohApplied = programData[hcEstimate.program_id]?.is_overhead_applied

        if (rowId in rowMap) {
            const row = rowMap[rowId]

            if (HC_TYPE in hcEstimate) {
                const hcType = hcEstimate.headcount_type

                // set the left pinned data
                row[`hc_${hcType}`] += hcEstimate.headcount_value
                row[`total_${hcType}`] += hcEstimate.headcount_value
                row[`row_total`] += hcEstimate.headcount_value

                // set the group data
                row[`${hcEstimate.group_id}_${hcType}`] = hcEstimate.headcount_value
                row[`${hcEstimate.group_id}_${hcType}`] = hcEstimate.headcount_value

                if (!row[`${hcEstimate.group_id}_total`]) {
                    row[`${hcEstimate.group_id}_total`] = 0
                }
                row[`${hcEstimate.group_id}_total`] += hcEstimate.headcount_value

                if (ohApplied && ohFactor) {
                    const ohValue = (1 + ohFactor / 100) * hcEstimate.headcount_value

                    if (!groupOHData[`${hcEstimate.group_id}_${hcType}`]) {
                        groupOHData[`${hcEstimate.group_id}_${hcType}`] = 0
                    }

                    if (!groupOHData[`${hcEstimate.group_id}_total`]) {
                        groupOHData[`${hcEstimate.group_id}_total`] = 0
                    }

                    groupOHData[`${hcEstimate.group_id}_${hcType}`] += ohValue
                    groupOHData[`${hcEstimate.group_id}_total`] += ohValue
                }
            }
        }
    }

    // BIS data
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            const orgId = org.orgId
            let orgFF = 0
            let orgCT = 0

            org.groups.forEach((group) => {
                bisData[`${group.group_id}_ct`] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                bisData[`${group.group_id}_ff`] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0

                orgFF += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                orgCT += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
            })

            bisData[`${orgId}_ct`] = orgCT
            bisData[`${orgId}_ff`] = orgFF
            bisData[`${orgId}_total`] = orgCT + orgFF
        })
    }

    const gapData = {}
    Object.entries(bisData).forEach((value: any) => {
        const totalOHHC = groupOHData[value[0]] ?? 0
        const bisHC = value[1]
        gapData[value[0]] = totalOHHC - bisHC
    })
    gapData['program_name'] = 'Gap (Total w/ OH - BIS)'

    const flatData = Object.values(rowMap)

    return { flatData, groupOHData, bisData, gapData }
}

const setProgramColumns = (groupOHData, groups, initialOrgs) => {
    const deliverableColDef = {
        headerName: 'Deliverable',
        field: 'deliverable_name',
        width: 300,
        pinned: 'left',
        headerTooltip: 'Deliverable',
        cellRenderer: (params) => deliverableCellRenderer(params, true),
    }

    const ctColDef = {
        headerName: 'C&T',
        field: 'hc_ct',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    }

    const ffColDef = {
        headerName: 'FF',
        field: 'hc_ff',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    }

    const rowHCAggColDef = {
        headerName: `Total`,
        field: 'row_total',
        width: 100,
        aggFunc: 'sum',
        colId: 'TotalAggRow',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
        headerTooltip: 'Total (C&T + FF)',
    }

    const colDef: any[] = [deliverableColDef, ctColDef, ffColDef, rowHCAggColDef]

    const bisData = { deliverable_name: 'BIS Total' }

    groups.forEach((org) => {
        if (initialOrgs.includes(org.orgName)) {
            const children: any = []
            const orgId = org.orgId
            const orgName = org.orgName
            let orgFF = 0
            let orgCT = 0

            org.groups.forEach((group) => {
                const groupColDef = {
                    columnGroupShow: 'open',
                    headerName: group.group_name,
                    field: `${group.group_id}`,
                    colId: `${group.group_id}_id`,
                    groupId: `${group.group_id}`,
                    valueFormatter: (params) => {
                        return params.value ? params.value : 0
                    },
                    width: 100,
                    headerTooltip: `${group.group_name}`,
                    children: [
                        {
                            columnGroupShow: 'open',
                            colId: `${group.group_id}_ct_id`,
                            headerName: 'C&T',
                            field: `${group.group_id}_ct`,
                            aggFunc: 'sum',
                            cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                        },
                        {
                            columnGroupShow: 'open',
                            colId: `${group.group_id}_ff_id`,
                            headerName: 'FF',
                            field: `${group.group_id}_ff`,
                            aggFunc: 'sum',
                            cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                        },
                    ],
                }

                children.push(groupColDef)
                bisData[`${group.group_id}_ct`] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                bisData[`${group.group_id}_ff`] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0

                orgFF += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                orgCT += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
            })

            bisData[`${orgId}_ct`] = orgCT
            bisData[`${orgId}_ff`] = orgFF
            bisData[`${orgId}_total`] = orgCT + orgFF

            children.push({
                headerName: 'C&T',
                field: `${orgId}_ct`,
                aggFunc: 'sum',
                colId: `${orgId}_ct_id`,
                columnGroupShow: 'closed',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })
            children.push({
                headerName: 'FF',
                field: `${orgId}_ff`,
                colId: `${orgId}_ff_id`,
                aggFunc: 'sum',
                columnGroupShow: 'closed',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })
            children.push({
                headerName: 'Total',
                field: `${orgId}_total`,
                colId: `${orgId}_total_id`,
                aggFunc: 'sum',
                columnGroupShow: 'closed',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })

            const col = {
                headerName: orgName,
                field: orgName,
                colId: `${orgId}_id`,
                groupId: `${orgId}_group_id`,
                width: 100,
                aggFunc: 'sum',
                headerTooltip: orgName,
                children: children,
            }

            colDef.push(col)
        }
    })

    const gapData = {}
    Object.entries(bisData).forEach((value: any) => {
        const totalOHHC = groupOHData[value[0]] ?? 0
        const bisHC = value[1]
        gapData[value[0]] = totalOHHC - bisHC
    })
    gapData['deliverable_name'] = 'Gap (Total w/ OH - BIS)'

    return { colDef, bisData, gapData }
}

const setProgramRows = (orgData, deliverables) => {
    const rowMap = createRowMapFromDeliverables(deliverables)
    const groupOHData = { deliverable_name: 'Total OH' }

    orgData.forEach((hcEstimate: any) => {
        const rowId = `${hcEstimate.deliverable_id}#${hcEstimate.program_id}`
        if (rowId in rowMap) {
            const row = rowMap[rowId]

            if (HC_TYPE in hcEstimate) {
                const hcType = hcEstimate.headcount_type

                // set the left pinned data
                row[`hc_${hcType}`] += hcEstimate.headcount_value
                row[`total_${hcType}`] += hcEstimate.headcount_value
                row['row_total'] += hcEstimate.headcount_value

                // set the group data
                row[`${hcEstimate.group_id}_${hcType}`] = hcEstimate.headcount_value

                if (!row[`${hcEstimate.org_id}_${hcType}`]) {
                    row[`${hcEstimate.org_id}_${hcType}`] = 0
                }

                row[`${hcEstimate.org_id}_${hcType}`] += hcEstimate.headcount_value

                if (!row[`${hcEstimate.org_id}_total`]) {
                    row[`${hcEstimate.org_id}_total`] = 0
                }
                row[`${hcEstimate.org_id}_total`] += hcEstimate.headcount_value
            }
        }
    })

    const flatData = Object.values(rowMap)

    return { flatData, groupOHData }
}

const setSpendColumns = (groupOHData, data) => {
    // TODO implementation
}

const setSpendRows = (orgData, deliverables, data) => {
    // TODO implementation
    return data[ACTION_TYPES.SPEND].data
}

const setPlanColumns = (data) => {
    const colDef = [...orgSummaryInitialColDef, ...leftPinnedColumns]
    const be = data[ACTION_TYPES.BE]
    const beName = be?.business_entity_name

    const childrenColDef: any[] = []

    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            const children: any = []
            const orgId = org.orgId
            const orgName = org.orgName

            children.push({
                headerName: 'C&T',
                field: `${orgId}_ct`,
                aggFunc: 'sum',
                colId: `${orgId}_ct_id`,
                columnGroupShow: 'open',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })

            children.push({
                headerName: 'FF',
                field: `${orgId}_ff`,
                colId: `${orgId}_ff_id`,
                aggFunc: 'sum',
                columnGroupShow: 'open',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })

            children.push({
                headerName: 'Total',
                field: `${orgId}_total`,
                colId: `${orgId}_total_id`,
                aggFunc: 'sum',
                columnGroupShow: 'open',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })

            const col = {
                headerName: orgName,
                field: orgName,
                colId: `${orgId}_id`,
                groupId: `${orgId}_group_id`,
                width: 100,
                columnGroupShow: 'open',
                aggFunc: 'sum',
                headerTooltip: orgName,
                children: children,
            }

            childrenColDef.push(col)
        })
    }

    const BEColDef = {
        headerName: beName,
        field: 'business_entity_name',
        width: 200,
        headerTooltip: 'Business Entity',
        children: childrenColDef,
    }

    colDef.push(BEColDef)

    return { colDef }
}

const setPlanRows = (data) => {
    const orgData = data[ACTION_TYPES.HC].data
    const deliverables = data[ACTION_TYPES.DELIVERABLES].data
    const programData = data[ACTION_TYPES.PROGRAM].data
    const ohFactor = Number(data[ACTION_TYPES.PLAN].selected?.data.overhead_factor)

    const rowMap = createRowMapFromDeliverables(deliverables)
    const groupOHData = { program_name: 'Total OH' }
    const bisData = { program_name: 'BIS Total' }

    orgData.forEach((hcEstimate: any) => {
        const rowId = `${hcEstimate.deliverable_id}#${hcEstimate.program_id}`
        if (rowId in rowMap) {
            const row = rowMap[rowId]

            if (HC_TYPE in hcEstimate) {
                const hcType = hcEstimate.headcount_type

                // set the left pinned data
                row[`hc_${hcType}`] += hcEstimate.headcount_value
                row[`total_${hcType}`] += hcEstimate.headcount_value
                row[`row_total`] += hcEstimate.headcount_value

                // set the org data
                if (!row[`${hcEstimate.org_id}_${hcType}`]) {
                    row[`${hcEstimate.org_id}_${hcType}`] = 0
                }

                row[`${hcEstimate.org_id}_${hcType}`] += hcEstimate.headcount_value

                if (!row[`${hcEstimate.org_id}_total`]) {
                    row[`${hcEstimate.org_id}_total`] = 0
                }

                row[`${hcEstimate.org_id}_total`] += hcEstimate.headcount_value
            }
        }
    })

    // BIS data
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            const orgId = org.orgId
            let orgFF = 0
            let orgCT = 0

            org.groups.forEach((group) => {
                bisData[`${group.group_id}_ct`] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                bisData[`${group.group_id}_ff`] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0

                orgFF += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                orgCT += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
            })

            bisData[`${orgId}_ct`] = orgCT
            bisData[`${orgId}_ff`] = orgFF
            bisData[`${orgId}_total`] = orgCT + orgFF
        })
    }

    const gapData = {}
    Object.entries(bisData).forEach((value: any) => {
        const totalOHHC = groupOHData[value[0]] ?? 0
        const bisHC = value[1]
        gapData[value[0]] = totalOHHC - bisHC
    })

    gapData['program_name'] = 'Gap (Total w/ OH - BIS)'

    const flatData = Object.values(rowMap)

    return { flatData, groupOHData, bisData, gapData }
}

export function setRowsAndColumns(
    tab: SUMMARY_TABS,
    data,
    initialOrgs,
    initialGroups,
    initialColumns,
    initialRows,
) {
    // Clear out the rows and columns
    data.gridApi.showLoadingOverlay()
    data.gridApi.setGridOption('columnDefs', [])
    data.gridApi.setGridOption('rowData', [])
    data.gridApi.setGridOption('pinnedBottomRowData', [])

    switch (tab) {
        case SUMMARY_TABS.PROGRAM: {
            // filter HC & deliverables to generate rows.
            const selectedProgram = data[ACTION_TYPES.PROGRAM]?.selected

            if (!selectedProgram) return

            const deliverables = {
                [selectedProgram.label]:
                    data[ACTION_TYPES.DELIVERABLES].data[selectedProgram.label],
            }
            const orgData = data[ACTION_TYPES.HC].data.filter(
                (hcItem: any) => hcItem.program_id === data[ACTION_TYPES.PROGRAM].selected.value,
            )

            const { flatData, groupOHData } = setProgramRows(orgData, deliverables)

            const groupsToInclude = orgData.map((deliverable) => deliverable.group_id)
            const orgsToInclude = orgData.map((deliverable) => deliverable.org_id)

            const groups = data[ACTION_TYPES.GROUPS_BY_ORG].data
                .filter((org) => orgsToInclude.includes(org.orgId))
                .map((org) => {
                    return {
                        ...org,
                        groups: org.groups.filter((group) =>
                            groupsToInclude.includes(group.group_id),
                        ),
                    }
                })

            const { colDef }: any = setProgramColumns(groupOHData, groups, initialOrgs)
            const filteredColDef = filterColumns(colDef, initialColumns)

            const rowData = data.showScopedOnly
                ? flatData?.filter((row: any) => row.row_total !== 0)
                : flatData

            data.gridApi.setGridOption('columnDefs', filteredColDef)
            data.gridApi.setGridOption('rowData', rowData)

            break
        }
        case SUMMARY_TABS.GROUP: {
            const allGroups = data[ACTION_TYPES.GROUPS_BY_ORG].data.map((org) => org.groups).flat() // flat array of groups
            const groupsToInclude = initialGroups.map((group) => {
                const groupToInclude = allGroups.find((g) => g.group_name === group)
                return groupToInclude?.group_id
            })

            const { flatData, groupOHData, bisData, gapData } = setGroupRows(data, groupsToInclude)
            const { colDef }: any = setGroupColumns(data, initialGroups)
            const filteredColDef = filterColumns(colDef, initialColumns)

            const rowData = data.showScopedOnly
                ? flatData?.filter((row: any) => row.row_total !== 0)
                : flatData

            data.gridApi.setGridOption('columnDefs', filteredColDef)
            data.gridApi.setGridOption('rowData', rowData)

            const pinnedRows = getPinnedRows(bisData, groupOHData, gapData, initialRows)
            data.gridApi.setGridOption('pinnedBottomRowData', pinnedRows)

            break
        }
        case SUMMARY_TABS.ORG: {
            const orgsToInclude = initialOrgs.map((org) => {
                const orgToInclude = data[ACTION_TYPES.ORG].options.find((o) => o.label === org)
                return orgToInclude?.value
            })

            const { flatData, groupOHData, bisData, gapData } = setOrgRows(data, orgsToInclude)

            const { colDef }: any = setOrgColumns(groupOHData, data, initialOrgs)

            const filteredColDef = filterColumns(colDef, initialColumns)

            const rowData = data.showScopedOnly
                ? flatData?.filter((row: any) => row.row_total !== 0)
                : flatData

            data.gridApi.setGridOption('columnDefs', filteredColDef)
            data.gridApi.setGridOption('rowData', rowData)

            const pinnedRows = getPinnedRows(bisData, groupOHData, gapData, initialRows)
            data.gridApi.setGridOption('pinnedBottomRowData', pinnedRows)
            data.gridApi.redrawRows()

            break
        }
        case SUMMARY_TABS.PLAN: {
            const { flatData, groupOHData, bisData, gapData } = setPlanRows(data)
            const { colDef }: any = setPlanColumns(data)
            const filteredColDef = filterColumns(colDef, initialColumns)

            data.gridApi.setGridOption('columnDefs', filteredColDef)
            data.gridApi.setGridOption('rowData', flatData)

            const pinnedRows = getPinnedRows(bisData, groupOHData, gapData, initialRows)
            data.gridApi.setGridOption('pinnedBottomRowData', pinnedRows)

            break
        }
        // case SUMMARY_TABS.SPEND:
        default:
            break
    }

    if (initialRows.includes('total')) {
        data.gridApi.setGridOption(GRAND_TOTAL_ROW, "bottom")
    }
    else {
        data.gridApi.setGridOption(GRAND_TOTAL_ROW, undefined)
    }

    if (data.gridApi.getDisplayedRowCount() === 0) {
        data.gridApi.setGridOption('pinnedBottomRowData', [])
        data.gridApi.showNoRowsOverlay()
    } else {
        data.gridApi.redrawRows()
        data.gridApi.hideOverlay()
    }

}

const deliverableCellRenderer = (params: any, isProgram = false) => {
    const value = params?.value
    if (value) {
        return (
            <>
                {value?.priority && (
                    <CustomDeliverableBadge $atl={true}>
                        {value.priority.toUpperCase()}
                    </CustomDeliverableBadge>
                )}
                {value?.name ? value.name : value}
            </>
        )
    } else {
        if (isProgram) return 'Total'
    }
}

// TODO figure out grouping with custom rendering
const programCellRenderer = (params: any) => {
    const value = params?.value
    const atl = value?.is_op_goal
    const priority = value?.priority
    if (value) {
        return (
            <>
                {atl !== undefined && (
                    <CustomProgramBadge $atl={atl}>
                        {' '}
                        {atl ? <NorthIcon /> : <SouthIcon />}
                        <b>{priority || ''}</b>
                    </CustomProgramBadge>
                )}
                {value?.name ? value.name : value}
            </>
        )
    }
}

export const orgSummaryInitialColDef: any[] = [
    {
        headerName: 'Program',
        field: 'program_name',
        rowGroup: true,
        hide: true,
        pinned: 'left',
        headerTooltip: 'Program',
        cellRenderer: programCellRenderer,
    },
    {
        headerName: 'Deliverable',
        field: 'deliverable_name',
        pinned: 'left',
        headerTooltip: 'Deliverable',
        cellRenderer: (params) => deliverableCellRenderer(params, false),
    },
]

export const ProgramTableColumns = [
    {
        headerName: 'STL Name',
        field: 'stl_name',
    },
    {
        headerName: 'STL Alias',
        field: 'stl_alias',
    },
    {
        headerName: 'Program',
        field: 'program_name',
    },
    {
        headerName: 'Subject to OH',
        field: 'is_overhead_applied',
    },
    {
        headerName: 'STL Name',
        field: 'stl_name',
    },
    {
        headerName: 'Registered users',
        field: 'registered_users',
        cellRenderer: (params) => params.value?.join(',') || '',
    },
    {
        headerName: 'Description',
        field: 'description',
    },
    {
        headerName: 'Owned Org.',
        field: 'owned_org',
    },
    {
        headerName: 'Program Theme',
        field: 'program_theme',
    },
    {
        headerName: 'Program Note',
        field: 'program_note',
    },
    {
        headerName: 'Active',
        field: 'is_active',
    },
    {
        headerName: 'Op Goal',
        field: 'is_op_goal',
    },
]
