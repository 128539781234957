import { Button, ButtonDropdown } from '@amzn/awsui-components-react'

import xlsx from 'json-as-xlsx'
import { ARRAY_VALUE_COLUMNS } from '../../Constant'

interface ExportTableProps {
    tableData: readonly any[]
    tableColumnDef: any[]
    visibleColumns: any[]
    fileName: string
    disabled?: boolean
    objectNameAttribute?: string
    enableDropdown?: boolean
    includeAggregatedSubTableData?: boolean
    parentSheetName?: string
    aggregatedSubDataSheetName?: string
    columnsWithObjectValues?: any
    subTableData?: any[]
    subTableColumnDef?: any[]
    subTableVisibleColumns?: any[]
    customizedExportButtons?: any[]
    allColumns?: any[]
}

const ExportTable = (props: ExportTableProps) => {
    const {
        tableData,
        tableColumnDef,
        visibleColumns,
        fileName,
        disabled,
        objectNameAttribute = '',
        enableDropdown = false,
        includeAggregatedSubTableData = false,
        parentSheetName = '',
        aggregatedSubDataSheetName = '',
        columnsWithObjectValues = {},
        subTableData = [],
        subTableColumnDef = [],
        subTableVisibleColumns = [],
        customizedExportButtons = [],
        allColumns = [],
    } = props

    const compareSheetNames = (name1, name2) => {
        return name1.sheet.localeCompare(name2.sheet)
    }

    const generateListOfObjects = () => {
        const objectList: string[] = []
        tableData.forEach((data) => {
            objectList.push(data[objectNameAttribute])
        })
        return objectList
    }

    const handleExport = (mainTableVisibleColumns) => {
        const data: any[] = []
        setUpData(tableData, mainTableVisibleColumns, tableColumnDef, data, true)
        exportCsv(data)
    }

    const handleExportsWithSubTables = (mainTableVisibleColumns) => {
        const data: any[] = []
        setUpData(tableData, mainTableVisibleColumns, tableColumnDef, data, true)
        const subdata: any[] = []
        const objectNameList = generateListOfObjects()
        Object.keys(subTableData).forEach((parent) => {
            if (objectNameList.includes(parent)) {
                setUpData(
                    subTableData[parent],
                    subTableVisibleColumns,
                    subTableColumnDef,
                    subdata,
                    false,
                    parent,
                )
            }
        })
        if (includeAggregatedSubTableData && subdata.length) {
            const aggregatedData: any[] = subdata.flatMap((data) => data.content)
            data.push({
                sheet: aggregatedSubDataSheetName,
                columns: subdata[0].columns,
                content: aggregatedData.sort((value1, value2) => {
                    return value1.org_name.localeCompare(value2.org_name)
                }),
            })
        }
        subdata.sort(compareSheetNames)
        exportCsv([...data, ...subdata])
    }

    const setUpData = (data, visibleCol, columnDef, dataList, parentSheet, parent = '') => {
        if (data.length > 0) {
            const csvHeaderNameMapping: { string: string } = generateColumnNameMapping(columnDef)
            const filteredJsonData = visibleCol ? filterUnseenDataColumns(visibleCol, data) : data
            const columns: any[] = []
            visibleCol.forEach((header) => {
                columns.push({
                    label: csvHeaderNameMapping[header],
                    value: ARRAY_VALUE_COLUMNS.includes(header)
                        ? (row) => row[header]?.toString()
                        : header,
                })
            })

            dataList.push({
                sheet: parentSheet ? parentSheetName : parent,
                columns: columns,
                content: filteredJsonData,
            })
        }
    }

    const accessValueInObject = (key, value) => {
        return value[key]
    }

    const filterUnseenDataColumns = (visibleCol, currentData) => {
        const filteredData: any[] = []
        currentData.filter((data) => {
            const filteredSubData = {}
            Object.keys(data).forEach((key) => {
                if (visibleCol.includes(key)) {
                    filteredSubData[key] = Object.keys(columnsWithObjectValues).includes(key)
                        ? accessValueInObject(columnsWithObjectValues[key], data[key])
                        : data[key]
                }
            })
            filteredData.push(filteredSubData)
        })
        return filteredData
    }

    const generateColumnNameMapping = (columnDef) => {
        const nameMapping: any = {}
        for (let i = 0; i < columnDef.length; i++) {
            nameMapping[columnDef[i].id] = columnDef[i].exportColumnName
        }
        return nameMapping
    }

    const exportCsv = (data) => {
        const settings = {
            fileName: fileName,
            extraLength: 3,
            writeMode: 'writeFile',
            writeOptions: {},
            RTL: false,
        }

        xlsx(data, settings)
    }

    return enableDropdown ? (
        <ButtonDropdown
            items={[
                {
                    text: 'Export',
                    id: 'export',
                    disabled: !tableData.length || disabled,
                },
                {
                    text: 'Export with Detail',
                    id: 'export_sub_data',
                    disabled: !tableData.length || disabled,
                },
                ...customizedExportButtons,
            ]}
            onItemClick={({ detail }) => {
                switch (detail.id) {
                    case 'export':
                        handleExport(visibleColumns)
                        break
                    case 'export_sub_data':
                        handleExportsWithSubTables(visibleColumns)
                        break
                    case 'export_with_program_metadata':
                        handleExportsWithSubTables(allColumns)
                        break
                    default:
                        handleExport(visibleColumns)
                        break
                }
            }}
        >
            Export
        </ButtonDropdown>
    ) : (
        <Button
            onClick={() => handleExport(visibleColumns)}
            disabled={!tableData.length || disabled}
        >
            Export
        </Button>
    )
}

export default ExportTable
