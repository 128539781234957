import Modal from '@amzn/awsui-components-react/polaris/modal'
import FormField from '@amzn/awsui-components-react/polaris/form-field'
import Select from '@amzn/awsui-components-react/polaris/select'
import { useEffect, useRef, useState } from 'react'
import { useQueryState } from '../../UseQuery'
import useStore from '../../Store'
import { SpaceBetween, TokenGroup } from '@amzn/awsui-components-react'
import { Toggle } from '@amzn/awsui-components-react'
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces'
import { SUMMARY_TABS } from '../../Constant'
import Alert from '@amzn/awsui-components-react/polaris/alert'
import {
    COLUMN_PREFERENCES,
    COLUMNS,
    MAX_GROUP_ALERT,
    MAX_ORG_ALERT,
    MIN_GROUP_ALERT,
    MIN_ORG_ALERT,
    PREFERENCE_LIMIT,
    PROGRAM_ROW_PREFERENCES,
    ROW_PREFERENCES,
    ROWS,
} from './PreferencesUtil'

export const Preferences = ({
    visible,
    onDismiss,
    initialSelectedOrgs,
    initialSelectedGroups,
    initialSelectedRows,
    initialSelectedColumns,
    orgs,
    groups,
    preferenceType,
}: {
    visible: boolean
    onDismiss: () => void
    initialSelectedOrgs: string[]
    initialSelectedGroups: string[]
    initialSelectedRows: string[]
    initialSelectedColumns: string[]
    orgs: any[]
    groups: any[]
    preferenceType: string
}) => {
    const selectedOrgs = useStore((state) => state.selectedOrgs)
    const setSelectedOrgs = useStore((state) => state.setSelectedOrgs)
    const selectedGroups = useStore((state) => state.selectedGroups)
    const setSelectedGroups = useStore((state) => state.setSelectedGroups)
    const selectedRows = useStore((state) => state.selectedRows)
    const setSelectedRows = useStore((state) => state.setSelectedRows)
    const selectedColumns = useStore((state) => state.selectedColumns)
    const setSelectedColumns = useStore((state) => state.setSelectedColumns)
    const [qsOrgs, setQsOrgs] = useQueryState(SUMMARY_TABS.ORG)
    const [qsGroups, setQsGroups] = useQueryState(SUMMARY_TABS.GROUP)
    const [qsRows, setQsRows] = useQueryState(ROWS)
    const [qsColumns, setQsColumns] = useQueryState(COLUMNS)

    const [searchOrgs, setSearchOrgs] = useState('')
    const [searchGroups, setSearchGroups] = useState('')
    const [filteredOrgs, setFilteredOrgs] = useState(orgs)
    const [filteredGroups, setFilteredGroups] = useState(groups)
    const [alertVisible, setAlertVisible] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const rowPreference =
        preferenceType === SUMMARY_TABS.PROGRAM ? PROGRAM_ROW_PREFERENCES : ROW_PREFERENCES

    const updateState = (initialSelected: string[], setSelected: (tokens: string[]) => void) => {
        if (initialSelected && initialSelected.length > 0) {
            setSelected(initialSelected)
        }
    }

    useEffect(() => {
        updateState(initialSelectedGroups, setSelectedGroups)
    }, [initialSelectedGroups, selectedGroups, setSelectedGroups, groups])

    useEffect(() => {
        updateState(initialSelectedOrgs, setSelectedOrgs)
    }, [initialSelectedOrgs, selectedOrgs, setSelectedOrgs, orgs])

    useEffect(() => {
        updateState(initialSelectedRows, setSelectedRows)
    }, [initialSelectedRows, selectedRows, setSelectedRows])

    useEffect(() => {
        updateState(initialSelectedColumns, setSelectedColumns)
    }, [initialSelectedColumns, selectedColumns, setSelectedColumns])

    const handleGroupChange = (option: OptionDefinition) => {
        if (selectedGroups.length >= PREFERENCE_LIMIT) {
            setAlertMessage(MAX_GROUP_ALERT)
            setAlertVisible(true)
        }
        const value = option.value
        if (!selectedGroups.includes(value)) {
            const newSelectedGroups = [...selectedGroups, value]
            setSelectedGroups(newSelectedGroups)
            setQsGroups(newSelectedGroups.join(','))
        }
    }

    const handleOrgChange = (option: OptionDefinition) => {
        if (selectedOrgs.length >= PREFERENCE_LIMIT) {
            setAlertMessage(MAX_ORG_ALERT)
            setAlertVisible(true)
        } else {
            const value = option.value
            if (!selectedOrgs.includes(value)) {
                const newSelectedOrgs = [...selectedOrgs, value]
                setSelectedOrgs(newSelectedOrgs)
                setQsOrgs(newSelectedOrgs.join(','))
            }
        }
    }

    const handleToggle = (id: string, type: 'column' | 'row') => {
        const updateFunctionMap = {
            column: {
                selected: selectedColumns,
                setSelected: setSelectedColumns,
                setQs: setQsColumns,
            },
            row: { selected: selectedRows, setSelected: setSelectedRows, setQs: setQsRows },
        }

        const { selected, setSelected, setQs } = updateFunctionMap[type]

        const updatedValues = selected.includes(id)
            ? selected.filter((itemId) => itemId !== id)
            : [...selected, id]

        if (updatedValues.length == 0) {
            setAlertMessage(`You must have at least 1 ${type} selected`)
            setAlertVisible(true)
        } else {
            setSelected(updatedValues)
            setQs(updatedValues.join(','))
        }
    }

    useEffect(() => {
        setFilteredOrgs(
            orgs.filter((org) => org.label.toLowerCase().includes(searchOrgs.toLowerCase())),
        )
    }, [searchOrgs, orgs])

    useEffect(() => {
        setFilteredGroups(
            groups.filter((group) =>
                group.label.toLowerCase().includes(searchGroups.toLowerCase()),
            ),
        )
    }, [searchGroups, groups])

    const OrgOptions = orgs.map((token) => ({
        value: token.id,
        label: token.label,
    }))

    const GroupOptions = groups.map((token) => ({
        value: token.id,
        label: token.label,
    }))

    return (
        <Modal onDismiss={onDismiss} visible={visible} header={`${preferenceType} Preferences`}>
            <SpaceBetween direction='vertical' size='xs'>
                {alertVisible && (
                    <Alert
                        dismissible
                        type='error'
                        header='Invalid Operation'
                        onDismiss={() => {
                            setAlertVisible(false)
                            setAlertMessage('')
                        }}
                    >
                        {alertMessage}
                    </Alert>
                )}
                {[SUMMARY_TABS.ORG, SUMMARY_TABS.PROGRAM /*SUMMARY_TABS.SPEND*/]
                    .map(String)
                    .includes(preferenceType) && (
                    <FormField
                        label={'Organizations'}
                        description={`Customize the organization visibility`}
                    >
                        <Select
                            filteringType='auto'
                            filteringPlaceholder={'Search organizations'}
                            selectedOption={null}
                            onChange={({ detail }) => {
                                handleOrgChange(detail.selectedOption)
                            }}
                            options={OrgOptions}
                            placeholder={'Add organizations to table...'}
                        />
                        <TokenGroup
                            onDismiss={({ detail: { itemIndex } }) => {
                                if (selectedOrgs.length == 1) {
                                    setAlertMessage(MIN_ORG_ALERT)
                                    setAlertVisible(true)
                                } else {
                                    const newSelectedOrgs = selectedOrgs.filter(
                                        (_, index) => index !== itemIndex,
                                    )
                                    setSelectedOrgs(newSelectedOrgs)
                                    setQsOrgs(newSelectedOrgs.join(','))
                                }
                            }}
                            items={selectedOrgs.map((org) => ({
                                label: orgs.find((o) => o.id === org)?.label || org,
                                dismissLabel: `Remove ${org}`,
                            }))}
                        />
                    </FormField>
                )}
                {preferenceType === SUMMARY_TABS.GROUP && (
                    <FormField label={'Groups'} description={`Customize the group visibility`}>
                        <Select
                            filteringType='auto'
                            filteringPlaceholder={'Search groups'}
                            selectedOption={null}
                            onChange={({ detail }) => {
                                handleGroupChange(detail.selectedOption)
                            }}
                            options={GroupOptions}
                            placeholder={'Add groups to table...'}
                        />
                        <TokenGroup
                            onDismiss={({ detail: { itemIndex } }) => {
                                if (selectedGroups.length == 1) {
                                    setAlertMessage(MIN_GROUP_ALERT)
                                    setAlertVisible(true)
                                } else {
                                    const newSelectedGroups = selectedGroups.filter(
                                        (_, index) => index !== itemIndex,
                                    )
                                    setSelectedGroups(newSelectedGroups)
                                    setQsGroups(newSelectedGroups.join(','))
                                }
                            }}
                            items={selectedGroups.map((group) => ({
                                label: groups.find((g) => g.id === group)?.label || group,
                                dismissLabel: `Remove ${group}`,
                            }))}
                        />
                    </FormField>
                )}
                <FormField label='Columns' description='Customize the column visibility'>
                    {COLUMN_PREFERENCES.map((col) => (
                        <Toggle
                            key={col.id}
                            checked={selectedColumns.includes(col.id)}
                            onChange={() => handleToggle(col.id, 'column')}
                        >
                            {col.label}
                        </Toggle>
                    ))}
                </FormField>
                <FormField label='Rows' description='Customize the row visibility'>
                    {rowPreference.map((row) => (
                        <Toggle
                            key={row.id}
                            checked={selectedRows.includes(row.id)}
                            onChange={() => handleToggle(row.id, 'row')}
                        >
                            {row.label}
                        </Toggle>
                    ))}
                </FormField>
            </SpaceBetween>
        </Modal>
    )
}

export default Preferences
