import {
    Box,
    Button,
    Container,
    ContentLayout,
    Header,
    Icon,
    Input,
    Select,
    SpaceBetween,
    Tabs,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { ACTION_TYPES } from './useNewSummaryData'
import { formatRevisionOptions, generateToggleComponent } from '../reusable/Utils'
import { useNewSummaryData } from './useNewSummaryData'
import { IconButton, Tooltip } from '@mui/material'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import SmsOutlined from '@mui/icons-material/SmsOutlined'
import { useEffect, useState } from 'react'
import { PLAN, REVISION, SUMMARY_TABS, SUMMARY_TABS_OPTIONS } from '../../Constant'

import { ModuleRegistry } from 'ag-grid-community'
import { ClientSideRowModelModule } from 'ag-grid-community'
import { ServerSideRowModelModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { ExcelExportModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { SetFilterModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { InfiniteRowModelModule } from 'ag-grid-community'
import './SummaryView.scss'
import { LicenseManager } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import { RowGroupingModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { GRAND_TOTAL_COLOR_CODE, NORMAL_CELL_COLOR_CODE } from './SummaryUtil'
import { AgGridReact } from 'ag-grid-react'
import Preferences from './Preferences'
import { useQuery, useQueryState } from '../../UseQuery'
import {
    parseArrayFromString,
    ORG_PREFERENCES_HANDLERS,
    setGroupList,
    ROWS,
    COLUMNS,
    PROGRAM,
    TAB,
    COL_LIST,
    ROW_LIST,
} from './PreferencesUtil'
import { ProgramTableColumns, setRowsAndColumns } from './NewSummariesUtil'
import './SummariesAudits.scss'
import HCDetailModal from '../program/deliverables/HCDetailModal'

LicenseManager.setLicenseKey(agGridLicense)

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    ExcelExportModule,
    SetFilterModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])

const headerBgColor = '#16191f'

const SummariesAudits = () => {
    const { data, dispatch } = useNewSummaryData()
    const [quickFilterText, setQuickFilterText] = useState<string>('')

    const query = useQuery()
    const initialTab = query.get(TAB)
    const [qsTab, setQsTab] = useQueryState(TAB)

    const [selectedTab, setSelectedTab] = useState<SUMMARY_TABS>(
        initialTab ? (initialTab as SUMMARY_TABS) : SUMMARY_TABS.PROGRAM,
    )
    const [isPreferencesVisible, setPreferencesVisible] = useState(false)
    const openPreferencesModal = () => setPreferencesVisible(true)
    const closePreferencesModal = () => setPreferencesVisible(false)
    const [orgs, setOrgs] = useState<{ id: string; label: string }[]>([])
    const [groups, setGroups] = useState<{ id: string; label: string }[]>([])
    const initialOrgs = parseArrayFromString(query.get(SUMMARY_TABS.ORG), [orgs[0]?.label])
    const initialGroups = parseArrayFromString(query.get(SUMMARY_TABS.GROUP), [groups[0]?.label])
    const initialRows = parseArrayFromString(query.get(ROWS), ROW_LIST)
    const initialColumns = parseArrayFromString(query.get(COLUMNS), COL_LIST)
    const [programTableColDefs, setProgramTableColDefs] = useState(ProgramTableColumns)

    const isLoading = () => {
        return (
            data[ACTION_TYPES.DELIVERABLES].loading ||
            data[ACTION_TYPES.PLAN].loading ||
            data[ACTION_TYPES.HC].loading ||
            data[ACTION_TYPES.ORG].loading ||
            data[ACTION_TYPES.PROGRAM].loading ||
            data[ACTION_TYPES.GROUPS_BY_ORG].loading
        )
    }

    useEffect(() => {
        if (!data.gridApi) {
            return
        }

        if (isLoading()) {
            data.gridApi.showLoadingOverlay()
        } else {
            data.gridApi.hideOverlay()
            if (data.gridApi.getDisplayedRowCount() === 0) {
                data.gridApi.showNoRowsOverlay()
            }
        }
    }, [
        data[ACTION_TYPES.PROGRAM].loading,
        data[ACTION_TYPES.DELIVERABLES].loading,
        data[ACTION_TYPES.PLAN].loading,
        data[ACTION_TYPES.HC].loading,
        data[ACTION_TYPES.GROUPS_BY_ORG].loading,
    ])

    const onGridReady = (params: any) => {
        dispatch({ type: 'GRID_API', payload: params.api })
        params.api.autoSizeAllColumns()
    }

    useEffect(() => {
        if (!data.gridApi || isLoading()) {
            return
        }

        setRowsAndColumns(
            selectedTab,
            data,
            initialOrgs,
            initialGroups,
            initialColumns,
            initialRows,
        )
    }, [data, selectedTab, initialOrgs, initialGroups, initialColumns, initialRows])

    useEffect(() => {
        // Load the column definitions and rows for the particular tab
        if (!data.gridApi || isLoading()) {
            return
        }

        const handler = ORG_PREFERENCES_HANDLERS.get(selectedTab)
        handler ? setOrgs(handler(data)) : setOrgs([])
        setGroups(setGroupList(data))
    }, [data, selectedTab])

    return (
        <>
            <ContentLayout
                defaultPadding
                headerBackgroundStyle={headerBgColor}
                headerVariant='high-contrast'
                header={
                    <Box margin={{ left: 's', right: 's' }}>
                        <SpaceBetween size='xs' direction='vertical'>
                            <HeaderTemplate
                                title='Summaries & Audits'
                                subtitle='View organizational summary reports'
                                items={[
                                    { text: 'Home', href: '/' },
                                    { text: `${selectedTab} Summary` },
                                ]}
                            />
                        </SpaceBetween>
                    </Box>
                }
            ></ContentLayout>
            <Container>
                <SpaceBetween size='xs' direction='vertical'>
                    {buildTableHeader(selectedTab, data, dispatch)}
                </SpaceBetween>

                <Tabs
                    onChange={({ detail }) => {
                        setSelectedTab(detail.activeTabId as SUMMARY_TABS)
                        setQsTab(detail.activeTabId)
                    }}
                    activeTabId={selectedTab}
                    tabs={SUMMARY_TABS_OPTIONS}
                />
                {selectedTab === SUMMARY_TABS.PROGRAM && (
                    <div
                        className='ag-theme-quartz' // applying the grid theme
                        // the grid will fill the size of the parent container
                    >
                        <AgGridReact
                            rowData={
                                data[ACTION_TYPES.PROGRAM]?.selected?.data
                                    ? [data[ACTION_TYPES.PROGRAM]?.selected?.data]
                                    : []
                            }
                            columnDefs={programTableColDefs}
                            domLayout='autoHeight'
                        />
                    </div>
                )}
                <div
                    className='ag-theme-quartz' // applying the grid theme
                    style={{ height: '60vh', paddingTop: '20px' }} // the grid will fill the size of the parent container
                >
                    <div className='ag-grid-header-extras'>
                        <Input
                            value={quickFilterText}
                            onChange={({ detail }) => setQuickFilterText(detail.value)}
                            placeholder={'Search table...'}
                        />
                        <Button onClick={openPreferencesModal}>
                            Preferences <Icon name='settings' />
                        </Button>
                        <Preferences
                            visible={isPreferencesVisible}
                            onDismiss={closePreferencesModal}
                            initialSelectedOrgs={initialOrgs}
                            initialSelectedGroups={initialGroups}
                            initialSelectedRows={initialRows}
                            initialSelectedColumns={initialColumns}
                            orgs={orgs}
                            groups={groups}
                            preferenceType={selectedTab}
                        />
                    </div>

                    <AgGridReact
                        quickFilterText={quickFilterText}
                        onGridReady={onGridReady}
                        gridOptions={{
                            getRowStyle: (params) => {
                                return {
                                    fontWeight:
                                        params.node.rowPinned ||
                                        params.node.footer /*|| params.node*/
                                            ? 'bold'
                                            : 'normal',
                                    background: params.node.footer
                                        ? GRAND_TOTAL_COLOR_CODE
                                        : NORMAL_CELL_COLOR_CODE,
                                }
                            },
                            pagination: true,
                            groupTotalRow: 'bottom',
                            grandTotalRow: 'bottom',
                            autoSizeStrategy: {
                                type: 'fitCellContents',
                            },
                            autoGroupColumnDef: {
                                headerName: 'Program',
                                field: 'program_name',
                                minWidth: 300,
                                pinned: 'left',
                            },
                            suppressAggFuncInHeader: true,
                            defaultColDef: {
                                lockPosition: true,
                                wrapHeaderText: true,
                                autoHeaderHeight: true,
                                initialWidth: 100,
                            },
                            tooltipShowDelay: 500,
                        }}
                    />
                </div>
            </Container>
        </>
    )
}

function buildTableHeader(type: SUMMARY_TABS, data, dispatch) {
    const [qsProgram, setQsProgram] = useQueryState(PROGRAM)
    const [qsPlan, setQsPlan] = useQueryState(PLAN)
    const [qsRevision, setQsRevision] = useQueryState(REVISION)

    const [hcModalVisible, setHCModalVisible] = useState<boolean>(false)

    const setScopedValue = (val: boolean) => {
        dispatch({
            type: ACTION_TYPES.SCOPED,
            payload: val,
        })
    }

    const handleClickExport = () => {
        data.gridApi?.exportDataAsExcel()
    }

    return (
        <>
            <HCDetailModal
                visible={hcModalVisible}
                onVisibleChange={setHCModalVisible}
                // TODO -> complete data
                hrTeamData={{}}
                hrMemberData={{}}
                employeeTypeData={{}}
            />
            <Box>
                <Box float='left'>
                    {type !== SUMMARY_TABS.PROGRAM ? (
                        <Header>{type} Summary</Header>
                    ) : (
                        <Select
                            options={data[ACTION_TYPES.PROGRAM].options}
                            selectedOption={data[ACTION_TYPES.PROGRAM].selected}
                            onChange={({ detail }) => {
                                setQsProgram(detail.selectedOption.value)
                                dispatch({
                                    type: ACTION_TYPES.PROGRAM,
                                    payload: { key: 'selected', data: detail.selectedOption },
                                })
                            }}
                        />
                    )}
                </Box>
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='s'>
                        <Box padding={{ top: 's' }}>
                            <SpaceBetween direction='horizontal' size='l'>
                                {/* TODO Uncomment when comments are implemented */}
                                {/* <Tooltip
                                    title={
                                        <Box variant='awsui-key-label' color='inherit'>
                                            Comments
                                        </Box>
                                    }
                                    placement='bottom'
                                >
                                    <IconButton aria-label={'comments'} size={'large'} color='primary'>
                                        <SmsOutlined fontSize='large' />
                                    </IconButton>
                                </Tooltip> */}
                                <Box padding={{ top: 's' }}>
                                    <Button onClick={() => setHCModalVisible(true)}>
                                        HC Detail
                                    </Button>
                                </Box>
                                <Box padding={{ top: 's' }}>
                                    <Button onClick={() => handleClickExport()}>Export</Button>
                                </Box>
                            </SpaceBetween>
                        </Box>
                    </SpaceBetween>
                </Box>
            </Box>
            <SpaceBetween direction='horizontal' size='m'>
                <Select
                    filteringType={'auto'}
                    placeholder={'Selected Plan'}
                    selectedOption={data[ACTION_TYPES.PLAN].selected}
                    onChange={({ detail }) => {
                        const option: any = detail.selectedOption
                        const revisions: any = option?.data.revisions.map(formatRevisionOptions)
                        setQsPlan(detail.selectedOption.value)
                        if (revisions.length) {
                            dispatch({
                                type: 'SET_MANY',
                                payload: {
                                    [ACTION_TYPES.REVISION]: [
                                        { key: 'options', data: revisions },
                                        { key: 'selected', data: revisions[0] },
                                    ],
                                },
                            })
                        } else {
                            dispatch({
                                type: 'SET_MANY',
                                payload: {
                                    [ACTION_TYPES.REVISION]: [
                                        { key: 'options', data: [] },
                                        {
                                            key: 'selected',
                                            data: {
                                                label: 'Select Revision',
                                                value: '',
                                            },
                                        },
                                    ],
                                },
                            })
                        }
                        dispatch({
                            type: ACTION_TYPES.PLAN,
                            payload: { key: 'selected', data: option },
                        })
                    }}
                    options={data[ACTION_TYPES.PLAN].options}
                    disabled={!data[ACTION_TYPES.REVISION].options.length}
                />
                <Select
                    filteringType={'auto'}
                    placeholder={'Selected Revision'}
                    selectedOption={data[ACTION_TYPES.REVISION].selected}
                    onChange={({ detail }) => {
                        setQsRevision(detail.selectedOption.value)
                        dispatch({
                            type: ACTION_TYPES.REVISION,
                            payload: {
                                key: 'selected',
                                data: detail.selectedOption,
                            },
                        })
                    }}
                    options={data[ACTION_TYPES.REVISION].options}
                    disabled={!data[ACTION_TYPES.REVISION].options.length}
                />
                {generateToggleComponent(
                    'All',
                    'Scoped',
                    data[ACTION_TYPES.SCOPED],
                    setScopedValue,
                )}
            </SpaceBetween>
        </>
    )
}

export default SummariesAudits
