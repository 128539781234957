import { useCallback, useEffect, useRef, useState } from 'react'
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-quartz.css'
import { ClientSideRowModelModule, ModuleRegistry } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import {
    AppLayout,
    Box,
    Button,
    Container,
    ContentLayout,
    Flashbar,
    Header,
    Select,
    SpaceBetween,
    StatusIndicator,
    TextContent,
    FlashbarProps,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import {
    AdvancedFilterModule,
    FiltersToolPanelModule,
    LicenseManager,
    MenuModule,
    MultiFilterModule,
    RowGroupingModule,
    SetFilterModule,
} from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import useStore from '../../Store'
import { useAppContext } from '../../../context'
import useWebSocket from 'react-use-websocket'
import { getOrgOptions } from '../../common/Util'
import { getMidwayJwtToken } from '../../../auth/MidwayJwtToken'
import {
    formatPlanOptions,
    formatRevisionOptions,
    generateToggleComponent,
} from '../reusable/Utils'
import {
    ALERT_TYPES,
    EMPTY_PLAN_SELECTION,
    GROUP_CELL_STATUS,
    SUMMARY_HEADCOUNT_TYPE,
    WEB_SOCKET_TYPE,
} from '../../Constant'
import SandboxSubmitWizard from './SandboxSubmitWizard'
import { SANDBOX_REVISION } from './ProgramConstants'
import { Avatar, AvatarGroup, IconButton, Tooltip } from '@mui/material'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import {
    formatSelection,
    generateCellTooltip,
    GRAND_TOTAL_COLOR_CODE,
    hideZerosAndTruncateNumbers,
    NORMAL_CELL_COLOR_CODE,
    updateRowItem,
} from '../summary/SummaryUtil'
import {
    generateBannerContent,
    generateCellStyle,
    generateHCCellStyle,
    generateSubmitDisableReason,
    getCellIdentifier,
    HEARTBEAT_INTERVAL_MS,
    HEARTBEAT_TIMEOUT_MS,
    initialColDefs,
    isHCCellEditable,
    isPinnedBottomRow,
    isRevisionClosed,
    NON_EDITABLE_COLOR,
    secondsSinceEpoch,
    TIME_OUT_VALUE_MS,
} from './SandboxUtils'
import SandboxHistoryPanel from './SandboxHistoryPanel'
import _ from 'lodash'
import * as xlsx from 'xlsx'

LicenseManager.setLicenseKey(agGridLicense)

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    RowGroupingModule,
    MultiFilterModule,
    RowGroupingModule,
    MenuModule,
    SetFilterModule,
    FiltersToolPanelModule,
    AdvancedFilterModule,
])

const Sandbox = ({ userAlias, wsURLBase }) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const userGroups = useStore((state) => state.userGroups).filter((group) => group.is_egret)
    const [selectedPlan, setSelectedPlan] = useState<any>()
    const [orgOptions, setOrgOptions] = useState<any>([])
    const [selectedOrg, setSelectedOrg] = useState<any>(
        orgOptions.length
            ? { object_id: orgOptions[0].value, object_name: orgOptions[0].label }
            : { object_id: '', object_name: 'Select Org' },
    )
    const [orgGroups, setOrgGroups] = useState<any>([])
    const [deliverables, setDeliverables] = useState<any>()
    const [plans, setPlans] = useState<any>()
    const [latestRevision, setLatestRevision] = useState<any>()
    const [token, setToken] = useState('')
    const [gridApi, setGridApi] = useState<any>()
    const [rowData, setRowData] = useState<any>([])
    const [visibleRowData, setVisibleRowData] = useState<any>([])
    const [colDefs, setColDefs] = useState<any>(initialColDefs)
    const [submitModalVisible, setSubmitModalVisible] = useState<any>(false)
    const [timeOutCount, setTimeOutCount] = useState<number>(TIME_OUT_VALUE_MS / 1000)
    const [timeOutId, setTimeOutId] = useState<any>(null)
    const [activeUsers, setActiveUsers] = useState<any>({})
    const [sandboxHeadcounts, setSandboxHeadcounts] = useState<any>({})
    const [totalSandboxHeadcounts, setTotalSandboxHeadcounts] = useState<any>([])
    const [sandboxFilteredHeadcounts, setSandboxFilteredHeadcounts] = useState<any>([])
    const [lockedGroupIds, setLockedGroupIds] = useState<any>([])
    const [historyPanelVisible, setHistoryPanelVisible] = useState<boolean>(false)
    const [historyPanelFilters, setHistoryPanelFilters] = useState<any>([])
    const [submissionLock, setSubmissionLock] = useState<boolean>(
        lockedGroupIds.length
            ? !!userGroups.filter((group) => lockedGroupIds.includes(group.group_id))
            : false,
    )
    const [submissionDisable, setSubmissionDisable] = useState<boolean>(true)
    const [showScopedOnlyPrograms, setShowScopedOnlyPrograms] = useState<boolean>(false)
    const [showFTEEstimation, setShowFTEEstimation] = useState<boolean>(true)
    const [newSandboxHeadcounts, setNewSandboxHeadcounts] = useState<any>([])
    const [bannerItems, setBannerItems] = useState<FlashbarProps.MessageDefinition[]>([])
    const [sandboxBannerItems, setSandboxBannerItems] = useState<FlashbarProps.MessageDefinition[]>(
        [],
    )
    const [isSandboxLoading, setIsSandboxLoading] = useState<boolean>(true)
    const [submissionRequester, setSubmissionRequester] = useState<string>('')

    const selectedPlanRef = useRef(EMPTY_PLAN_SELECTION)
    const selectedOrgRef = useRef({
        object_id: 0,
        object_name: 'None',
    })
    const orgGroupsRef = useRef([])
    const userGroupsRef = useRef([])

    const wsUrl = `${wsURLBase}?Authorization=Bearer ${token}`

    const generateHeartbeatMessage = () => {
        const payload =
            !selectedPlanRef.current.value || !selectedOrgRef.current.object_id
                ? {}
                : {
                      plan_id: selectedPlanRef.current.value['plan_id'],
                      org_id: selectedOrgRef.current.object_id,
                  }
        const message: string = JSON.stringify({
            message: {
                type: WEB_SOCKET_TYPE.GET_LATEST_STATE,
                user_groups: userGroupsRef.current,
                payload: payload,
            },
        })
        return message
    }

    const webSocket = useWebSocket(wsUrl, {
        heartbeat: {
            message: generateHeartbeatMessage,
            interval: HEARTBEAT_INTERVAL_MS,
            timeout: HEARTBEAT_TIMEOUT_MS,
        },
        onOpen: () => {
            const jsonMessage = {
                message: {
                    type: WEB_SOCKET_TYPE.GET_LATEST_STATE,
                    payload: {},
                },
            }
            if (webSocket) {
                // eslint-disable-next-line react/prop-types
                webSocket.sendJsonMessage(jsonMessage)
            }
        },
        onClose: () => {},
        onMessage: (e) => {
            if (!e?.data) {
                return
            }
            const messageBody = JSON.parse(e.data)
            const payload = messageBody.body
            const groupId = payload?.group_id
            const requester = payload?.requester
            const headcountType = payload?.headcount_type
            switch (messageBody.type) {
                case WEB_SOCKET_TYPE.HEADCOUNT_ESTIMATE_REVERTED:
                case WEB_SOCKET_TYPE.HEADCOUNT_ESTIMATE_UPDATED: {
                    setNewSandboxHeadcounts([payload, ...newSandboxHeadcounts])

                    // create rowID from deliverable id and program id
                    const rowID = `${payload.deliverable_id}#${payload.program_id}`
                    // find this item in the rowData
                    const oldItem = rowData.find((row) => row.id === rowID)
                    const newItem = { ...oldItem }
                    updateRowItem(newItem, groupId, headcountType, payload, showFTEEstimation)

                    // go through the list of rowData and update the headcount id
                    // TODO - move this code to gridApi.applyTransaction and update just cell level data
                    const headcountData = rowData.map((oldItem) => {
                        return oldItem.id === newItem.id ? newItem : oldItem
                    })
                    setRowData([...headcountData])
                    gridApi.setGridOption('rowData', headcountData)
                    break
                }
                case WEB_SOCKET_TYPE.HEADCOUNT_ESTIMATE_ADDED: {
                    setNewSandboxHeadcounts([payload, ...newSandboxHeadcounts])
                    const rowID = `${payload.deliverable_id}#${payload.program_id}`
                    const oldItem = rowData.find((row) => row.id === rowID)
                    let newItem = {}
                    // This is the first time we are seeing this row_id inside the row_Data
                    if (!oldItem) {
                        newItem['id'] = rowID
                        newItem['deliverable_id'] = payload.deliverable_id
                        newItem['deliverable_name'] = payload.deliverable_name
                        newItem['program_id'] = payload.program_id
                        newItem['program_name'] = payload.program_name
                        newItem['scoped'] = true
                        updateRowItem(newItem, groupId, headcountType, payload, showFTEEstimation)
                        setRowData([...rowData, newItem])
                        gridApi.setGridOption('rowData', [...rowData, newItem])
                    } else {
                        newItem = { ...oldItem }
                        newItem['scoped'] = true
                        updateRowItem(newItem, groupId, headcountType, payload, showFTEEstimation)
                        const headcountData = rowData.map((oldItem) => {
                            return oldItem.id === newItem['id'] ? newItem : oldItem
                        })
                        setRowData([...headcountData])
                        const res = gridApi.applyTransaction({ update: [newItem] })
                        gridApi?.refreshCells({
                            force: true,
                            suppressFlash: true,
                        })
                    }
                    break
                }
                case WEB_SOCKET_TYPE.ROW_SELECTION_UPDATED: {
                    // Status can be locked or unlocked
                    const status = payload['status']
                    // headcount-id is a cell_id
                    // TODO - change this to cell_id in future, requires a backend change
                    const headcountId = payload['headcount_id']
                    const headcountIDArr = headcountId.split('@')
                    if (headcountIDArr.length < 2) {
                        console.error(
                            'Invalid headcount_id while updating row_selection',
                            headcountIDArr,
                        )
                        return
                    }
                    const columnId = headcountIDArr[0]
                    const rowId = headcountIDArr[1]
                    if (!rowId || !columnId) {
                        console.error('Invalid row_id or column_id while updating row_selection')
                        return
                    }
                    const data = gridApi?.getRowNode(rowId)?.data
                    if (!data) {
                        return
                    }

                    // This is for the case when the user generates unlock of the cell after the TTL has expired.
                    // In that case - if we receive a cell unlock message, we check if the cell is still selected. If the cell
                    // is selected we stop editing without commiting the value entered by the user.
                    if (
                        payload['user_id'] == userAlias && // user is the one that initiated the unlock due to timeout
                        status === GROUP_CELL_STATUS.UNLOCKED && // status of the message is unlock
                        data[`${columnId}_locked`] // the cell is locked as the user is still editing it
                    ) {
                        const cellDefs = gridApi.getEditingCells()
                        cellDefs.forEach((cellDef) => {
                            const groupId = cellDef.column.getId()
                            const rowNode = gridApi.getDisplayedRowAtIndex(cellDef.rowIndex)
                            // Only unlock if the column id (group_id) and the row id match ( deliverable_id)
                            if (groupId === columnId && rowNode.id === rowId) {
                                // Do not commit the changes that the user has typed in
                                const cancelEdit = true
                                gridApi.stopEditing(cancelEdit)
                            }
                        })
                    }

                    // TODO - delete this data if status is unlocked
                    data[`${columnId}_locked`] = status === GROUP_CELL_STATUS.LOCKED
                    data[`${columnId}_locked_user`] = payload['user_id']
                    const res = gridApi.applyTransactionAsync({ update: [data] })!
                    // Force refresh cell to re-render the cells
                    gridApi?.refreshCells({
                        force: true,
                        suppressFlash: true,
                    })
                    break
                }
                case WEB_SOCKET_TYPE.UPDATED_USER_LIST: {
                    const currActiveUsers = activeUsers
                    payload.forEach((user) => {
                        if (!(user in currActiveUsers)) {
                            const randomColor = Math.floor(Math.random() * 16777215).toString(16)
                            currActiveUsers[user] = `#${randomColor}`
                        }
                    })
                    setActiveUsers({ ...currActiveUsers })
                    break
                }
                case WEB_SOCKET_TYPE.GROUP_SELECTION_UPDATED: {
                    const status = payload['status'] === GROUP_CELL_STATUS.LOCKED
                    const groupIds = payload['group_ids']
                    // only lock groups if the locked group status is for the selected plan
                    if (payload['plan_id'] !== selectedPlanRef.current.value['plan_id']) {
                        return
                    }

                    setSubmissionRequester(requester)
                    setLockedGroupIds(status ? groupIds : [])
                    if (!status) {
                        getSandboxHistoryHeadcount()
                    }
                    break
                }
                case WEB_SOCKET_TYPE.UPDATED_GROUP_LIST: {
                    const groupIds: string[] = []
                    payload.forEach((group) => {
                        if (group.plan_id === selectedPlanRef.current.value['plan_id']) {
                            groupIds.push(group.id)
                        }
                    })
                    setLockedGroupIds(groupIds)
                    break
                }
                case WEB_SOCKET_TYPE.SUBMIT_STATUS_UPDATED: {
                    setSubmissionDisable(payload)
                    break
                }
                case WEB_SOCKET_TYPE.UPDATED_CELL_LIST: {
                    const cellList = payload
                    const cellsToRefresh: any = []

                    let jsonMessage = {}
                    cellList.forEach((cell) => {
                        const cellIdentifier = getCellIdentifier(cell?.id)
                        const data = gridApi?.getRowNode(cellIdentifier?.rowId)?.data
                        if (!data) {
                            return
                        }

                        data[`${cellIdentifier?.columnId}_locked`] = true
                        data[`${cellIdentifier?.columnId}_locked_user`] = cell['user_id']
                        cellsToRefresh.push(data)
                        // TODO - delete this data if status is unlocked
                        // Check ttl and delete the cell
                        if (cell['user_id'] === userAlias) {
                            const timeNowSecs = secondsSinceEpoch()
                            if (cell['ttl'] < timeNowSecs) {
                                jsonMessage = {
                                    message: {
                                        type: WEB_SOCKET_TYPE.UPDATE_ROW_SELECTION,
                                        payload: {
                                            headcount_id: cell?.id,
                                            cell_status: GROUP_CELL_STATUS.UNLOCKED,
                                        },
                                    },
                                }
                            }
                        }
                    })
                    if (cellsToRefresh.length == 0) {
                        return
                    }

                    const res = gridApi.applyTransactionAsync({ update: cellsToRefresh })!
                    // Force refresh cell to re-render the cells
                    gridApi?.refreshCells({
                        force: true,
                        suppressFlash: true,
                        rowNodes: cellsToRefresh,
                    })

                    // There are times when the updates to the grid are not applied. This can be due to transactions
                    // not getting applied on time. Whenever we receive an updated cell-list , we will trigger a flush
                    // of async events
                    gridApi!.flushAsyncTransactions()
                    if (webSocket && Object.keys(jsonMessage).length) {
                        // eslint-disable-next-line react/prop-types
                        webSocket.sendJsonMessage(jsonMessage)
                    }
                    break
                }
            }
        },
    })

    useEffect(() => {
        userGroupsRef.current = userGroups.map((group) => group.group_id)
    }, [userGroups])

    useEffect(() => {
        if (isRevisionClosed(latestRevision)) {
            setBannerItems([
                generateBannerContent(
                    'info_message_1',
                    ALERT_TYPES.INFO,
                    `Revision ${latestRevision.revision_number} - ${latestRevision.revision_title} is locked - Sandbox edits cannot be submitted.`,
                    setBannerItems,
                ),
            ])
        }
    }, [latestRevision])

    useEffect(() => {
        if (!gridApi) {
            return
        }
        const lockedUserGroups = userGroups.filter((group) =>
            lockedGroupIds.includes(group.group_id),
        )
        setSubmissionLock(!!lockedUserGroups.length)

        const updatedData: any[] = []
        rowData.forEach((row) => {
            const rowId = row.id
            const data = gridApi?.getRowNode(rowId)?.data
            // when "scoped" is toggled, there are instances when data ends up being undefined.
            if (!data) {
                return
            }
            orgGroups.forEach((group) => {
                const groupId = group.group_id
                const lockedStatus = lockedGroupIds.includes(groupId)
                data[`${groupId}_ct_group_locked`] = lockedStatus
                data[`${groupId}_ff_group_locked`] = lockedStatus
            })
            updatedData.push(data)
        })

        // If a cell is being edited and overlaps with the locked groups, stop cell editing
        const cellDefs = gridApi.getEditingCells()
        cellDefs.forEach((cellDef) => {
            const groupId = cellDef.column.getId().split('_')[0]
            if (lockedGroupIds.includes(groupId)) {
                const cancelEdit = true
                gridApi.stopEditing(cancelEdit)
            }
        })

        gridApi.applyTransaction({ update: updatedData })
        gridApi.refreshCells({ force: true })
    }, [lockedGroupIds, rowData])

    const onGridReady = (params: any) => {
        setGridApi(params.api)
    }

    const getOrgGroups = () => {
        if (!selectedOrg?.object_id) {
            return
        }

        selectedOrgRef.current = {
            object_id: selectedOrg.object_id,
            object_name: selectedOrg.object_name,
        }
        apiClient
            .get(`/falcon/orgs/${selectedOrg?.object_id}/groups`)
            .then((res) => {
                const allGroups = res.data.filter((group) => group.is_active && group.is_egret)
                orgGroupsRef.current = allGroups
                setOrgGroups(allGroups)
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getOrgs = () => {
        apiClient
            .get(`/falcon/business-entity/${selectedBusinessEntity.business_entity_id}/orgs`)
            .then((res) => {
                const allOrgs = res.data.filter((org) => org.is_active)
                setOrgOptions(getOrgOptions(allOrgs))
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getAllPlansByBusinessEntity = () => {
        apiClient
            .get(`plan/business-entity/${selectedBusinessEntity.business_entity_id}?year=`)
            .then((response) => {
                const formattedPlanOptions = response.data.map((plan) => formatPlanOptions(plan))
                if (formattedPlanOptions.length) {
                    const selectedPlan = formattedPlanOptions[0]
                    const revisionOptions = selectedPlan.value.revisions
                    setLatestRevision(revisionOptions[0])
                    selectedPlanRef.current = selectedPlan
                    setSelectedPlan(selectedPlan)
                    setPlans(formattedPlanOptions)
                } else {
                    setSelectedPlan({})
                    setPlans([])
                }
            })
            .catch((error) => {
                console.error(error)
                setPlans([])
            })
    }

    const getToken = async () => {
        setToken(await getMidwayJwtToken())
    }

    const getPlanDeliverables = () => {
        if (_.isEmpty(latestRevision) || !selectedPlan) {
            return
        }

        apiClient
            .get(
                `/plan/${selectedPlan?.value?.plan_id}/revision/${latestRevision.revision_id}/deliverables`,
            )
            .then((res) => {
                setDeliverables(res.data)
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const queryHCEstimateAPI = async () => {
        if (!selectedOrg?.object_id || !selectedPlan?.value?.plan_id) {
            return
        }

        apiClient
            .get(
                `/org-headcount-estimates/org/${selectedOrg?.object_id}/plan/${selectedPlan?.value?.plan_id}/revision/${SANDBOX_REVISION}`,
            )
            .then((response) => {
                const data = response.data
                const headcountData: any = []
                // Go through all the deliverables and add it to the program row_group
                Object.entries(deliverables).forEach((value) => {
                    const program_name = value[0]
                    const deliverables: any = value[1]
                    Array.from(deliverables).forEach((deliverable: any) => {
                        if (deliverable?.is_active) {
                            headcountData.push({
                                id: `${deliverable?.deliverable_id}#${deliverable?.program_id}`,
                                program_name: program_name,
                                deliverable_name: deliverable?.deliverable_name,
                                deliverable_id: deliverable?.deliverable_id,
                                program_id: deliverable?.program_id,
                                total: 0,
                                total_ct: 0,
                                total_ff: 0,
                                scoped: false,
                            })
                        }
                    })
                })

                // Go through all the groups in the org and add the groups as columns
                const colDef: any[] = []
                const userGroupSet = new Set(userGroups.map((group) => group.group_id))
                const bisData = { program_name: 'BIS Total' }
                let bisCTTotal = 0
                let bisFFTotal = 0
                orgGroups.forEach((group) => {
                    const groupColDef = {
                        headerName: group.group_name,
                        field: `${group.group_id}`,
                        valueFormatter: (params) => {
                            return params.value ? params.value : 0
                        },
                        children: [
                            {
                                headerName: 'C&T',
                                field: `${group.group_id}_ct`,
                                aggFunc: 'sum',
                                width: 100,
                                type: 'numericColumn',
                                filter: false,
                                floatingFilter: false,
                                tooltipValueGetter: (params) => {
                                    if (isPinnedBottomRow(params)) return
                                    return generateCellTooltip(params)
                                },
                                cellRenderer: (params) => {
                                    return hideZerosAndTruncateNumbers(params)
                                },
                                editable: (params) => {
                                    return isHCCellEditable(
                                        params,
                                        group,
                                        userGroupSet,
                                        selectedPlanRef,
                                        SUMMARY_HEADCOUNT_TYPE.CT,
                                    )
                                },
                                cellStyle: (params) => {
                                    return generateHCCellStyle(
                                        params,
                                        group,
                                        userGroupSet,
                                        SUMMARY_HEADCOUNT_TYPE.CT,
                                        selectedPlanRef,
                                        activeUsers,
                                    )
                                },
                            },
                            {
                                headerName: 'FF',
                                field: `${group.group_id}_ff`,
                                aggFunc: 'sum',
                                width: 100,
                                type: 'numericColumn',
                                filter: false,
                                floatingFilter: false,
                                tooltipValueGetter: (params) => {
                                    if (isPinnedBottomRow(params)) return
                                    return generateCellTooltip(params)
                                },
                                cellRenderer: (params) => {
                                    return hideZerosAndTruncateNumbers(params)
                                },
                                editable: (params) => {
                                    return isHCCellEditable(
                                        params,
                                        group,
                                        userGroupSet,
                                        selectedPlanRef,
                                        SUMMARY_HEADCOUNT_TYPE.FF,
                                    )
                                },
                                cellStyle: (params) => {
                                    return generateHCCellStyle(
                                        params,
                                        group,
                                        userGroupSet,
                                        SUMMARY_HEADCOUNT_TYPE.FF,
                                        selectedPlanRef,
                                        activeUsers,
                                    )
                                },
                            },
                        ],
                    }

                    colDef.push(groupColDef)
                    bisData[`${group.group_id}_ct`] =
                        'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                    bisData[`${group.group_id}_ff`] =
                        'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                    bisCTTotal += bisData[`${group.group_id}_ct`]
                    bisFFTotal += bisData[`${group.group_id}_ff`]
                })
                bisData['total_ct'] = bisCTTotal
                bisData['total_ff'] = bisFFTotal
                bisData['total'] = bisCTTotal + bisFFTotal
                setColDefs(initialColDefs.concat(colDef))
                // Go through all the existing headcount data and populate it in the row groups for only active groups
                const activeGroupIds = orgGroups.map((group) => group.group_id)
                Array.from(data).forEach((headcountEstimate: any) => {
                    if ('headcount_type' in headcountEstimate) {
                        if (
                            headcountEstimate.headcount_type === 'ct' ||
                            headcountEstimate.headcount_type === 'ff'
                        ) {
                            const groupId = headcountEstimate.group_id
                            const type = headcountEstimate.headcount_type
                            headcountData.forEach((row) => {
                                if (
                                    row.program_id === headcountEstimate.program_id &&
                                    row.deliverable_id === headcountEstimate.deliverable_id
                                ) {
                                    const headcountValue = showFTEEstimation
                                        ? headcountEstimate.headcount_value
                                        : headcountEstimate.fte_month_value
                                    const previousValue = showFTEEstimation
                                        ? headcountEstimate.previous_value
                                        : headcountEstimate.previous_fte_month_value

                                    row[`${groupId}_${type}`] = headcountValue
                                    row[`${groupId}_${type}_id`] = headcountEstimate.headcount_id
                                    row.total += activeGroupIds.includes(groupId)
                                        ? headcountValue
                                        : 0
                                    row[`total_${type}`] += activeGroupIds.includes(groupId)
                                        ? headcountValue
                                        : 0
                                    row.scoped = row.total > 0
                                    row[`${groupId}_${type}_updated_by`] =
                                        headcountEstimate.updated_by
                                    row[`${groupId}_${type}_updated_at`] =
                                        headcountEstimate.updated_at
                                    row[`${groupId}_${type}_previous_value`] = previousValue
                                    row[`${groupId}_${type}_hc_val`] =
                                        headcountEstimate.headcount_value
                                    row[`${groupId}_${type}_fte_val`] =
                                        headcountEstimate.fte_month_value
                                }
                            })
                        }
                    }
                })
                setRowData([...headcountData])
                gridApi.setGridOption('rowData', headcountData)
                gridApi.setGridOption('columnDefs', colDefs)
                gridApi.setPinnedBottomRowData([bisData])
                gridApi.redrawRows()
                getToken()
            })
            .catch((err) => {
                console.error(err)
            })
    }

    useEffect(() => {
        getOrgGroups()
    }, [selectedOrg])

    useEffect(() => {
        setVisibleRowData(showScopedOnlyPrograms ? rowData.filter((row) => row.scoped) : rowData)
    }, [showScopedOnlyPrograms, rowData])

    useEffect(() => {
        if (orgOptions.length) {
            selectedOrgRef.current = {
                object_id: orgOptions[0].value,
                object_name: orgOptions[0].label,
            }
            setSelectedOrg({
                object_id: orgOptions[0].value,
                object_name: orgOptions[0].label,
            })
        }
    }, [orgOptions])

    useEffect(() => {
        if (!gridApi) {
            return
        }

        gridApi.showLoadingOverlay()
        getOrgs()
        getAllPlansByBusinessEntity()
    }, [gridApi, selectedBusinessEntity])

    useEffect(() => {
        queryHCEstimateAPI()
    }, [deliverables, orgGroups, showFTEEstimation])

    useEffect(() => {
        if (!selectedPlan || _.isEmpty(latestRevision)) {
            return
        }

        selectedPlanRef.current = selectedPlan
        getPlanDeliverables()
    }, [selectedPlan])

    // both parameters can be string or number
    function toFixed(number, decimals) {
        const x = Math.pow(10, Number(decimals) + 2)
        return (Number(number) + 1 / x).toFixed(decimals)
    }

    const onSelectionChanged = (event) => {
        const columnID = event?.colDef?.field
        const rowID = event?.data?.id
        const data = event?.data
        if (!columnID || !rowID || data[`${columnID}_group_locked`]) {
            return
        }

        const column = event?.column
        const isCellEditable = column.isCellEditable(event?.node)

        // do not update row selection for non-editable cells
        if (!isCellEditable) {
            return
        }

        const jsonMessage = {
            message: {
                type: WEB_SOCKET_TYPE.UPDATE_ROW_SELECTION,
                payload: {
                    headcount_id: `${columnID}@${rowID}`,
                    cell_status: GROUP_CELL_STATUS.LOCKED,
                },
            },
        }
        if (webSocket) {
            // eslint-disable-next-line react/prop-types
            webSocket.sendJsonMessage(jsonMessage)
        }
    }

    const onFocusLost = (event) => {
        const columnID = event?.colDef?.field
        const rowID = event?.data?.id
        if (!columnID && !rowID) {
            console.error(
                'Error while deselecting a cell - ColumnID/RowID not defined',
                event,
                columnID,
                rowID,
            )
            return
        }

        const jsonMessage = {
            message: {
                type: WEB_SOCKET_TYPE.UPDATE_ROW_SELECTION,
                payload: {
                    headcount_id: `${columnID}@${rowID}`,
                    cell_status: GROUP_CELL_STATUS.UNLOCKED,
                },
            },
        }
        if (webSocket) {
            // eslint-disable-next-line react/prop-types
            webSocket.sendJsonMessage(jsonMessage)
        }
    }

    const createHeadcountID = (deliverable_id, groupId, hcType) => {
        return `${deliverable_id}@${groupId}@${hcType}`
    }

    const onCellEditRequest = useCallback(
        (event: any) => {
            const headcountID = event['column']['colId']
            const headcountType = headcountID.split('_')[1]
            let jsonMessage = {}
            if (!event['oldValue']) {
                const columnID = event['column']['colId']
                const groupId = columnID.split('_')[0]
                const hcType = columnID.split('_')[1]
                const groupName: any = orgGroupsRef.current.find((group: any) => {
                    return group['group_id'] === groupId
                })
                const newValue =
                    event.newValue instanceof Number ? event.newValue : parseFloat(event.newValue)
                const headcountValue = showFTEEstimation
                    ? newValue
                    : parseFloat(toFixed(newValue / 12, 2))
                const fteMonthValue = showFTEEstimation ? newValue * 12 : newValue
                if (!headcountValue || !fteMonthValue) {
                    return
                }
                jsonMessage = {
                    message: {
                        type: WEB_SOCKET_TYPE.ADD_HEADCOUNT_ESTIMATE,
                        user_groups: userGroupsRef.current,
                        payload: {
                            headcount_id: createHeadcountID(
                                event['data']['deliverable_id'],
                                groupId,
                                hcType,
                            ),
                            deliverable_id: event['data']['deliverable_id'],
                            deliverable_name: event['data']['deliverable_name'],
                            program_id: event['data']['program_id'],
                            program_name: event['data']['program_name'],
                            plan_id: selectedPlanRef.current.value['plan_id'],
                            group_id: groupId,
                            group_name: groupName.group_name,
                            headcount_type: hcType,
                            org_id: selectedOrgRef.current.object_id,
                            // is_fte_months dictates the units for headcount estimate
                            is_fte_months: !showFTEEstimation,
                            revision_id: SANDBOX_REVISION,
                            business_entity_id: selectedBusinessEntity.business_entity_id,
                            plan_sort: `${selectedPlanRef.current.value['plan_id']}#${SANDBOX_REVISION}`,
                            is_submitted: false,
                            headcount_value: headcountValue,
                            fte_month_value: fteMonthValue,
                            previous_value: 0,
                            previous_fte_month_value: 0,
                        },
                    },
                }
            } else {
                let value = 0
                const colId = event['column']['colId']
                if (event.newValue) {
                    if (event.newValue instanceof Number) {
                        value = event.newValue
                    } else {
                        value = parseFloat(event.newValue)
                    }
                } else if (event.oldValue && event.newValue == null) {
                    value = 0
                }

                const data = event.data
                if (!data) {
                    console.error('data not found')
                }
                const headcountValue = showFTEEstimation
                    ? value
                    : parseFloat(toFixed(value / 12, 2))
                const fteMonthValue = showFTEEstimation ? value * 12 : value
                const previousValue = data[`${colId}_hc_val`]
                const previousFTEValue = data[`${colId}_fte_val`]

                if (headcountValue == previousValue || fteMonthValue == previousFTEValue) {
                    return
                }
                const headcount_id = event['data'][`${headcountID}_id`]
                jsonMessage = {
                    message: {
                        type: WEB_SOCKET_TYPE.UPDATE_HEADCOUNT_ESTIMATE,
                        user_groups: userGroupsRef.current,
                        payload: {
                            plan_id: selectedPlanRef.current.value['plan_id'],
                            org_id: selectedOrgRef.current.object_id,
                            headcount_id: headcount_id,
                            headcount_type: headcountType,
                            plan_sort: `${selectedPlanRef.current.value['plan_id']}#${SANDBOX_REVISION}`,
                            is_submitted: false,
                            is_fte_months: !showFTEEstimation,
                            previous_value: previousValue,
                            previous_fte_month_value: previousFTEValue,
                            headcount_value: headcountValue,
                            fte_month_value: fteMonthValue,
                        },
                    },
                }
            }
            webSocket.sendJsonMessage(jsonMessage)
        },
        [showFTEEstimation],
    )

    const getSandboxHistoryHeadcount = () => {
        if (!selectedOrg.object_id) {
            return
        }

        apiClient
            .get(
                `/sandbox-history/plan/${selectedPlan.value?.plan_id}/org/${selectedOrg.object_id}`,
            )
            .then((response) => {
                const result = response.data
                setSandboxFilteredHeadcounts(result)
                setTotalSandboxHeadcounts(result)
            })
            .catch((err) => {
                console.error(err)
                setSandboxFilteredHeadcounts([])
                setTotalSandboxHeadcounts([])
            })
            .finally(() => {
                setHistoryPanelFilters([])
            })
    }

    const getSandboxHeadcount = () => {
        const userGroupIds = userGroups.map((group) => group.group_id).join(',')
        apiClient
            .get(
                `/sandbox-headcount/plan/${selectedPlan.value?.plan_id}/org/${selectedOrg.object_id}?group_ids=${userGroupIds}`,
            )
            .then((response) => {
                const result = response.data
                const resultKeys = Object.keys(result)
                const validHeadcounts = resultKeys.includes('valid') ? result['valid'] : []
                const bannerItems: FlashbarProps.MessageDefinition[] = [
                    generateBannerContent(
                        'loading_complete',
                        ALERT_TYPES.SUCCESS,
                        validHeadcounts.length
                            ? `${validHeadcounts.length} unsubmitted Sandbox headcounts have been loaded.`
                            : `There are no new headcounts to submit.`,
                        setSandboxBannerItems,
                    ),
                ]
                const invalidHeadcounts = resultKeys.includes('invalid') ? result['invalid'] : []
                const headcounts = {}
                validHeadcounts.forEach((estimate: any) => {
                    if (!Object.keys(headcounts).includes(estimate.program_id)) {
                        headcounts[estimate.program_id] = []
                    }
                    estimate.removed = false
                    headcounts[estimate.program_id].push(estimate)
                })
                if (validHeadcounts.length) {
                    lockSubmittingGroups(validHeadcounts)
                }
                setSandboxHeadcounts(headcounts)
                if (invalidHeadcounts.length) {
                    // inform users of how many invalid headcounts have been removed
                    bannerItems.push(
                        generateBannerContent(
                            'success_message_1',
                            ALERT_TYPES.INFO,
                            `${invalidHeadcounts.length} Sandbox headcounts have been removed from Sandbox due to invalid program/deliverable mapping.`,
                            setSandboxBannerItems,
                        ),
                    )
                    getSandboxHistoryHeadcount()
                }
                setSandboxBannerItems(bannerItems)
                setIsSandboxLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setSandboxHeadcounts({})
            })
    }

    const unlockSubmittingGroups = () => {
        const jsonMessage = {
            message: {
                type: WEB_SOCKET_TYPE.UPDATE_GROUP_SELECTION,
                payload: {
                    group_ids: lockedGroupIds.join(','),
                    group_status: GROUP_CELL_STATUS.UNLOCKED,
                    plan_id: selectedPlanRef.current.value['plan_id'],
                },
            },
        }
        if (webSocket) {
            webSocket.sendJsonMessage(jsonMessage)
        }
    }

    const lockSubmittingGroups = (estimates) => {
        const groupIds = new Set()
        estimates.forEach((estimate) => {
            groupIds.add(estimate.group_id)
        })

        const jsonMessage = {
            message: {
                type: WEB_SOCKET_TYPE.UPDATE_GROUP_SELECTION,
                payload: {
                    group_ids: Array.from(groupIds).join(','),
                    group_status: GROUP_CELL_STATUS.LOCKED,
                    plan_id: selectedPlanRef.current.value['plan_id'],
                },
            },
        }
        if (webSocket) {
            webSocket.sendJsonMessage(jsonMessage)
        }
    }

    const handleSubmit = () => {
        const cancelEdit = true
        gridApi.stopEditing(cancelEdit)
        setSubmitModalVisible(true)
        setSandboxBannerItems([
            generateBannerContent(
                'loading',
                ALERT_TYPES.SUCCESS,
                'Loading Sandbox headcounts ...',
                setSandboxBannerItems,
            ),
        ])
        getSandboxHeadcount()
        modalTimeOut()
        setTimeOutCount(TIME_OUT_VALUE_MS / 1000 - 1)
    }

    const handleClose = () => {
        setSubmitModalVisible(false)
        setTimeOutCount(TIME_OUT_VALUE_MS / 1000)
        if (lockedGroupIds.length) {
            unlockSubmittingGroups()
        }
    }
    const modalTimeOut = () => {
        // sandbox submission modal will time out after a minute to avoid infinite group locks
        const id = setTimeout(() => setSubmitModalVisible(false), TIME_OUT_VALUE_MS)
        setTimeOutId(id)
    }

    useEffect(() => {
        if (submitModalVisible) {
            const timer: any =
                timeOutCount > 0 && setInterval(() => setTimeOutCount(timeOutCount - 1), 1000)
            return () => clearInterval(timer)
        } else {
            if (lockedGroupIds.length) {
                unlockSubmittingGroups()
            }
        }
    }, [timeOutCount, submitModalVisible])

    const getRowStyle: any = (params: any) => {
        let backgroundColor = NORMAL_CELL_COLOR_CODE
        if (params.node.footer) {
            backgroundColor = GRAND_TOTAL_COLOR_CODE
        } else if (params.node.group) {
            backgroundColor = NON_EDITABLE_COLOR
        }
        return {
            fontWeight: params.node.rowPinned || params.node.footer ? 'bold' : 'normal',
            background: backgroundColor,
        }
    }

    const handleClickExport = async () => {
        const excelBinary = gridApi?.getDataAsExcel()
        const arrayBuffer = await excelBinary.arrayBuffer()
        // Convert the Excel data string to a workbook
        const workbook = xlsx.read(new Uint8Array(arrayBuffer), { type: 'array' })

        const groupData = Array.from(orgGroups).map((orgGroup: any) => {
            return {
                'Group ID': orgGroup.group_id,
                'Group Name': orgGroup.group_name,
            }
        })
        const groupDataSheet = xlsx.utils.json_to_sheet(groupData)
        xlsx.utils.book_append_sheet(workbook, groupDataSheet, 'DO-NOT-DELETE-Group-Data')
        xlsx.utils.book_set_sheet_visibility(
            workbook,
            'DO-NOT-DELETE-Group-Data',
            xlsx.utils.consts.SHEET_HIDDEN,
        )

        const deliverableData: any = []
        Object.entries(deliverables).forEach((program: any) => {
            const programName = program[0]
            program[1].forEach((deliverable) => {
                deliverableData.push({
                    'Program Name': programName,
                    'Program ID': deliverable.program_id,
                    'Deliverable Name': deliverable.deliverable_name,
                    'Deliverable ID': deliverable.deliverable_id,
                })
            })
        })
        const deliverableDataSheet = xlsx.utils.json_to_sheet(deliverableData)
        xlsx.utils.book_append_sheet(
            workbook,
            deliverableDataSheet,
            'DO-NOT-DELETE-Deliverable-Data',
        )
        xlsx.utils.book_set_sheet_visibility(
            workbook,
            'DO-NOT-DELETE-Deliverable-Data',
            xlsx.utils.consts.SHEET_HIDDEN,
        )

        // Convert the workbook to XLSX format
        const xlsxData = xlsx.write(workbook, { bookType: 'xlsx', type: 'buffer' })

        // Create a Blob from the XLSX data
        const blob = new Blob([xlsxData], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        // Create a temporary URL for the Blob
        const url = URL.createObjectURL(blob)

        // Create a temporary link element
        const link = document.createElement('a')
        link.href = url
        link.download = 'output.xlsx'

        // Append the link to the document and click it
        document.body.appendChild(link)
        link.click()

        // Clean up the temporary URL and link
        URL.revokeObjectURL(url)
        document.body.removeChild(link)
    }

    return (
        <AppLayout
            disableContentPaddings={true}
            contentType={'table'}
            navigationHide={true}
            toolsHide={true}
            splitPanelOpen={historyPanelVisible}
            splitPanelPreferences={{ position: 'side' }}
            splitPanel={
                <SandboxHistoryPanel
                    setVisible={setHistoryPanelVisible}
                    showFTEEstimation={showFTEEstimation}
                    getSandboxHeadcounts={getSandboxHistoryHeadcount}
                    totalSandboxHeadcounts={totalSandboxHeadcounts}
                    setTotalSandboxHeadcounts={setTotalSandboxHeadcounts}
                    sandboxFilteredHeadcounts={sandboxFilteredHeadcounts}
                    setSandboxFilteredHeadcounts={setSandboxFilteredHeadcounts}
                    newSandboxHeadcounts={newSandboxHeadcounts}
                    setNewSandboxHeadcounts={setNewSandboxHeadcounts}
                    historyPanelFilters={historyPanelFilters}
                    setHistoryPanelFilters={setHistoryPanelFilters}
                    activeUsers={activeUsers}
                    selectedOrg={selectedOrg}
                    selectedPlan={selectedPlan?.value}
                    orgGroups={orgGroups}
                    programDeliverables={deliverables}
                />
            }
            content={
                <ContentLayout
                    defaultPadding={true}
                    header={
                        <Box margin={{ top: 's', left: 's', right: 's' }}>
                            <SpaceBetween size='s'>
                                <HeaderTemplate
                                    items={[
                                        { text: 'Home', href: '/' },
                                        { text: 'Programs Headcount', href: '' },
                                    ]}
                                />
                                <Flashbar items={bannerItems} />
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <Container
                        header={
                            <Header
                                actions={
                                    <Box float={'right'}>
                                        <SpaceBetween size={'s'} direction={'horizontal'}>
                                            {(Object.keys(activeUsers).length && (
                                                <Box padding={{ top: 'm' }}>
                                                    <TextContent>
                                                        <strong>Active Users</strong>
                                                    </TextContent>
                                                </Box>
                                            )) || <></>}
                                            <SpaceBetween size={'xxs'} direction={'horizontal'}>
                                                <AvatarGroup>
                                                    {Object.keys(activeUsers).map((user) => {
                                                        return (
                                                            <Tooltip
                                                                title={
                                                                    <Box
                                                                        variant='awsui-key-label'
                                                                        color='inherit'
                                                                    >
                                                                        {user}
                                                                    </Box>
                                                                }
                                                                placement='bottom'
                                                            >
                                                                <Avatar
                                                                    sx={{
                                                                        bgcolor: activeUsers[user],
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )
                                                    })}
                                                </AvatarGroup>
                                            </SpaceBetween>
                                            <Tooltip
                                                title={
                                                    <Box variant='awsui-key-label' color='inherit'>
                                                        History
                                                    </Box>
                                                }
                                                placement='bottom'
                                            >
                                                <IconButton
                                                    aria-label={'history'}
                                                    size={'large'}
                                                    color='primary'
                                                    onClick={() => {
                                                        getSandboxHistoryHeadcount()
                                                        setHistoryPanelVisible((prev) => !prev)
                                                        setSandboxFilteredHeadcounts([])
                                                        setNewSandboxHeadcounts([])
                                                        setHistoryPanelFilters([])
                                                    }}
                                                >
                                                    <HistoryRoundedIcon fontSize='large' />
                                                </IconButton>
                                            </Tooltip>
                                            <Box padding={{ top: 's' }}>
                                                <Button onClick={() => handleClickExport()}>
                                                    Export
                                                </Button>
                                            </Box>
                                        </SpaceBetween>
                                    </Box>
                                }
                            >
                                <SpaceBetween direction={'horizontal'} size={'s'}>
                                    <Select
                                        filteringType={'auto'}
                                        placeholder={'Selected Plan'}
                                        selectedOption={selectedPlan}
                                        onChange={({ detail }) => {
                                            const option: any = detail.selectedOption
                                            const revisions: any = option.value?.revisions
                                            if (revisions.length) {
                                                setLatestRevision(revisions[0])
                                            } else {
                                                setLatestRevision({})
                                            }
                                            setSelectedPlan(option)
                                        }}
                                        options={plans}
                                    />
                                    <Select
                                        selectedOption={
                                            formatRevisionOptions(latestRevision) as any
                                        }
                                        disabled
                                    />
                                    <Select
                                        filteringType={'auto'}
                                        placeholder={'Selected Org'}
                                        selectedOption={formatSelection({
                                            label: selectedOrg?.object_name,
                                            value: selectedOrg?.object_id,
                                        })}
                                        options={orgOptions}
                                        onChange={({ detail }) => {
                                            setSelectedOrg({
                                                object_id: detail.selectedOption.value,
                                                object_name: detail.selectedOption.label,
                                            })
                                        }}
                                        disabled={!orgOptions.length}
                                    />
                                    {webSocket.readyState === WebSocket.OPEN ? (
                                        <Box padding={{ top: 's' }}>
                                            <StatusIndicator type={'success'}>
                                                Sandbox Connected
                                            </StatusIndicator>
                                        </Box>
                                    ) : (
                                        <Box padding={{ top: 's' }}>
                                            <StatusIndicator type={'error'}>
                                                Sandbox Not Connected
                                            </StatusIndicator>
                                        </Box>
                                    )}
                                    {generateToggleComponent(
                                        'All',
                                        'Scoped',
                                        showScopedOnlyPrograms,
                                        setShowScopedOnlyPrograms,
                                    )}
                                    {generateToggleComponent(
                                        'Effort Estimation (FTE-Months)',
                                        'FTE Estimation',
                                        showFTEEstimation,
                                        setShowFTEEstimation,
                                    )}
                                </SpaceBetween>
                            </Header>
                        }
                    >
                        <SpaceBetween size={'xxs'} direction={'vertical'}>
                            <Box margin={{ top: 's' }}>
                                <div
                                    className='ag-theme-quartz' // applying the grid theme
                                    style={{ height: 700 }} // the gsrid will fill the size of the parent container
                                >
                                    <AgGridReact
                                        columnDefs={colDefs}
                                        rowData={visibleRowData}
                                        onGridReady={onGridReady}
                                        onCellEditRequest={onCellEditRequest}
                                        gridOptions={{
                                            groupTotalRow: 'bottom',
                                            grandTotalRow: 'bottom',
                                            autoSizeStrategy: {
                                                type: 'fitCellContents',
                                            },
                                            defaultColDef: {
                                                filter: true,
                                                floatingFilter: true,
                                                lockPosition: true,
                                            },
                                            tooltipShowDelay: 500,
                                            autoGroupColumnDef: {
                                                headerName: 'Program',
                                                field: 'program_name',
                                                minWidth: 100,
                                                maxWidth: 400,
                                                pinned: 'left',
                                                cellStyle: (params) => generateCellStyle(params),
                                                tooltipValueGetter: (params) =>
                                                    params.data ? params.data['program_name'] : '',
                                            },
                                            readOnlyEdit: true,
                                            getRowId: (params: any) => String(params.data.id),
                                            suppressAggFuncInHeader: true,
                                            onCellClicked: (event: any) =>
                                                onSelectionChanged(event),
                                            onCellEditingStopped: (event: any) =>
                                                onFocusLost(event),
                                            singleClickEdit: true,
                                            columnMenu: 'new',
                                            getRowStyle: (params: any) => getRowStyle(params),
                                        }}
                                    />
                                </div>
                            </Box>
                            <SandboxSubmitWizard
                                userGroups={userGroupsRef.current}
                                gridApi={gridApi}
                                visible={submitModalVisible}
                                userAlias={userAlias}
                                showFTEEstimation={showFTEEstimation}
                                selectedOrg={selectedOrg}
                                latestRevision={latestRevision}
                                selectedPlan={selectedPlan}
                                webSocket={webSocket}
                                timeOutCount={timeOutCount}
                                handleClose={handleClose}
                                timeOutId={timeOutId}
                                isSandboxLoading={isSandboxLoading}
                                sandboxHeadcounts={sandboxHeadcounts}
                                setSandboxHeadcounts={setSandboxHeadcounts}
                                setTotalSandboxHeadcounts={setTotalSandboxHeadcounts}
                                setSandboxFilteredHeadcounts={setSandboxFilteredHeadcounts}
                                onBannerItemsChange={setBannerItems}
                                sandboxBannerItems={sandboxBannerItems}
                                setSubmissionDisable={setSubmissionDisable}
                                updateSandboxBannerItems={setSandboxBannerItems}
                            />
                            <Box float={'right'} padding={{ top: 's' }}>
                                <Button
                                    variant='primary'
                                    disabled={
                                        submissionLock ||
                                        submissionDisable ||
                                        isRevisionClosed(latestRevision)
                                    }
                                    disabledReason={
                                        submissionRequester === userAlias && submissionLock
                                            ? ''
                                            : generateSubmitDisableReason(
                                                  submissionLock,
                                                  isRevisionClosed(latestRevision),
                                              )
                                    }
                                    onClick={() => handleSubmit()}
                                >
                                    {'Submit'}
                                </Button>
                            </Box>
                        </SpaceBetween>
                    </Container>
                </ContentLayout>
            }
        ></AppLayout>
    )
}

export { Sandbox }
