import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { SELECT_BUSINESS_ENTITY } from './Constant'

let useStore: any = (set) => ({
    businessEntities: [],
    selectedBusinessEntity: {
        business_entity_id: '',
        business_entity_name: SELECT_BUSINESS_ENTITY,
    },
    allPlans: [],
    userRoles: [],
    canAdmin: false,
    canEditPrograms: false,
    canScopeProgramsDeliverables: false,
    businessEntityMap: {},
    userGroups: [],
    userOrgs: [],
    userPrograms: [],
    selectedOrgs: [],
    selectedGroups: [],
    selectedColumns: [],
    selectedRows: [],
    setUserRoles: (value) =>
        set((state) => ({
            ...state,
            userRoles: value,
        })),
    setCanAdmin: (value) =>
        set((state) => ({
            ...state,
            canAdmin: value,
        })),
    setCanEditPrograms: (value) =>
        set((state) => ({
            ...state,
            canEditPrograms: value,
        })),
    setCanScopeProgramsDeliverables: (value) =>
        set((state) => ({
            ...state,
            canScopeProgramsDeliverables: value,
        })),
    setUserGroups: (value) =>
        set((state) => ({
            ...state,
            userGroups: value,
        })),
    setUserOrgs: (value) =>
        set((state) => ({
            ...state,
            userOrgs: value,
        })),
    setUserPrograms: (value) =>
        set((state) => ({
            ...state,
            userPrograms: value,
        })),
    setBusinessEntities: (value) =>
        set((state) => ({
            ...state,
            businessEntities: value,
        })),
    setBusinessEntityMap: (value) =>
        set((state) => ({
            ...state,
            businessEntityMap: value,
        })),
    setSelectedBusinessEntity: (value) =>
        set((state) => ({
            ...state,
            selectedBusinessEntity: value,
        })),

    setAllPlans: (value) =>
        set((state) => ({
            ...state,
            allPlans: value,
        })),

    setSelectedOrgs: (value) =>
        set((state) => ({
            ...state,
            selectedOrgs: value,
        })),

    setSelectedGroups: (value) =>
        set((state) => ({
            ...state,
            selectedGroups: value,
        })),

    setSelectedRows: (value) =>
        set((state) => ({
            ...state,
            selectedRows: value,
        })),

    setSelectedColumns: (value) =>
        set((state) => ({
            ...state,
            selectedColumns: value,
        })),
})

// TODO: remove it before production release
useStore = devtools(useStore)

export default useStore = create(useStore)
