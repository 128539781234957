import { useEffect, useState } from 'react'
import {
    Modal,
    SpaceBetween,
    Box,
    Button,
    Header,
    Flashbar,
    Spinner,
} from '@amzn/awsui-components-react'
import GenericSummaryTable from '../reusable/GenericSummaryTable'
import {
    getSandboxSummaryColumnDefinitions,
    getSandboxSummaryVisibleColumns,
} from './SandboxSummaryAttributes'
import { useAppContext } from '../../../context'
import { ALERT_TYPES, WEB_SOCKET_TYPE } from '../../Constant'
import { generateToggleComponent } from '../reusable/Utils'
import { generateBannerContent } from './SandboxUtils'

const SandboxSubmitWizard = (props) => {
    const {
        userGroups,
        gridApi,
        visible,
        userAlias,
        showFTEEstimation,
        selectedPlan,
        latestRevision,
        selectedOrg,
        webSocket,
        timeOutCount,
        handleClose,
        timeOutId,
        isSandboxLoading,
        sandboxHeadcounts,
        setSandboxHeadcounts,
        setTotalSandboxHeadcounts,
        setSandboxFilteredHeadcounts,
        onBannerItemsChange,
        setSubmissionDisable,
        sandboxBannerItems,
        updateSandboxBannerItems,
    } = props
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [submissionToggleChecked, setSubmissionToggleChecked] = useState<boolean>(true)

    useEffect(() => {
        generateSummaryTables(showFTEEstimation)
    }, [sandboxHeadcounts])

    const removeRow = (item) => {
        const programId = item.program_id
        const headcountId = item.headcount_id
        const planId = item.plan_sort.split('#')[0]
        const modifiedHeadcounts = {}
        Object.keys(sandboxHeadcounts).forEach((program) => {
            if (programId === program) {
                modifiedHeadcounts[program] = sandboxHeadcounts[program].filter(
                    (estimate) => estimate.headcount_id !== headcountId,
                )
            } else {
                modifiedHeadcounts[program] = sandboxHeadcounts[program]
            }
        })
        // revert headcount value to previous submitted value when row is deleted
        const jsonMessage = {
            message: {
                type: WEB_SOCKET_TYPE.REVERT_HEADCOUNT_ESTIMATE,
                user_groups: userGroups,
                payload: {
                    headcount_id: headcountId,
                    plan_id: planId,
                    org_id: item.org_id,
                },
            },
        }
        webSocket.sendJsonMessage(jsonMessage)
        setSandboxHeadcounts(modifiedHeadcounts)
    }

    const generateSummaryTables = (showFTEEstimation) => {
        return Object.keys(sandboxHeadcounts).map((program) => {
            let estimates = sandboxHeadcounts[program]
            if (!estimates) return
            if (!submissionToggleChecked) {
                estimates = estimates.filter((estimate) => estimate.updated_by === userAlias)
            }
            return (
                estimates.length > 0 && (
                    <Box padding={{ vertical: 's' }}>
                        <GenericSummaryTable
                            columns={getSandboxSummaryColumnDefinitions(showFTEEstimation)}
                            visibleColumns={getSandboxSummaryVisibleColumns(showFTEEstimation)}
                            itemsToShow={estimates}
                            isLoading={!estimates}
                            nameField={'program_name'}
                            defaultNameField={'Program'}
                            objectType={'Program'}
                            actions={[]}
                            wrapLines
                            includePagination={false}
                            customHeader={<Header> {estimates[0].program_name} </Header>}
                            onRowClick={(details: any) => {
                                const item = details.detail.item
                                if (item.removed) {
                                    removeRow(item)
                                }
                            }}
                        />
                    </Box>
                )
            )
        })
    }

    const submitSandboxHeadcounts = () => {
        const headcountIds: any[] = []
        const groupList = new Set()
        Object.keys(sandboxHeadcounts).forEach((program) => {
            sandboxHeadcounts[program].forEach((estimate) => {
                const groupId = estimate.group_id
                if (!submissionToggleChecked) {
                    if (estimate.updated_by === userAlias) {
                        headcountIds.push(estimate.headcount_id)
                        groupList.add(groupId)
                    }
                } else {
                    headcountIds.push(estimate.headcount_id)
                    groupList.add(groupId)
                }
            })
        })
        gridApi.showLoadingOverlay()
        setSubmissionDisable(true)
        apiClient
            .post(
                `/sandbox-headcount/plan/${selectedPlan.value?.plan_id}/org/${selectedOrg.object_id}`,
                JSON.stringify({ headcount_ids: headcountIds }),
            )
            .then(() => {
                onBannerItemsChange([
                    generateBannerContent(
                        'success_message_1',
                        ALERT_TYPES.SUCCESS,
                        `Successfully submitted ${headcountIds.length} HC estimate change${headcountIds.length > 1 ? '(s)' : ''} to ${selectedPlan.value.plan_type} ${selectedPlan.value.year} Revision ${latestRevision.revision_number} - ${latestRevision.revision_title}.`,
                        onBannerItemsChange,
                    ),
                ])
                setTotalSandboxHeadcounts([])
                setSandboxFilteredHeadcounts([])
            })
            .catch((err) => {
                console.error(err)
                onBannerItemsChange([
                    generateBannerContent(
                        'error_message_1',
                        ALERT_TYPES.ERROR,
                        `Failed to submitting HC estimate change${headcountIds.length > 1 ? '(s)' : ''} to ${selectedPlan.value.plan_type} ${selectedPlan.value.year} Revision ${latestRevision.revision_number} - ${latestRevision.revision_title}: ${err}`,
                        onBannerItemsChange,
                    ),
                ])
                setSubmissionDisable(false)
            })
            .finally(() => {
                gridApi.hideOverlay()
            })
    }

    const resetData = () => {
        setSandboxHeadcounts({})
        updateSandboxBannerItems([])
        setSubmissionToggleChecked(true)
        clearTimeout(timeOutId)
    }

    return (
        <Modal
            onDismiss={() => {
                handleClose()
                resetData()
            }}
            visible={visible}
            closeAriaLabel='Close modal'
            size='large'
            header={
                <Header variant={'h1'} description={`Session will expire in: ${timeOutCount} s`}>
                    Summary of Changes
                </Header>
            }
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button
                            variant='link'
                            onClick={() => {
                                handleClose()
                                resetData()
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={() => {
                                submitSandboxHeadcounts()
                                handleClose()
                                resetData()
                            }}
                            disabled={!Object.keys(sandboxHeadcounts).length}
                        >
                            Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Box margin={{ top: 's' }}>
                <SpaceBetween size={'s'} direction={'vertical'}>
                    {generateToggleComponent(
                        'Submit My Changes',
                        'Submit All Changes',
                        submissionToggleChecked,
                        setSubmissionToggleChecked,
                    )}
                    <Flashbar items={sandboxBannerItems} />
                    <span>{generateSummaryTables(showFTEEstimation)}</span>
                    {isSandboxLoading && <Spinner />}
                </SpaceBetween>
            </Box>
        </Modal>
    )
}

export default SandboxSubmitWizard
