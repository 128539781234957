import {
    Box,
    Grid,
    Icon,
    SpaceBetween,
    TextContent,
    TokenGroup,
} from '@amzn/awsui-components-react'
import StatusIndicatorTemplate from '../reusable/StatusIndicatorTemplate'
import { findTextDiff } from '../reusable/Utils'
import React, { Dispatch } from 'react'
import {
    ACTION_TYPES,
    AUDIT_KEY_REGEX,
    AUDIT_LOG_GROUPS,
    AUDIT_SERVER_SIDE_FILTERS,
    getColumnsForState,
    IS_REGISTERED_USERS,
    Q3_OTHER_PROGRAMS,
    SET_FILTER_LIMIT,
} from './Constant'
import { OBJECT_TYPE } from '../../Constant'
import { capitalizeFirstLetter, convertToLocalTime, formatFieldName } from '../../common/Util'
import { AUDIT_STATE_ACTIONS, AuditState } from './useAuditState'
import { IS_TEXT_AREA_REGEX } from '../reusable/Regex'
import _ from 'lodash'
import { DifferencesObject } from './audit_tables/cell_renderers/CellRenderers'
import { GridApi } from 'ag-grid-community'
import { formatDataForState } from './audit_tables/AuditFormatters'

const formatArrayValueWithLabel = (
    isRegisteredUsers: boolean,
    isQ3OtherPrograms: boolean,
    array,
) => {
    if (_.isEmpty(array)) {
        return '-'
    }

    let fieldKeys: any[] = Object.keys(array[0])
    if (isRegisteredUsers) {
        return array.join(', ')
    }

    if (isQ3OtherPrograms) {
        fieldKeys = fieldKeys.filter((key) => key !== 'value')
    }
    const keyLength = fieldKeys.length

    return (
        <TokenGroup
            items={array.map((item) => ({
                label: item[fieldKeys[0]],
                description: keyLength >= 1 ? item[fieldKeys[1]] : '',
                labelTag: keyLength >= 2 ? item[fieldKeys[2]] : '',
                disabled: true,
            }))}
            alignment='vertical'
        />
    )
}

export interface RenderValueProps {
    usePrevious?: boolean
    omitHeader?: boolean
    isRegisteredUsers: boolean
    isQ3OtherPrograms: boolean
    isTextArea: boolean
    label: string
    prev: any
    next: any // todo - define possible value types
}
export const valueWithLabel = (props: RenderValueProps) => {
    const { usePrevious, isRegisteredUsers, isQ3OtherPrograms, isTextArea, label, prev, next } =
        props
    const value = usePrevious ? prev : next
    return (
        <SpaceBetween size={'s'} direction={'vertical'}>
            <Box variant='awsui-key-label'>{label}</Box>
            {typeof value === 'boolean' ? (
                <StatusIndicatorTemplate value={value} />
            ) : Array.isArray(value) ? (
                formatArrayValueWithLabel(isRegisteredUsers, isQ3OtherPrograms, value)
            ) : !usePrevious && isTextArea ? (
                <SpaceBetween direction='horizontal' size='xxxs'>
                    {findTextDiff(prev, next)}
                </SpaceBetween>
            ) : (
                value || '-'
            )}
        </SpaceBetween>
    )
}

export const valueWithLabelDifferences = (props: RenderValueProps) => {
    const { isRegisteredUsers, isQ3OtherPrograms, isTextArea, label, prev, next, omitHeader } =
        props
    let prevContent = prev || '-'
    let nextContent
    if (typeof next === 'boolean' || typeof prev === 'boolean') {
        prevContent = <StatusIndicatorTemplate value={prev} />
        nextContent = <StatusIndicatorTemplate value={next} />
    } else if (Array.isArray(next) || Array.isArray(prev)) {
        prevContent = formatArrayValueWithLabel(isRegisteredUsers, isQ3OtherPrograms, prev)
        nextContent = formatArrayValueWithLabel(isRegisteredUsers, isQ3OtherPrograms, next)
    } else {
        nextContent = isTextArea ? (
            <SpaceBetween direction='horizontal' size='xxxs'>
                {findTextDiff(prev || '-', next)}
            </SpaceBetween>
        ) : (
            <TextContent>
                <b>{next || '-'}</b>
            </TextContent>
        )
    }
    return (
        <SpaceBetween size={'s'} direction={'vertical'}>
            <Box variant='awsui-key-label'>{label}</Box>

            <Grid gridDefinition={[{ colspan: 5 }, { colspan: 1 }, { colspan: 5 }]}>
                {prevContent}
                <span className={'center-justify'}>
                    <Icon variant={'normal'} name={'caret-right-filled'} />
                </span>
                {nextContent}
            </Grid>
        </SpaceBetween>
    )
}

export const generateUpdateText = (differencesKeys: string[], itemName: string, action: string) => {
    const prefix = `${differencesKeys?.length || 0} item${differencesKeys?.length === 1 ? '' : 's'}`
    return `${prefix} ${action?.toLowerCase() || 'change'}d for ${itemName}`
}

export const generateHeadcountUpdateText = (
    differencesKeys: string[],
    itemName: string,
    action: string,
) => {
    if (differencesKeys.length > 1) {
        // this shouldn't happen, but it's a bug if it does
        return generateUpdateText(differencesKeys, itemName, action)
    }
    return `1 ${itemName} ${action?.toLowerCase() || 'change'}d`
}
export const getItemName = (rawEntry) => {
    const state =
        rawEntry.action !== ACTION_TYPES.CREATE ? rawEntry.prev_state : rawEntry.next_state
    let name = 'undefined'

    switch (rawEntry.object_type) {
        case OBJECT_TYPE.HC_ESTIMATE:
            name = state.headcount_type?.toUpperCase()
            break
        case OBJECT_TYPE.PROGRAM:
            name = state.program_name
            break
        case OBJECT_TYPE.PARENT_PROGRAM:
            name = state.program_name
            break
        case OBJECT_TYPE.DELIVERABLE:
            name = state.deliverable_name
            break
        case OBJECT_TYPE.PLAN:
            name = `${state?.plan_type} ${state?.year}`
            break
        case OBJECT_TYPE.REVISION:
            name = `${state?.revision_number} - ${state.revision_title}`
            break
        case OBJECT_TYPE.DISCRETIONARY_SPEND:
            name = state.ds_item_id_display
            break
        default:
            console.error(`Undefined object type: ${rawEntry}`)
            break
    }
    return name
}

export const formatTimestampFromSortKey = (rawEntry: any) => {
    return convertToLocalTime(new Date(Number(rawEntry.timestamp_sort.split('#')[2])).toISOString())
}

export const onDetailsClick = (rawEntry: any, dispatch: any) => {
    const objectName = `${capitalizeFirstLetter(rawEntry.object_type)} ${getItemName(rawEntry)}`
    const newClickedItem = {
        ...rawEntry,
        object_name: objectName,
    }

    dispatch({
        type: AUDIT_STATE_ACTIONS.SET_SINGLE,
        payload: {
            isDetailsModalVisible: true,
            clickedItem: newClickedItem,
        },
    })
}

const findIdsFromMap = (map, values: string[], attrKey: string) => {
    const mapList = [...map]
    return values
        .map((value: string) => mapList.find(([_, object]) => object[attrKey] === value)?.[0])
        .filter((id) => id !== undefined)
}

export const getQueryKeyValues = (
    id: string,
    values: string[],
    businessEntityId: string,
    programs,
    orgs,
    groups,
) => {
    let queryKeyValues: string[]
    switch (id) {
        case AUDIT_SERVER_SIDE_FILTERS.program_name:
            queryKeyValues = findIdsFromMap(programs, values, 'program_name')
            break
        case AUDIT_SERVER_SIDE_FILTERS.org_name:
            queryKeyValues = findIdsFromMap(orgs, values, 'org_name')
            break
        case AUDIT_SERVER_SIDE_FILTERS.group_name:
            queryKeyValues = findIdsFromMap(groups, values, 'group_name')
            break
        default:
            queryKeyValues = [businessEntityId]
            break
    }
    return queryKeyValues.join(',')
}

export const getUniqueOptions = (map, OptionName: string) => {
    return [...new Set(Array.from(map.values()).map((item: any) => item[OptionName]))]
}

export const getKeyProps = (key: string) => {
    const isRegisteredUsers = key === IS_REGISTERED_USERS
    const isQ3OtherPrograms = key === Q3_OTHER_PROGRAMS
    const isTextArea = IS_TEXT_AREA_REGEX.test(key)
    let displayKey = ''
    if (AUDIT_KEY_REGEX.test(key)) {
        return null
    } else {
        displayKey = formatFieldName(key)
    }
    return {
        label: displayKey,
        isTextArea: isTextArea,
        isQ3OtherPrograms: isQ3OtherPrograms,
        isRegisteredUsers: isRegisteredUsers,
    }
}
// note - this is only relevant for diff of create or delete actions
const FIELDS_TO_IGNORE = [
    'created_at',
    'created_by',
    'updated_at',
    'updated_by',
    'lock_updated_at',
    'status_change_date',
    'prev_is_active',
    'previous_fte_month_value',
    'previous_value',
    'is_fte_months',
    'is_fte',
    'is_locked',
]
const ignoreField = (keyName: string, customFieldsToIgnore: string[] = []) => {
    return (
        FIELDS_TO_IGNORE.includes(keyName) ||
        AUDIT_KEY_REGEX.test(keyName) ||
        (customFieldsToIgnore.length && customFieldsToIgnore.includes(keyName))
    )
}
const isEmptyDiffChange = (stateObject) => {
    const prev = stateObject['prev'] || ''
    const next = stateObject['next'] || ''
    return (isEmptyStateAttribute(prev) && isEmptyStateAttribute(next)) || prev === next
}

const isEmptyNumber = (num) => {
    return num === 0
}
const isEmptyStateAttribute = (attrVal) => {
    const normalizedVal = attrVal || '0'
    const valAsNum = Number(normalizedVal)
    return !isNaN(valAsNum) ? isEmptyNumber(valAsNum) : _.isEmpty(attrVal)
}
export const filterObjectState = (
    objectState: { [p: string]: any } | DifferencesObject,
    customFieldsToIgnore: string[] = [],
    isDifferences: boolean = false,
): { [p: string]: any } | DifferencesObject => {
    // this is a safeguard made especially for HC estimate diffs
    // second pass filtering of system-facing or unused attributes
    // may be useful for other objects where we're deprecating attributes
    if (_.isEmpty(objectState)) {
        return {}
    }
    const isEmpty = isDifferences ? isEmptyDiffChange : isEmptyStateAttribute
    const filteredEntries: [string, any][] | [string, DifferencesObject][] = Object.entries(
        objectState,
    ).filter(
        (objectEntry) =>
            !ignoreField(objectEntry[0], customFieldsToIgnore) && !isEmpty(objectEntry[1]),
    )
    if (!filteredEntries.length) {
        return {}
    }
    return Object.fromEntries(filteredEntries)
}
export const formatObjectStateAsDifferences = (
    isCreate: boolean,
    objectState: any,
    defaultVal: string = '-',
) => {
    // we would like to display created, delete states inline
    // if there's only one field changed
    // primary use case is HC estimate, but we may have other ones
    if (_.isEmpty(objectState)) {
        return null
    }
    let prevNextList: [string, DifferencesObject][] = []
    Object.keys(objectState).forEach((keyName) => {
        prevNextList.push([
            keyName,
            {
                prev: isCreate ? defaultVal : objectState[keyName],
                next: isCreate ? objectState[keyName] : defaultVal,
            },
        ])
    })
    if (!prevNextList.length) {
        return null
    }
    return Object.fromEntries(prevNextList)
}
export const PROGRAM_LOG_GROUPS = [
    AUDIT_LOG_GROUPS.PROGRAM_DELIVERABLE,
    AUDIT_LOG_GROUPS.HEADCOUNT,
    AUDIT_LOG_GROUPS.SPEND,
]
export const DELIVERABLE_LOG_GROUPS = [AUDIT_LOG_GROUPS.HEADCOUNT]
export const manageLogGroupLoadingState = (state) => {
    if (isGridUnusable(state.gridApi)) {
        return
    }
    const logGroup = state.activeTabId

    const isLogGroupUsingProgramMap = PROGRAM_LOG_GROUPS.includes(logGroup)
    const selectedPlanId = state.selectPlanData?.selected?.value || ''
    const isSelectedPlanNotInProgramsMap = !(selectedPlanId in state.programsByPlan.data)
    const logGroupKey = buildLogGroupKeyFromState(state)
    const isLogGroupUsingDeliverableMap = DELIVERABLE_LOG_GROUPS.includes(logGroup)
    const isSelectedPlanNotInDeliverablesMap = !(
        (state.selectPlanData?.selected?.value || '') in state.deliverablesByPlan.data
    )
    if (
        state.plansMap.loading ||
        state.orgsMap.loading ||
        state.groupsMap.loading ||
        state.revisionsMap.loading ||
        state[logGroup].loading ||
        (state.programsByPlan.loading &&
            isLogGroupUsingProgramMap &&
            isSelectedPlanNotInProgramsMap) ||
        (state.deliverablesByPlan.loading &&
            isLogGroupUsingDeliverableMap &&
            isSelectedPlanNotInDeliverablesMap)
    ) {
        state.gridApi.showLoadingOverlay()
    } else if (!(state.auditTabDataStates.get(logGroupKey)?.rowData || []).length) {
        state.gridApi.showNoRowsOverlay()
    }
}

export const getSpendFieldsSorted = () => {
    const expenditureFields = Array(12)
        .fill(0)
        .map((_, monthIdx) => {
            const monthName = new Date(0, monthIdx)
                .toLocaleDateString(undefined, { month: 'short' })
                .toLowerCase()
            return `${monthName}_expenditure`
        })
    return [...expenditureFields, 'total_expenditure']
}

export const isSetFiltersExceedLimit = (
    api,
    colId: string,
    filterLength: number,
    logGroup,
    dispatch,
) => {
    if (filterLength > SET_FILTER_LIMIT) {
        const colName = api.getColumn(colId)
        dispatch({
            type: AUDIT_STATE_ACTIONS.SET_MANY,
            payload: {
                [logGroup]: {
                    flashBarItems: [
                        {
                            type: 'warning',
                            content: `Failed to apply set filter column ${colName && api.getDisplayNameForColumn(colName, 'header')} (${filterLength}) which exceed the maximum ${SET_FILTER_LIMIT} filters.`,
                            dismissible: true,
                            dismissLabel: 'Dismiss message',
                            onDismiss: () =>
                                dispatch({
                                    type: AUDIT_STATE_ACTIONS.SET_MANY,
                                    payload: {
                                        [logGroup]: {
                                            flashBarItems: [],
                                        },
                                    },
                                }),
                            id: 'set filter limit exceed',
                        },
                    ],
                },
            },
        })
        return true
    }
    return false
}
export interface ProcessedRow {
    ignoreRow: boolean
    differences: DifferencesObject | { [p: string]: any }
    prev_state: { [p: string]: any }
    next_state: { [p: string]: any }
}

export const ignoreChange = (rawAuditLog, fieldsToIgnore: string[] = []): ProcessedRow => {
    const differences: DifferencesObject | { [p: string]: any } = filterObjectState(
        rawAuditLog.differences,
        fieldsToIgnore,
        true,
    )
    const prev_state: { [p: string]: any } = filterObjectState(
        rawAuditLog.prev_state,
        fieldsToIgnore,
    )
    const next_state: { [p: string]: any } = filterObjectState(
        rawAuditLog.next_state,
        fieldsToIgnore,
    )

    const isUpdateNoDiff = rawAuditLog.action === ACTION_TYPES.UPDATE && _.isEmpty(differences)
    const refState = rawAuditLog.action === ACTION_TYPES.DELETE ? prev_state : next_state
    const isCreateDeleteNoChanges =
        rawAuditLog.action !== ACTION_TYPES.UPDATE && _.isEmpty(refState)
    return {
        ignoreRow: isUpdateNoDiff || isCreateDeleteNoChanges,
        differences: differences,
        prev_state: prev_state,
        next_state: next_state,
    }
}

export const getDefaultGridOptions: any = (selectedPaginationPageSize: number) => {
    return {
        defaultColDef: {
            floatingFilter: true,
        },
        autoSizeStrategy: {
            type: 'fitGridWidth',
        },
        pagination: true,
        suppressPaginationPanel: true,
        paginationPageSize: selectedPaginationPageSize,
        tooltipInteraction: true,
        tooltipShowDelay: 0,
        rowHeight: 60,
    }
}

export interface FetchAuditDataParams {
    apiClient: any
    state: AuditState
    dispatch: Dispatch<any>
    isConcatingData: boolean
    queryKey: string
    queryKeyValues: string
}

export const fetchAuditDataForTab = (params: FetchAuditDataParams) => {
    const { apiClient, state, dispatch, isConcatingData, queryKey, queryKeyValues } = params
    const { auditTabDataStates, startTimestamp, endTimestamp, activeTabId, selectPlanData } = state
    const selectedPlanId = selectPlanData?.selected?.value || ''
    const logGroupKey = `${activeTabId}@${selectedPlanId}`
    const logGroupPlanData = auditTabDataStates.get(logGroupKey)
    if (_.isEmpty(logGroupPlanData)) {
        console.error('Log group state not initialized.')
        return
    }
    const numberSignEncodeURI = encodeURIComponent('#')
    const startLogGroupTimeStamp = startTimestamp
        ? `${activeTabId}${numberSignEncodeURI}${selectedPlanId}${numberSignEncodeURI}${startTimestamp}`
        : startTimestamp
    const nextToken =
        queryKeyValues !== logGroupPlanData?.queryKeyValues || !isConcatingData
            ? ''
            : logGroupPlanData?.nextToken

    dispatch({
        type: AUDIT_STATE_ACTIONS.SET_MANY,
        payload: {
            [activeTabId]: {
                loading: true,
            },
        },
    })

    apiClient
        .get(
            `/audit-dashboard/query_key/${queryKey}/plan/${selectedPlanId}/bulk-get?query_key_values=${queryKeyValues}&end_timestamp=${activeTabId}${numberSignEncodeURI}${selectedPlanId}${numberSignEncodeURI}${endTimestamp}&start_timestamp=${startLogGroupTimeStamp}&next_token=${encodeURIComponent(nextToken)}`,
        )
        .then((res) => {
            const response = res.data
            const dataFormatted = formatDataForState({
                items: response.items,
                state: state,
                dispatch: dispatch,
            })
            const fullData = isConcatingData
                ? [...(logGroupPlanData?.rowData || [])].concat(dataFormatted)
                : dataFormatted
            state.gridApi.updateGridOptions({
                rowData: fullData,
                columnDefs: getColumnsForState({ state: state }),
            })
            dispatch({
                action: AUDIT_STATE_ACTIONS.SET_SINGLE,
                gridApi: state.gridApi,
            })
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
                payload: {
                    rowData: isConcatingData
                        ? [...(logGroupPlanData?.rowData || [])].concat(dataFormatted)
                        : dataFormatted,
                    nextToken: response.next_token,
                    lastQueryKeyValues: queryKeyValues,
                    startTimestampFetched: startTimestamp,
                    endTimestampFetched: endTimestamp,
                    currentPageIndex: !isConcatingData
                        ? 1
                        : (logGroupPlanData?.currentPageIndex ?? 1),
                },
                log_group_key: logGroupKey,
            })
        })
        .catch((err) => {
            console.error(err)
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
                payload: {
                    rowData: [],
                    nextToken: '',
                    lastQueryKeyValues: '',
                },
                log_group_key: logGroupKey,
            })
        })
        .finally(() => {
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_MANY,
                payload: {
                    [activeTabId]: {
                        loading: false,
                    },
                },
            })
        })
}

export const buildLogGroupKeyFromState = (state: AuditState) => {
    const selectedPlanId = state.selectPlanData?.selected?.value || ''
    return `${state.activeTabId}@${selectedPlanId}`
}

export const isGridUnusable = (gridApi: GridApi) => {
    return !gridApi || gridApi?.isDestroyed()
}
export const isDataLoading = (state, selectedBusinessEntity) => {
    return (
        areDataDependenciesLoading(state, selectedBusinessEntity) ||
        _.isEmpty(state.auditTabDataStates.get(buildLogGroupKeyFromState(state)))
    )
}

export const areDataDependenciesLoading = (state, selectedBusinessEntity) => {
    const selectedPlanId = state.selectPlanData?.selected?.value || ''
    return (
        !selectedBusinessEntity.business_entity_id ||
        !state.revisionsMap.data.size ||
        !state.orgsMap.data.size ||
        !state.groupsMap.data.size ||
        !state.plansMap.data.size ||
        _.isEmpty(state.deliverablesByPlan.data) ||
        !state.deliverablesByPlan.data[selectedPlanId] ||
        _.isEmpty(state.programsByPlan.data) ||
        !state.programsByPlan.data[selectedPlanId]
    )
}

export const onGoToPage = (gridApi: GridApi, pageNumber: number) => {
    if (isGridUnusable(gridApi)) {
        return
    }
    gridApi.paginationGoToPage(pageNumber ? pageNumber - 1 : pageNumber)
}

export const auditConcatenatedActionComparator = (a: string, b: string) => {
    const splitA = a.split(' ')
    const splitB = b.split(' ')
    if (splitA.length !== 2 || splitB.length !== 2) {
        console.error(`Cannot split concatenated action: ${a}, ${b}.`)
        return 0
    }

    const actionTypeA = splitA[0]
    const actionTypeB = splitB[0]
    const objectTypeA = splitA[1]
    const objectTypeB = splitB[1]
    return objectTypeA + actionTypeA > objectTypeB + actionTypeB ? 1 : -1
}

export const auditRevisionComparator = (a: string, b: string) => {
    const splitA = a.split(' ')
    const splitB = b.split(' ')
    if (splitA.length !== 2 || splitB.length !== 2) {
        console.error(`Cannot split revision: ${a}, ${b}.`)
        return 0
    }

    const revisionNumberA = parseInt(splitA[1])
    const revisionNumberB = parseInt(splitB[1])
    if (isNaN(revisionNumberA) || isNaN(revisionNumberB)) {
        console.error(`Invalid revision number: ${a}, ${b}.`)
        return 0
    }

    return revisionNumberA > revisionNumberB ? -1 : 1
}
