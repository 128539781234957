import React, { useEffect, useState } from 'react'
import {
    Box,
    Container,
    ContentLayout,
    Flashbar,
    Grid,
    Header,
    Link,
    SpaceBetween,
    Spinner,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import LinkButton from '../reusable/LinkButton'
import {
    HomePageButton,
    programButtons,
    programsSectionDescription,
    summariesAndAuditSectionDescription,
    summariesButtons,
    orgButtons,
    planButtons,
    orgSectionDescription,
    planSectionDescription,
    auditButtons,
    auditSectionDescription,
} from './HomePageConstants'
import { REPORT_BUG_LINK } from '../../common/LinkUtil'
import useStore from '../../Store'
import { NO_USER_BUSINESS_ENTITY } from '../../Constant'
import { HC_REPORTS } from './HomePageConstants'
import { useAppContext } from '../../../context'

interface HomePageProps {
    businessEntityBannerContent: any
    setBusinessEntityBannerContent: any
    isLoading: boolean
}

const HomePageSetUp = (props: HomePageProps) => {
    const userRoles = useStore((state) => state.userRoles)
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [businessEntityBannerItems, setBusinessEntityBannerItems] = useState<any[]>([])
    const [hcReportUrl, setHcReportUrl] = useState('#')
    const businessEntity = useStore((state) => state.selectedBusinessEntity)

    useEffect(() => {
        if (!props.businessEntityBannerContent.type) {
            setBusinessEntityBannerItems([])
            return
        }
        setBusinessEntityBannerItems([
            {
                header: props.businessEntityBannerContent.header,
                type: props.businessEntityBannerContent.type,
                content: props.businessEntityBannerContent.content,
                dismissible: true,
                dismissLabel: 'Dismiss',
                onDismiss: () => {
                    props.setBusinessEntityBannerContent({
                        header: '',
                        type: '',
                        content: '',
                    })
                },
            },
        ])
    }, [props.businessEntityBannerContent])

    useEffect(() => {
        apiClient
            .get('/falcon/tableau/reports')
            .then((res) => {
                const reports = res.data
                const setters = [setHcReportUrl]
                const buttonNames = [HC_REPORTS]
                buttonNames.forEach((buttonName, idx) => {
                    const url =
                        reports.find((report) => report.report_name === `Egret ${buttonName}`)
                            ?.url || '#'
                    setters[idx](url)
                })
            })
            .catch((error) => console.error(error))
    }, [])

    const getButtonUrl = (buttonMeta) => {
        switch (buttonMeta.name) {
            case HC_REPORTS:
                return hcReportUrl
            default:
                return buttonMeta.href
        }
    }

    const buildButtons = (buttonList) => {
        if (!buttonList) {
            return <></>
        }

        return buttonList.map((buttonMetadata) => (
            <SpaceBetween direction='horizontal' size='xs'>
                <LinkButton
                    dataCy={`home page ${buttonMetadata.name}`}
                    content={buttonMetadata.name}
                    path={getButtonUrl(buttonMetadata)}
                    external={buttonMetadata?.external}
                    disabled={buttonMetadata?.disabled}
                />
            </SpaceBetween>
        ))
    }
    const buttonGenerator = (buttons: HomePageButton[]) => {
        if (!buttons) {
            return <></>
        }
        return buildButtons(buttons)
    }

    return (
        <ContentLayout header={<HeaderTemplate />}>
            {props.isLoading || !businessEntity.business_entity_id ? (
                <Box margin={{ left: 'm', right: 'm' }}>
                    <Container>
                        <Spinner size={'large'} />
                    </Container>
                </Box>
            ) : (
                <Box margin={{ left: 'm', right: 'm' }}>
                    {!userRoles.length ? (
                        <Container>
                            <Container>
                                <Header variant={'h2'}>
                                    {businessEntity.business_entity_id === NO_USER_BUSINESS_ENTITY
                                        ? 'Missing Business Entity'
                                        : 'Insufficient Permissions'}
                                </Header>
                                {businessEntity.business_entity_id === NO_USER_BUSINESS_ENTITY ? (
                                    <>
                                        Please select a business entity from the top navigation bar.{' '}
                                    </>
                                ) : (
                                    <>
                                        {`You are not registered to any roles for ${
                                            businessEntity.business_entity_id
                                                ? businessEntity.business_entity_name
                                                : 'Egret'
                                        }. Please open a `}
                                        <Link href={REPORT_BUG_LINK}> ticket </Link>
                                        {`to request access.`}
                                    </>
                                )}
                            </Container>
                        </Container>
                    ) : (
                        <Box>
                            <Box padding={{ vertical: 's' }}>
                                <Flashbar items={businessEntityBannerItems} />
                            </Box>
                            <SpaceBetween direction='vertical' size='l'>
                                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                                    <Container
                                        header={
                                            <Header description={programsSectionDescription}>
                                                {'Programs'}
                                            </Header>
                                        }
                                    >
                                        <SpaceBetween direction='horizontal' size='s'>
                                            {buttonGenerator(programButtons)}
                                        </SpaceBetween>
                                    </Container>
                                    <Container
                                        header={
                                            <Header
                                                description={summariesAndAuditSectionDescription}
                                            >
                                                {'Summaries'}
                                            </Header>
                                        }
                                    >
                                        <SpaceBetween direction='horizontal' size='s'>
                                            {buttonGenerator(summariesButtons)}
                                        </SpaceBetween>
                                    </Container>
                                </Grid>
                                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                                    <Container
                                        header={
                                            <Header description={planSectionDescription}>
                                                {'Plans'}
                                            </Header>
                                        }
                                    >
                                        <SpaceBetween direction='horizontal' size='s'>
                                            {buttonGenerator(planButtons)}
                                        </SpaceBetween>
                                    </Container>
                                    <Container
                                        header={
                                            <Header description={auditSectionDescription}>
                                                {'Audits'}
                                            </Header>
                                        }
                                    >
                                        <SpaceBetween direction='horizontal' size='s'>
                                            {buttonGenerator(auditButtons)}
                                        </SpaceBetween>
                                    </Container>
                                </Grid>
                                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                                    <Container
                                        header={
                                            <Header description={orgSectionDescription}>
                                                {'Organizations'}
                                            </Header>
                                        }
                                    >
                                        <SpaceBetween direction='horizontal' size='s'>
                                            {buttonGenerator(orgButtons)}
                                        </SpaceBetween>
                                    </Container>
                                </Grid>
                            </SpaceBetween>
                        </Box>
                    )}
                </Box>
            )}
        </ContentLayout>
    )
}

export default HomePageSetUp
