import { programAttributes } from './ProgramAttributes'
import {
    BUSINESS_ENTITY_ABBREVIATIONS,
    COMPONENT_TYPES,
    EMPTY_REVISION_SELECTION,
    EMPTY_SELECTION,
} from '../../Constant'
import { deliverableAttributes } from './deliverables/DeliverableAttributes'

export const REDUCER_ACTIONS = {
    input: 'INPUT',
    initial: 'INITIAL',
    load: 'LOAD',
}

export const loadSelectedState = (state, selectedItem, isViewOnly, itemType) => {
    const result = { ...state }
    const targetAttrs: any[] = itemType === 'program' ? programAttributes : deliverableAttributes

    const findLabel = (id: string, value: string) => {
        const attrDef = targetAttrs.find((attr) => attr.id === id)

        if (attrDef && attrDef.selections) {
            const selection = attrDef.selections.find((selection) => selection.value === value)
            return selection ? selection.label : ''
        }
        return ''
    }
    const getTokens = (id: string, value: any) => {
        const attrDef = targetAttrs.find((attr) => attr.id === id)
        if (attrDef && attrDef?.componentType === COMPONENT_TYPES.TOKEN_GROUP && value.length) {
            return value.map((val) => ({
                label: val,
                dismissLabel: `Dismiss ${val}`,
                disabled: isViewOnly,
            }))
        }
        return []
    }

    const isToken = (id: string, value: any) => {
        const attrDef = targetAttrs.find((attr) => attr.id === id)
        return attrDef && attrDef?.componentType === COMPONENT_TYPES.TOKEN_GROUP
    }

    Object.entries(selectedItem).forEach(([stateId, value]) => {
        if (state[stateId] && state[stateId].label) {
            if (findLabel(stateId, value as string)) {
                result[stateId] = {
                    label: findLabel(stateId, value as string),
                    value: value,
                }
            } else {
                result[stateId] = EMPTY_SELECTION
            }
        } else if (isToken(stateId, value)) {
            result[stateId] = getTokens(stateId, value)
        } else {
            result[stateId] = value
        }
    })
    return result
}

export const programReducer = (state, action) => {
    switch (action.type) {
        case REDUCER_ACTIONS.load:
            return loadSelectedState(state, action.target, true, 'program')
    }
}

export const formatSelectedRevisionFromPath = (locationHook, browserPath) => {
    if (locationHook?.state?.revision_id) {
        return {
            label: '',
            value: locationHook?.state?.revision_id,
        }
    } else if (browserPath.length === 5) {
        // ["", "plan", "60b463ec-5dca-422b-b734-bf943bd1fd79", "revision", "1694489983366" ]
        const revisionIdIdx = 4
        return {
            label: '',
            value: browserPath[revisionIdIdx],
        }
    }
    return EMPTY_REVISION_SELECTION
}

export const formatPlanMetadataFromPath = (locationHook, browserPath) => {
    if (locationHook?.state?.plan_id) {
        return {
            plan_id: locationHook?.state?.plan_id,
        }
    } else if (browserPath.length === 5) {
        // ["", "plan", "60b463ec-5dca-422b-b734-bf943bd1fd79", "revision", "1694489983366" ]
        const planIdIdx = 2
        return {
            plan_id: browserPath[planIdIdx],
        }
    }
    return {}
}

export const formatOPYears = (years, businessEntityMap) => {
    if (!years.length) {
        return []
    }
    const planYearsItems = {}
    years.forEach((year) => {
        const yearSplit = year.split('#')
        const BE = businessEntityMap[yearSplit[0]]
        const planYear = `${yearSplit[1]}#${yearSplit[2]}`
        if (!Object.keys(planYearsItems).includes(planYear)) {
            planYearsItems[planYear] = []
        }
        planYearsItems[planYear].push(BE)
    })
    return Object.keys(planYearsItems)
        .map((planYear) => {
            const plan = planYear.split('#')[1]
            const year = planYear.split('#')[0]
            const BEList = planYearsItems[planYear]
            const item: any = {
                plan: plan,
                year: year,
            }
            if (
                BEList.includes(BUSINESS_ENTITY_ABBREVIATIONS.AR) &&
                BEList.includes(BUSINESS_ENTITY_ABBREVIATIONS.GRD)
            ) {
                item.ar_status = 'ATL'
                item.grd_status = 'ATL'
            } else if (BEList.includes(BUSINESS_ENTITY_ABBREVIATIONS.GRD)) {
                item.ar_status = 'BTL'
                item.grd_status = 'ATL'
            } else if (BEList.includes(BUSINESS_ENTITY_ABBREVIATIONS.AR)) {
                item.ar_status = 'ATL'
                item.grd_status = 'BTL'
            }
            return item
        })
        .sort((a, b) => b.year - a.year)
}
