export interface HomePageButton {
    name: string
    href: string
    external?: boolean
}
export const HC_REPORTS = `Tableau Reports`
export const programsSectionDescription = 'View and edit Program details.'
export const summariesAndAuditSectionDescription = 'View summary reports by type.'

export const planSectionDescription = 'View OP1, OP2, and Off Cycle plans.'
export const orgSectionDescription = 'View organizational structure.'
export const auditSectionDescription = 'View audit reports by type.'

export const planButtons: HomePageButton[] = [
    {
        name: 'Plans',
        href: '/all-plans',
    },
]

export const orgButtons: HomePageButton[] = [
    {
        name: 'Organizations',
        href: '/orgs',
    },
    // todo: uncomment when my groups page has been developed
    // {
    //     name: 'My Groups',
    //     href: '',
    // },
]

export const programButtons: HomePageButton[] = [
    {
        name: 'Program List',
        href: '/programs',
    },
    {
        name: 'HC Editor',
        href: '/sandbox',
    },
    // todo: uncomment when spend estimate page is revamped and available
    // {
    //     name: 'Spend Estimates',
    //     href: '',
    // },
]

export const auditButtons: HomePageButton[] = [
    {
        name: 'Audits',
        href: '/audits',
    },
]

export const summariesButtons: HomePageButton[] = [
    {
        name: 'Plan',
        href: '/plan-summary',
    },
    {
        name: 'Program',
        href: '/program-summary-revamped',
    },
    {
        name: 'Group',
        href: '/group-summary-revamped',
    },
    {
        name: 'Organization',
        href: '/org-summary-revamped',
    },
    // todo: uncomment once summaries page has been consolidated
    // {
    //     name: 'Summaries',
    //     href: '/summaries',
    // },
    {
        name: 'Tableau Reports',
        href: '#',
        external: true,
    },
]
