import { COMPONENT_TYPES, PROGRAM_ATTRIBUTE_TYPES, STATUS_INDICATOR_TYPES } from '../../../Constant'
import { Link, SelectProps, StatusIndicator } from '@amzn/awsui-components-react'
import DatePopover from '../../reusable/DatePopover'
import { convertToLocalTime } from '../../reusable/Utils'

import { styleHeaderConditionally, styleHeaderWithNoWrap } from '../../../common/Util'
import TextPopover from '../../reusable/TextPopover'

interface DeliverableAttribute {
    id: string
    headerName?: string
    headerDisplay?: any
    description?: string
    componentType?: COMPONENT_TYPES
    attributeType: PROGRAM_ATTRIBUTE_TYPES
    selections?: SelectProps.Option[]
    tableVisible: boolean
    exportLocalVisible: boolean
    summaryVisible: boolean
    required: boolean
    adminRequired?: boolean
    checkDuplicate?: boolean
    defaultValue?: any
    nonProgram?: boolean
}

export const NONPROGRAM_DELIVERABLE_ATTRIBUTES = ['deliverable_name', 'description', 'is_active']

export const DELIVERABLE_PRIORITY: SelectProps.Option[] = [
    { label: 'P0 - Business Critical', value: 'p0' },
    { label: 'P1 - Strategically Important', value: 'p1' },
    { label: 'P2 - Nice to Have', value: 'p2' },
    { label: 'P3 - Next Generation', value: 'p3' },
    { label: 'P4 - Backlog for Future Consideration', value: 'p4' },
    { label: 'Obsolete', value: 'obsolete' },
]

export const DELIVERABLE_CATEGORY: SelectProps.Option[] = [
    { label: 'Must Do for OP2 Year', value: 'md' },
    { label: 'Prep Work for Current Year for Next Year', value: 'prep' },
    { label: 'Performance', value: 'perf' },
    { label: 'User Experience', value: 'ux' },
    { label: 'Other', value: 'other' },
]

export const PILLARS: SelectProps.Option[] = [
    { label: 'Safety', value: 'safety' },
    { label: 'Customer Experience', value: 'customer_experience' },
    { label: 'Speed to Customer', value: 'speed_to_customer' },
    { label: 'Quality with DEA', value: 'quality_dea' },
    { label: 'Lowering Cost to Serve', value: 'lowering_cost' },
]

const addYears = (date, years) => {
    const tempDate = new Date(date)
    tempDate.setFullYear(tempDate.getFullYear() + years)
    return tempDate.getFullYear()
}

const generateYearList = () => {
    const now = new Date()
    const yearOptions: SelectProps.Option[] = [
        {
            label: now.getFullYear().toString(),
            value: now.getFullYear().toString(),
        },
    ]
    for (let offset = 1; offset <= 5; offset++) {
        yearOptions[offset] = {
            label: addYears(now, offset).toString(),
            value: addYears(now, offset).toString(),
        }
    }

    return yearOptions
}

const generateDeliverableQuarters = () => {
    const quarterOptions: SelectProps.Option[] = []
    for (let q = 0; q < 4; q++) {
        quarterOptions[q] = {
            label: `Q${q + 1}`,
            value: `Q${q + 1}`,
        }
    }

    return quarterOptions
}

export const deliverableAttributes: DeliverableAttribute[] = [
    {
        id: 'program_id',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'revision_id',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'plan_id',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'plan_sort',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'deliverable_id',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'deliverable_name',
        headerName: 'Deliverable Name',
        description: 'Name of the deliverable',
        componentType: COMPONENT_TYPES.INPUT_STRING,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        required: true,
        adminRequired: true,
        checkDuplicate: true,
        nonProgram: true,
    },
    {
        id: 'description',
        headerName: 'Description',
        description: 'Description of the deliverable',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        required: true,
        adminRequired: true,
        checkDuplicate: false,
        nonProgram: true,
    },
    {
        id: 'delivery_year',
        headerName: 'Deliverable Year',
        headerDisplay: (
            <>
                Deliverable <br></br> Year
            </>
        ),
        componentType: COMPONENT_TYPES.SELECT,
        selections: generateYearList(),
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        required: true,
        adminRequired: false,
    },
    {
        id: 'delivery_quarter',
        headerName: 'Deliverable Quarter',
        headerDisplay: (
            <>
                Deliverable <br></br> Quarter
            </>
        ),
        componentType: COMPONENT_TYPES.SELECT,
        selections: generateDeliverableQuarters(),
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        required: true,
        adminRequired: false,
    },
    {
        id: 'priority',
        headerName: 'Priority',
        componentType: COMPONENT_TYPES.SELECT,
        selections: DELIVERABLE_PRIORITY,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        required: true,
        adminRequired: false,
    },
    {
        id: 'category',
        headerName: 'Category',
        componentType: COMPONENT_TYPES.SELECT,
        selections: DELIVERABLE_CATEGORY,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        required: true,
        adminRequired: false,
    },
    {
        id: 'pillars',
        headerName: 'Pillars',
        componentType: COMPONENT_TYPES.SELECT,
        selections: PILLARS,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        required: true,
        adminRequired: false,
    },
    {
        id: 'is_external_dependency',
        headerName: 'External Dependencies',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: true,
        exportLocalVisible: false,
        required: true,
        adminRequired: false,
        defaultValue: false,
    },
    {
        id: 'note',
        headerName: 'Deliverable Note',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: true,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'entitlement',
        headerName: 'Entitlement',
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'ar_dependencies',
        headerName: 'AR Dependencies',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        required: false,
    },
    {
        id: 'external_dependent_teams',
        headerName: 'External Dependent Teams',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: true,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'is_active',
        headerDisplay: (
            <>
                Is <br></br> Active
            </>
        ),
        headerName: 'Is Active',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        required: true,
        exportLocalVisible: false,
        defaultValue: true,
        nonProgram: true,
    },
    {
        id: 'updated_by',
        headerName: 'Updated By',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'updated_at',
        headerName: 'Updated At',
        componentType: COMPONENT_TYPES.DATE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'created_by',
        headerName: 'Created By',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        required: false,
    },
    {
        id: 'created_at',
        headerName: 'Created At',
        componentType: COMPONENT_TYPES.DATE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        required: false,
    },
]

export const getDeliverableTableVisibleColumns = () => {
    return deliverableAttributes.flatMap((attr) => (attr.tableVisible ? [attr.id] : []))
}

export const getDeliverableExportVisibleColumns = () => {
    return deliverableAttributes.flatMap((attr) => (attr.exportLocalVisible ? [attr.id] : []))
}

export const renderDeliverableCell = (item, attr: DeliverableAttribute) => {
    switch (attr.componentType) {
        case COMPONENT_TYPES.TOGGLE:
            return item[attr.id] ? (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
            ) : (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
            )
        case COMPONENT_TYPES.SELECT: {
            const value = attr.selections
                ? attr.selections.find((option) => option.value === item[attr.id])
                : undefined
            return value ? value.label : ''
        }
        case COMPONENT_TYPES.DATE:
            return <DatePopover date={convertToLocalTime(item[attr.id])} />
        case COMPONENT_TYPES.LINK:
            return (
                <Link external href={item[attr.id]}>
                    {item[attr.id]}
                </Link>
            )
        case COMPONENT_TYPES.TOKEN_GROUP:
            return item[attr.id]?.toString()
        default:
            return attr.id === 'description' ? (
                <TextPopover text={item[attr.id]} lengthLimit={50} />
            ) : (
                item[attr.id]
            )
    }
}

export const getDeliverableColumnDefinitions = () => {
    return deliverableAttributes.flatMap((attr) => [
        {
            id: attr.id,
            header: styleHeaderConditionally(attr),
            cell: (item) => renderDeliverableCell(item, attr),
            exportColumnName: attr.headerName,
            sortingField: attr.id,
        },
    ])
}

export const getNonProgramDeliverableAttributes = () => {
    return deliverableAttributes.flatMap((attr) => ('nonProgram' in attr ? [attr] : []))
}

export const getDeliverableAttributes = () => {
    return deliverableAttributes.flatMap((attr) =>
        attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.LOCAL ? [attr] : [],
    )
}

const programNameRender = (handleProgramView, programMetaData) => {
    return {
        id: 'prog_name_display',
        header: styleHeaderWithNoWrap('Program'),
        cell: (e) => (
            <Link
                onFollow={() => {
                    handleProgramView(programMetaData)
                }}
            >
                {e.program_name}
            </Link>
        ),
        sortingField: 'program_name',
    }
}

export const getProgramColumnDefinitionsForDeliverables = (
    handleProgramView,
    programMetaData,
    isGlobalProgram,
    programAttributes,
) => {
    const programAttrs = isGlobalProgram
        ? programAttributes.filter((attr) => attr.attributeType !== PROGRAM_ATTRIBUTE_TYPES.LOCAL)
        : programAttributes
    const definitions = programAttrs.flatMap((attr) => [
        {
            id: attr.id,
            header: styleHeaderConditionally(attr),
            cell: (item) => renderDeliverableCell(item, attr),
            sortingField: attr.id,
        },
    ])
    definitions.unshift(programNameRender(handleProgramView, programMetaData))

    return definitions
}
