import { Link, SelectProps, Popover, Icon, SpaceBetween, Box } from '@amzn/awsui-components-react'
import {
    COMPONENT_TYPES,
    ENTITLEMENT_CURRENCY,
    POPOVER_CONTENT_TYPES,
    PROGRAM_ATTRIBUTE_TYPES,
} from '../../Constant'
import DatePopover from '../reusable/DatePopover'
import { arrayGroupBy, convertToLocalTime } from '../reusable/Utils'
import { styleHeaderConditionally, styleHeaderWithNoWrap } from '../../common/Util'

import StatusIndicatorTemplate from '../reusable/StatusIndicatorTemplate'
import GenericSummaryTable from '../reusable/GenericSummaryTable'

export const PROGRAM_SELECTION_IDS = ['program_theme', 'eoy_target_rl', 'primary_building_type']

interface ProgramAttribute {
    id: string
    headerName?: string
    headerDisplay?: any
    description?: string
    componentType?: COMPONENT_TYPES
    attributeType: PROGRAM_ATTRIBUTE_TYPES
    selections?: SelectProps.Option[]
    tableVisible: boolean
    summaryVisible: boolean
    exportLocalVisible: boolean
    exportGlobalVisible: boolean
    required: boolean
    adminRequired: boolean
    inPayload: boolean
    checkDuplicate?: boolean
    defaultValue?: any
    disabled?: boolean
    tokenGroupInputId?: string
    tokenGroupLimit?: number
    saveTokensAsList?: boolean
    rowDefinition?: number
    popoverContentType?: any
    popoverTableColumns?: any
    popoverTableVisibleColumns?: any
}
// edit from BE-specific list, or create a new program
export const PARENT_CHILD_PROGRAM_CREATE_UPDATE = 'parent_child'
// update core attributes for a program (library entry)
export const PARENT_PROGRAM_CREATE_UPDATE = 'parent'
// add a program to a plan
export const CHILD_PROGRAM_CREATE_UPDATE = 'child'

export const programAttributes: ProgramAttribute[] = [
    {
        id: 'program_id',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        inPayload: true,
    },
    {
        id: 'parent_program_id',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        inPayload: true,
    },
    {
        id: 'plan_id',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        inPayload: true,
    },
    {
        id: 'program_name',
        headerName: 'Program Name',
        description: 'Name of the program',
        componentType: COMPONENT_TYPES.INPUT_STRING,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: false,
        summaryVisible: true,
        exportLocalVisible: true,
        exportGlobalVisible: true,
        required: true,
        adminRequired: true,
        checkDuplicate: true,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'stl_name',
        headerName: 'STL Name',
        description: 'Single Threaded Leader name',
        componentType: COMPONENT_TYPES.INPUT_STRING,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        exportGlobalVisible: true,
        required: true,
        adminRequired: true,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'is_true_program',
        headerName: 'Is Program',
        headerDisplay: (
            <>
                Is <br></br> Program
            </>
        ),
        description: 'Select if this is a true program',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: true,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: true,
        adminRequired: true,
        checkDuplicate: false,
        defaultValue: true,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'op_years',
        headerName: 'OP Years',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        componentType: COMPONENT_TYPES.POPOVER,
        tableVisible: false,
        defaultValue: [],
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: true,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: false,
        popoverContentType: POPOVER_CONTENT_TYPES.TABLE,
        popoverTableColumns: [
            {
                id: 'year',
                header: 'Year',
                cell: (item) => item.year,
                sortingField: 'year',
            },
            {
                id: 'plan',
                header: 'Plan',
                cell: (item) => item.plan,
                sortingField: 'plan',
            },
            {
                id: 'ar_status',
                header: 'AR Status',
                cell: (item) => item.ar_status,
                sortingField: 'ar_status',
            },
            {
                id: 'grd_status',
                header: 'GRD Status',
                cell: (item) => item.grd_status,
                sortingField: 'grd_status',
            },
        ],
        popoverTableVisibleColumns: [
            { id: 'year', visible: true },
            { id: 'plan', visible: true },
            { id: 'ar_status', visible: true },
            { id: 'grd_status', visible: true },
        ],
    },
    {
        id: 'is_overhead_applied',
        headerName: 'Subject to OH',
        headerDisplay: (
            <>
                Subject <br></br> to OH{' '}
            </>
        ),
        description: 'Select if overhead should be applied to headcount/effort estimates',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        exportGlobalVisible: true,
        required: false,
        adminRequired: true,
        checkDuplicate: false,
        defaultValue: true,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'stl_alias',
        headerName: 'STL Alias',
        description: 'Single Threaded Leader alias',
        componentType: COMPONENT_TYPES.TOKEN_GROUP,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        exportGlobalVisible: true,
        required: true,
        adminRequired: true,
        defaultValue: [],
        checkDuplicate: false,
        inPayload: true,
        tokenGroupInputId: 'stl_alias_input',
        tokenGroupLimit: 1,
        saveTokensAsList: false,
        rowDefinition: 2,
    },
    {
        id: 'stl_alias_input',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        defaultValue: '',
        checkDuplicate: false,
        inPayload: false,
    },
    {
        id: 'registered_users',
        headerName: 'Registered Users',
        description: 'Registered users for the program',
        componentType: COMPONENT_TYPES.TOKEN_GROUP,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: true,
        defaultValue: [],
        summaryVisible: true,
        exportLocalVisible: true,
        exportGlobalVisible: true,
        required: false,
        adminRequired: false,
        checkDuplicate: true,
        inPayload: true,
        tokenGroupInputId: 'registered_users_input',
        saveTokensAsList: true,
        rowDefinition: 2,
    },
    {
        id: 'registered_users_input',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        defaultValue: '',
        checkDuplicate: false,
        inPayload: false,
    },
    {
        id: 'description',
        headerName: 'Description',
        description: 'Description of the program',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        exportGlobalVisible: false,
        required: true,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'owned_org',
        headerName: 'Owned Org',
        description: 'Organization who is responsible for this program',
        componentType: COMPONENT_TYPES.INPUT_STRING,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        exportGlobalVisible: false,
        required: true,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 4,
    },
    {
        id: 'program_theme',
        headerName: 'Program Theme',
        description: 'Strategic theme for this program',
        componentType: COMPONENT_TYPES.SELECT,
        selections: [],
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        exportGlobalVisible: false,
        required: true,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 3,
    },
    {
        id: 'justification',
        headerName: 'Justification',
        description:
            'Why is this program/project important to the customer? What is it trying to achieve?',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: true,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'year_end_goal',
        headerName: 'Year End Goal',
        description: 'What will the program deliver by year end?',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: true,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 2,
    },
    {
        id: 'program_note',
        headerName: 'Program Note',
        description: 'Notes/Comments regarding the program',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: true,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 2,
    },
    {
        id: 'eoy_target_rl',
        headerName: 'EOY Target RL',
        description: 'End of Year Target Program/Readiness Level',
        componentType: COMPONENT_TYPES.SELECT,
        selections: [],
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: true,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 3,
    },
    {
        id: 'primary_building_type',
        headerName: 'Primary Building Type',
        componentType: COMPONENT_TYPES.SELECT,
        selections: [],
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 3,
    },
    {
        id: 'ga_target_date',
        headerName: 'GA Target Date',
        componentType: COMPONENT_TYPES.INPUT_STRING,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 4,
    },
    {
        id: 'three_year_entitlement',
        headerName: '3 Year Entitlement',
        description: ENTITLEMENT_CURRENCY.MILLION,
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 4,
    },
    {
        id: 'five_year_entitlement',
        headerName: '5 Year Entitlement',
        description: ENTITLEMENT_CURRENCY.MILLION,
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 4,
    },
    {
        id: 'priority',
        headerName: 'Priority',
        description: 'Used to organize the program sequence for reporting',
        componentType: COMPONENT_TYPES.INPUT_INT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
    },
    {
        id: 'display_seq',
        headerName: 'Display Seq',
        description: 'Secondary sort value',
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
    },
    {
        id: 'is_locked',
        headerName: 'Is Locked',
        description: 'Lock the program to disable estimates against it',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        summaryVisible: false,
        required: true,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
    },
    {
        id: 'is_carryover',
        headerName: 'Is Carryover',
        description: "Was this a program in last year's OP2?",
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: true,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
    },
    {
        id: 'is_active',
        headerName: 'Is Active',
        headerDisplay: (
            <>
                Is <br></br> Active
            </>
        ),
        description: '',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: true,
        checkDuplicate: false,
        inPayload: true,
        defaultValue: true,
        adminRequired: false,
    },
    {
        id: 'is_op_goal',
        headerName: 'Is OP Goal',
        headerDisplay: <>Is OP Goal</>,
        description: '',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        checkDuplicate: false,
        inPayload: true,
        defaultValue: true,
        adminRequired: false,
    },
    {
        id: 'op1_estimate_ct',
        headerName: 'OP1 Estimated C&T',
        description: 'C&T Headcount from OP1',
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
    },
    {
        id: 'op1_estimate_ff',
        headerName: 'OP1 Estimated FF',
        description: 'FF Headcount from OP1',
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
    },
    {
        id: 'op1_estimate_total',
        headerName: 'OP1 Estimate Total',
        description: 'Total Headcount from OP1',
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: false,
        disabled: true,
    },
    {
        id: 'created_by',
        headerName: 'Created By',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        inPayload: false,
    },
    {
        id: 'created_at',
        headerName: 'Created At',
        componentType: COMPONENT_TYPES.DATE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        adminRequired: false,
        inPayload: false,
    },
    {
        id: 'updated_by',
        headerName: 'Updated By',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        inPayload: false,
        adminRequired: false,
    },
    {
        id: 'updated_at',
        headerName: 'Updated At',
        componentType: COMPONENT_TYPES.DATE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        exportLocalVisible: false,
        exportGlobalVisible: false,
        required: false,
        inPayload: false,
        adminRequired: false,
    },
]

export const getProgramCoreAttributes = () => {
    return programAttributes.flatMap((attr) =>
        attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.CORE ? [attr] : [],
    )
}

export const getProgramGlobalAttributes = () => {
    return programAttributes.flatMap((attr) =>
        attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.GLOBAL ? [attr] : [],
    )
}

export const getProgramLocalAttributes = () => {
    return programAttributes.flatMap((attr) =>
        attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.LOCAL ? [attr] : [],
    )
}

export const getAttributesByRow = (attrType) => {
    switch (attrType) {
        case PROGRAM_ATTRIBUTE_TYPES.CORE:
            return arrayGroupBy(
                getProgramCoreAttributes().filter((attr) => attr.rowDefinition !== undefined),
                'rowDefinition',
            )
        case PROGRAM_ATTRIBUTE_TYPES.LOCAL:
            return arrayGroupBy(
                getProgramLocalAttributes().filter((attr) => attr.rowDefinition !== undefined),
                'rowDefinition',
            )
        case PROGRAM_ATTRIBUTE_TYPES.GLOBAL:
            return arrayGroupBy(
                getProgramGlobalAttributes().filter((attr) => attr.rowDefinition !== undefined),
                'rowDefinition',
            )
        default:
            return {}
    }
}

export const getProgramTableLocalVisibleColumns = () => {
    const definitions = programAttributes.flatMap((attr) =>
        attr.tableVisible ? [{ id: attr.id, visible: true }] : [],
    )
    definitions.unshift({ id: 'prog_name_display', visible: true })

    return definitions
}

export const getProgramTableGlobalVisibleColumns = () => {
    const definitions = programAttributes.flatMap((attr) =>
        attr.tableVisible ? [{ id: attr.id, visible: true }] : [],
    )
    definitions.push({ id: 'update_by', visible: true }, { id: 'update_at', visible: true })
    definitions.unshift({ id: 'prog_name_display', visible: true })
    return definitions
}

export const getProgramTableExportLocalVisibleColumns = () => {
    return programAttributes.flatMap((attr) => (attr.exportLocalVisible ? [attr.id] : []))
}

export const getProgramTableExportGlobalVisibleColumns = () => {
    return programAttributes.flatMap((attr) => (attr.exportGlobalVisible ? [attr.id] : []))
}

export const getProgramTableExportAllColumns = () => {
    return programAttributes.flatMap((attr) =>
        attr.inPayload && !attr.id.includes('id') ? [attr.id] : [],
    )
}

export const getProgramSummaryVisibleColumns = () => {
    const definitions = programAttributes.flatMap((attr) => (attr.summaryVisible ? [attr.id] : []))
    definitions.unshift('prog_name_display')

    return definitions
}

const renderCell = (item, attr: ProgramAttribute) => {
    switch (attr.componentType) {
        case COMPONENT_TYPES.TOGGLE:
            return <StatusIndicatorTemplate value={item[attr.id]} />
        case COMPONENT_TYPES.SELECT: {
            const value = attr.selections
                ? attr.selections.find((option) => option.value === item[attr.id])
                : undefined
            return value ? value.label : ''
        }
        case COMPONENT_TYPES.DATE:
            return <DatePopover date={convertToLocalTime(item[attr.id])} />
        case COMPONENT_TYPES.LINK:
            return (
                <Link external href={item[attr.id]}>
                    {item[attr.id]}
                </Link>
            )
        case COMPONENT_TYPES.TOKEN_GROUP:
            return item[attr.id].join(', ')
        case COMPONENT_TYPES.POPOVER:
            return item[attr.id].length ? (
                <Popover
                    size='large'
                    position='right'
                    fixedWidth
                    content={renderPopoverContent(item[attr.id], attr.popoverContentType, attr)}
                >
                    <SpaceBetween size='xxs' direction={'horizontal'}>
                        <Icon name='status-info' />
                        <Box color={'text-body-secondary'}> View </Box>
                    </SpaceBetween>
                </Popover>
            ) : (
                <></>
            )
        default:
            return item[attr.id]
    }
}

const renderPopoverContent = (content, contentType, attr: ProgramAttribute) => {
    switch (contentType) {
        case POPOVER_CONTENT_TYPES.TABLE:
            return (
                <Box margin={{ left: 's', right: 's' }}>
                    <GenericSummaryTable
                        itemsToShow={content}
                        actions={[]}
                        columns={attr.popoverTableColumns}
                        visibleColumns={attr.popoverTableVisibleColumns}
                        defaultNameField={''}
                        nameField={''}
                        isLoading={!content.length}
                        objectType={'Years'}
                        includeHeader={false}
                        wrapLines
                        variant={'borderless'}
                        includePagination={false}
                    ></GenericSummaryTable>
                </Box>
            )
        default:
            return content
    }
}

const programNameRender = (history, planMetadata, selectedRevision, programType) => {
    return {
        id: 'prog_name_display',
        header: styleHeaderWithNoWrap('Program'),
        cell: (item) => (
            <Link
                onFollow={(event) => {
                    event.preventDefault()
                    const programId =
                        programType === 'global' ? item.parent_program_id : item.program_id
                    history(
                        `/plan/${planMetadata.plan_id}/revision/${selectedRevision.value ?? ''}/program/${programId}?type=${programType}`,
                    )
                }}
            >
                {item.program_name}
            </Link>
        ),
        exportColumnName: 'Program',
        sortingField: 'program_name',
    }
}

export const getProgramColumnDefinitions = (
    history,
    planMetadata,
    selectedRevision,
    programAttributes,
) => {
    const definitions = programAttributes.flatMap((attr) => [
        {
            id: attr.id,
            header: styleHeaderConditionally(attr),
            cell: (item) => renderCell(item, attr),
            exportColumnName: attr.headerName,
            sortingField: attr.id,
        },
    ])
    definitions.unshift(programNameRender(history, planMetadata, selectedRevision, 'local'))

    return definitions
}

export const getGlobalProgramColumnDefinitions = (history, planMetadata, selectedRevision) => {
    const definitions = programAttributes.flatMap((attr) =>
        attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.CORE
            ? [
                  {
                      id: attr.id,
                      header: styleHeaderConditionally(attr),
                      cell: (item) => renderCell(item, attr),
                      exportColumnName: attr.headerName,
                      sortingField: attr.id,
                  },
              ]
            : [],
    )
    definitions.push(
        {
            id: 'update_by',
            header: 'Updated By',
            cell: (item) => item['update_by'],
            exportColumnName: 'Updated By',
            sortingField: 'update_by',
        },
        {
            id: 'update_at',
            header: 'Updated At',
            cell: (item) => <DatePopover date={convertToLocalTime(item.update_at)} />,
            exportColumnName: 'Updated At',
            sortingField: 'update_at',
        },
    )
    definitions.unshift(programNameRender(history, planMetadata, selectedRevision, 'global'))

    return definitions
}

export const getFormattedAttributes = (selections, attributes) => {
    const result = [...attributes]
    const formattedSelections = {}
    selections.forEach((selection) => {
        const key = selection.key
        if (!(key in formattedSelections)) {
            formattedSelections[key] = []
        }
        formattedSelections[key].push({
            label: selection.display_name,
            value: selection.value,
        })
    })

    Object.keys(formattedSelections).forEach(function (key) {
        const attrIndex = result.findIndex((attr) => attr.id === key)
        if (attrIndex) {
            result[attrIndex].selections = formattedSelections[key]
        }
    })

    return result
}
