import { Box, SpaceBetween, Select, TextContent } from '@amzn/awsui-components-react'
import React, { useEffect, useMemo } from 'react'
import { DEFAULT_PAGE_SIZE_OPTIONS, getColumnsForState } from './Constant'
import { AUDIT_STATE_ACTIONS } from './useAuditState'
import { useAuditContext } from './AuditContext'
import useStore from '../../Store'
import {
    buildLogGroupKeyFromState,
    fetchAuditDataForTab,
    isDataLoading,
    isGridUnusable,
    onGoToPage,
} from './AuditUtil'

import _ from 'lodash'
import Pagination from '@amzn/awsui-components-react/polaris/pagination'

export interface AuditLoadParams {
    apiClient: any
}

const AuditPaginationComponent = (props: AuditLoadParams) => {
    const { apiClient } = props
    const { state, dispatch } = useAuditContext()
    const {
        activeTabId,
        auditTabDataStates,
        programsByPlan,
        deliverablesByPlan,
        revisionsMap,
        orgsMap,
        groupsMap,
        plansMap,
        startTimestamp,
        endTimestamp,
    } = state

    const selectedPlanId: string = state.selectPlanData?.selected?.value || ''
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const logGroupKey = buildLogGroupKeyFromState(state)
    const logGroupPlanData = auditTabDataStates.get(logGroupKey)
    const startItem = useMemo(() => {
        const currentPage = logGroupPlanData?.currentPageIndex ?? 1
        return state.selectedPageSize * currentPage - state.selectedPageSize + 1
    }, [state.selectedPageSize, logGroupPlanData?.currentPageIndex])
    const endItem = useMemo(() => {
        const displayedTotal = logGroupPlanData?.visibleRows ?? 0
        return Math.min(displayedTotal, startItem + state.selectedPageSize - 1)
    }, [logGroupPlanData?.visibleRows, startItem, state.selectedPageSize])

    useEffect(() => {
        if (isDataLoading(state, selectedBusinessEntity) || isGridUnusable(state.gridApi)) {
            return
        }

        if (
            logGroupPlanData?.startTimestampFetched === startTimestamp &&
            logGroupPlanData?.endTimestampFetched === endTimestamp
        ) {
            dispatch({
                action: AUDIT_STATE_ACTIONS.SET_MANY,
                payload: {
                    [state.activeTabId]: { loading: false },
                },
            })
            return
        }
        if (
            logGroupPlanData?.queryKey === undefined ||
            logGroupPlanData?.queryKeyValues === undefined
        ) {
            console.error('Log group query key undefined.')
            dispatch({
                action: AUDIT_STATE_ACTIONS.SET_MANY,
                payload: {
                    [state.activeTabId]: { loading: false },
                },
            })
            return
        }
        fetchAuditDataForTab({
            apiClient: apiClient,
            state: state,
            dispatch: dispatch,
            isConcatingData: false,
            queryKey: logGroupPlanData.queryKey,
            queryKeyValues: logGroupPlanData.queryKeyValues,
        })
    }, [
        state.gridApi,
        revisionsMap.data,
        orgsMap.data,
        groupsMap.data,
        plansMap.data,
        programsByPlan.data,
        deliverablesByPlan.data,
        startTimestamp,
        endTimestamp,
        selectedPlanId,
        activeTabId,
        logGroupPlanData,
    ])

    const isLoading =
        revisionsMap.loading ||
        orgsMap.loading ||
        groupsMap.loading ||
        plansMap.loading ||
        state[state.activeTabId].loading

    return (
        <Box float='right'>
            <SpaceBetween direction='horizontal' size='m'>
                <TextContent>
                    <p>Page Size:</p>
                </TextContent>
                <Select
                    selectedOption={{
                        value: state.selectedPageSize.toString(),
                        label: state.selectedPageSize.toString(),
                    }}
                    onChange={({ detail }) => {
                        const newPageSize = Number(detail.selectedOption.value)
                        state.gridApi.setGridOption('paginationPageSize', newPageSize)
                        dispatch({
                            type: AUDIT_STATE_ACTIONS.SET_PAGE_SIZE,
                            new_page_size: newPageSize,
                            log_group_key: logGroupKey,
                        })
                        onGoToPage(state.gridApi, 1)
                    }}
                    options={DEFAULT_PAGE_SIZE_OPTIONS}
                />
                {Boolean((logGroupPlanData?.rowData || []).length) &&
                    (logGroupPlanData?.visibleRows ?? 0) !== 0 && (
                        <TextContent>
                            <p>
                                <b>{startItem.toLocaleString()}</b> to{' '}
                                <b>{endItem.toLocaleString()}</b> of{' '}
                                <b>{(logGroupPlanData?.visibleRows ?? 1).toLocaleString()}</b>
                            </p>
                        </TextContent>
                    )}
                <Pagination
                    currentPageIndex={logGroupPlanData?.currentPageIndex ?? 1}
                    onChange={({ detail }) => {
                        dispatch({
                            type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
                            payload: {
                                currentPageIndex: detail.currentPageIndex,
                            },
                            log_group_key: logGroupKey,
                        })
                        onGoToPage(state.gridApi, detail.currentPageIndex)
                    }}
                    onNextPageClick={({ detail }) => {
                        if (!logGroupPlanData?.nextToken || isLoading) {
                            return
                        }

                        dispatch({
                            type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
                            payload: {
                                currentPageIndex: detail.requestedPageIndex,
                            },
                            log_group_key: logGroupKey,
                        })
                        onGoToPage(state.gridApi, detail.requestedPageIndex)
                    }}
                    onPreviousPageClick={({ detail }) => {
                        dispatch({
                            type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
                            payload: {
                                currentPageIndex: detail.requestedPageIndex,
                            },
                            log_group_key: logGroupKey,
                        })
                        onGoToPage(state.gridApi, detail.requestedPageIndex)
                    }}
                    openEnd={false}
                    pagesCount={logGroupPlanData?.totalPages ?? 1}
                />
            </SpaceBetween>
        </Box>
    )
}
export default AuditPaginationComponent
