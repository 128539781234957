import { COMPONENT_TYPES, MODAL_MODES, PROGRAM_ATTRIBUTE_TYPES } from '../../Constant'
import {
    ColumnLayout,
    FormField,
    Input,
    Select,
    Textarea,
    Toggle,
    InputProps,
    SpaceBetween,
    Box,
    TokenGroup,
    Button,
} from '@amzn/awsui-components-react'
import ComponentOnChangeTemplate from './ComponentOnChangeTemplate'

import { getInputProperties, getOpEstimateValueSum } from './Utils'
import EmptyModalSection from './EmptyModalSection'

interface GroupFormFieldGeneratorProps {
    isStlNotAdmin: boolean
    attributes: any[]
    state: any
    inputChange: any
    items: any[]
    selectedItems: any[]
    isInputInvalid: boolean
    onIsInputInvalidChanged: any
    onIsDataUnmodifiedChanged: any
    isAddingProgram: boolean
    formErrors: any
    setFormErrors: any
    modalMode?: string
    colNum?: number
}

const GroupFormFieldGenerator = (props: GroupFormFieldGeneratorProps) => {
    const {
        isStlNotAdmin,
        attributes,
        state,
        inputChange,
        items,
        selectedItems,
        isInputInvalid,
        onIsInputInvalidChanged,
        onIsDataUnmodifiedChanged,
        isAddingProgram,
        modalMode,
        formErrors,
        setFormErrors,
        colNum,
    } = props

    return (
        <ColumnLayout columns={colNum || 4}>
            {attributes.map((attr) => {
                let required = isStlNotAdmin ? attr.required : attr.adminRequired
                if (required === undefined) {
                    required = attr.required
                }
                switch (attr.componentType) {
                    case COMPONENT_TYPES.INPUT_STRING:
                    case COMPONENT_TYPES.INPUT_INT:
                    case COMPONENT_TYPES.INPUT_FLOAT:
                        return (
                            <FormField
                                key={attr.id}
                                label={
                                    attr.disabled ? (
                                        <span> {attr.headerName} </span>
                                    ) : (
                                        <span>
                                            {attr.headerName}
                                            {!required && <i> - optional</i>}
                                        </span>
                                    )
                                }
                                description={attr.description}
                                errorText={required ? state[`${attr.id}_error`] : ''}
                            >
                                <SpaceBetween size={'xxs'} direction={'horizontal'}>
                                    <Input
                                        value={
                                            attr.disabled
                                                ? getOpEstimateValueSum(
                                                      state['op1_estimate_ct'],
                                                      state['op1_estimate_ff'],
                                                  )
                                                : state[attr.id]
                                        }
                                        onChange={({ detail }) =>
                                            ComponentOnChangeTemplate(
                                                attr.checkDuplicate,
                                                required,
                                                attr.componentType,
                                                attr.id,
                                                detail.value,
                                                attr.headerName,
                                                inputChange,
                                                selectedItems,
                                                items,
                                                isInputInvalid,
                                                onIsInputInvalidChanged,
                                                onIsDataUnmodifiedChanged,
                                                formErrors,
                                                setFormErrors,
                                            )
                                        }
                                        disabled={
                                            attr.disabled ||
                                            (isAddingProgram &&
                                                attr.attributeType !==
                                                    PROGRAM_ATTRIBUTE_TYPES.LOCAL) ||
                                            modalMode === MODAL_MODES.VIEW ||
                                            (attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.CORE &&
                                                isStlNotAdmin)
                                        }
                                        type={
                                            getInputProperties(attr.componentType)
                                                .type as InputProps.Type
                                        }
                                        inputMode={
                                            getInputProperties(attr.componentType)
                                                .inputMode as InputProps.InputMode
                                        }
                                        step={
                                            getInputProperties(attr.componentType, state[attr.id])
                                                .step
                                        }
                                    ></Input>
                                </SpaceBetween>
                            </FormField>
                        )
                    case COMPONENT_TYPES.TEXTAREA:
                        return (
                            <FormField
                                key={attr.id}
                                label={
                                    <span>
                                        {attr.headerName}
                                        {!required && <i> - optional</i>}
                                    </span>
                                }
                                description={attr.description}
                                errorText={required ? state[`${attr.id}_error`] : ''}
                            >
                                <Textarea
                                    value={state[attr.id]}
                                    rows={5}
                                    onChange={({ detail }) =>
                                        ComponentOnChangeTemplate(
                                            attr.checkDuplicate,
                                            required,
                                            COMPONENT_TYPES.TEXTAREA,
                                            attr.id,
                                            detail.value,
                                            attr.headerName,
                                            inputChange,
                                            selectedItems,
                                            items,
                                            isInputInvalid,
                                            onIsInputInvalidChanged,
                                            onIsDataUnmodifiedChanged,
                                            formErrors,
                                            setFormErrors,
                                        )
                                    }
                                    disabled={
                                        (isAddingProgram &&
                                            attr.attributeType !== PROGRAM_ATTRIBUTE_TYPES.LOCAL) ||
                                        modalMode === MODAL_MODES.VIEW
                                    }
                                    spellcheck
                                ></Textarea>
                            </FormField>
                        )
                    case COMPONENT_TYPES.TOGGLE:
                        return (
                            <FormField
                                key={attr.id}
                                label={
                                    <span>
                                        {attr.headerName}
                                        {!required && <i> - optional</i>}
                                    </span>
                                }
                                description={attr.description}
                            >
                                <Toggle
                                    onChange={({ detail }) => {
                                        inputChange(attr.id, detail.checked)
                                        onIsDataUnmodifiedChanged(false)
                                    }}
                                    checked={state[attr.id]}
                                    disabled={
                                        (isAddingProgram &&
                                            attr.attributeType !== PROGRAM_ATTRIBUTE_TYPES.LOCAL) ||
                                        modalMode === MODAL_MODES.VIEW ||
                                        (attr.id === 'is_true_program' && isStlNotAdmin)
                                    }
                                ></Toggle>
                            </FormField>
                        )
                    case COMPONENT_TYPES.SELECT:
                        return (
                            <FormField
                                key={attr.id}
                                label={
                                    <span>
                                        {attr.headerName}
                                        {!required && <i> - optional</i>}
                                    </span>
                                }
                                description={attr.description}
                                errorText={required ? state[`${attr.id}_error`] : ''}
                            >
                                <Select
                                    selectedOption={state[attr.id]}
                                    onChange={({ detail }) =>
                                        ComponentOnChangeTemplate(
                                            attr.checkDuplicate,
                                            required,
                                            COMPONENT_TYPES.SELECT,
                                            attr.id,
                                            detail.selectedOption,
                                            attr.headerName,
                                            inputChange,
                                            selectedItems,
                                            items,
                                            isInputInvalid,
                                            onIsInputInvalidChanged,
                                            onIsDataUnmodifiedChanged,
                                            formErrors,
                                            setFormErrors,
                                        )
                                    }
                                    options={attr.selections}
                                    disabled={
                                        (isAddingProgram &&
                                            attr.attributeType !== PROGRAM_ATTRIBUTE_TYPES.LOCAL) ||
                                        modalMode === MODAL_MODES.VIEW
                                    }
                                ></Select>
                            </FormField>
                        )
                    case COMPONENT_TYPES.TOKEN_GROUP:
                        return modalMode === MODAL_MODES.VIEW ? (
                            !(state[attr.id] || []).length ? (
                                <EmptyModalSection headerTitle={attr.headerName} />
                            ) : (
                                <SpaceBetween size={'s'} direction={'vertical'}>
                                    <Box variant={'h3'}>{attr.headerName}</Box>
                                    <TokenGroup
                                        i18nStrings={{
                                            limitShowFewer: `Show fewer ${attr.headerName}`,
                                            limitShowMore: `Show more  ${attr.headerName}`,
                                        }}
                                        items={state[attr.id] || []}
                                        limit={3}
                                    />
                                </SpaceBetween>
                            )
                        ) : (
                            <FormField
                                key={attr.id}
                                label={
                                    <span>
                                        {attr.headerName}
                                        {!required && <i> - optional</i>}
                                    </span>
                                }
                                description={attr.description}
                            >
                                <SpaceBetween size={'s'}>
                                    <TokenGroup
                                        i18nStrings={{
                                            limitShowFewer: `Show fewer ${attr.headerName}`,
                                            limitShowMore: `Show more  ${attr.headerName}`,
                                        }}
                                        onDismiss={({ detail: { itemIndex } }) => {
                                            if ((state[attr.id] || []).length) {
                                                const temp = [
                                                    ...state[attr.id].slice(0, itemIndex),
                                                    ...state[attr.id].slice(itemIndex + 1),
                                                ]
                                                ComponentOnChangeTemplate(
                                                    attr.checkDuplicate,
                                                    required,
                                                    COMPONENT_TYPES.TOKEN_GROUP,
                                                    attr.id,
                                                    temp,
                                                    attr.headerName,
                                                    inputChange,
                                                    selectedItems,
                                                    items,
                                                    isInputInvalid,
                                                    onIsInputInvalidChanged,
                                                    onIsDataUnmodifiedChanged,
                                                    formErrors,
                                                    setFormErrors,
                                                )
                                                onIsDataUnmodifiedChanged(false)
                                            }
                                        }}
                                        items={state[attr.id] || []}
                                        limit={3}
                                    />
                                    <SpaceBetween size={'xs'} direction={'horizontal'}>
                                        <Input
                                            value={state[attr.tokenGroupInputId] || ''}
                                            onChange={({ detail }) =>
                                                inputChange(attr.tokenGroupInputId, detail.value)
                                            }
                                            disabled={
                                                (isAddingProgram &&
                                                    attr.attributeType !==
                                                        PROGRAM_ATTRIBUTE_TYPES.LOCAL) ||
                                                modalMode === MODAL_MODES.VIEW ||
                                                (attr.attributeType ===
                                                    PROGRAM_ATTRIBUTE_TYPES.CORE &&
                                                    isStlNotAdmin)
                                            }
                                        ></Input>
                                        <Button
                                            onClick={() => {
                                                const newAliases = (state[attr.id] || []).length
                                                    ? state[attr.id].map((item) => ({
                                                          label: item.label,
                                                          dismissLabel: `Remove ${item.label}`,
                                                      }))
                                                    : []
                                                const newAlias = state[attr.tokenGroupInputId] || ''
                                                newAliases.push({
                                                    label: newAlias,
                                                    dismissLabel: `Remove ${newAlias}`,
                                                })
                                                inputChange(attr.tokenGroupInputId, '')
                                                ComponentOnChangeTemplate(
                                                    attr.checkDuplicate,
                                                    required,
                                                    COMPONENT_TYPES.TOKEN_GROUP,
                                                    attr.id,
                                                    newAliases,
                                                    attr.headerName,
                                                    inputChange,
                                                    selectedItems,
                                                    items,
                                                    isInputInvalid,
                                                    onIsInputInvalidChanged,
                                                    onIsDataUnmodifiedChanged,
                                                    formErrors,
                                                    setFormErrors,
                                                )
                                            }}
                                            disabled={
                                                (isAddingProgram &&
                                                    attr.attributeType !==
                                                        PROGRAM_ATTRIBUTE_TYPES.LOCAL) ||
                                                modalMode === MODAL_MODES.VIEW ||
                                                (attr.attributeType ===
                                                    PROGRAM_ATTRIBUTE_TYPES.CORE &&
                                                    isStlNotAdmin) ||
                                                !state[attr.tokenGroupInputId] ||
                                                (attr?.tokenGroupLimit &&
                                                    (state[attr.id] || []).length ===
                                                        attr.tokenGroupLimit)
                                            }
                                        >
                                            {`Add ${attr.headerName}`}
                                        </Button>
                                    </SpaceBetween>
                                </SpaceBetween>
                            </FormField>
                        )
                    default:
                        return
                }
            })}
        </ColumnLayout>
    )
}

export default GroupFormFieldGenerator
